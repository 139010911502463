import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpandedMetrics, ExpandedMetricsValues } from '../../../../models/ProjectReport/ExpandedMetrics';
import {
	CalendarArrayInterface,
	TaskArrayInterface,
	TaskPredArrayInterface,
	XerActivityCode,
	XerActivityType,
} from '../../../../models/Update/Task';
import { BehaviorSubject, Subject } from 'rxjs';
import { getLowercaseValues } from '../../../../util/tasks';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { RestService } from '../../../../services/common/rest.service';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { saveAs } from 'file-saver';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { fileExcelIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Xer, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import {
	multipleTies,
	ProjectReportInterface,
	QcScoreAndPenalties,
	redundantTies,
} from '@rhinoworks/analytics-calculations';
import { QualityControlVars } from '../../../../pkg-test/models/scores';

const allColumns = require('./schedule-analysis-columns.json') as {
	columns: ScheduleAnalysisColumn[];
	buttons: ScheduleAnalysisButton[];
};

export type ScheduleAnalysisColumn = {
	id: number;
	field: string;
	otherField?: string;
	title: string;
	type: string;
	usePredecessorData: boolean;
	useSuccessorData: boolean;
	width: number;
	format?: string;
};

export type ScheduleAnalysisButton = {
	columnsDisplayed: number[];
	displaySet: string;
	dataType: 'Activity' | 'Relationship';
	name: string;
	title: string;
};

export const constraintDict = {
	CS_ALAP: 'As Late As Possible',
	CS_MANDFIN: 'Mandatory Finish',
	CS_MANDSTART: 'Mandatory Start',
	CS_MEO: 'Finish On',
	CS_MEOA: 'Finish On or After',
	CS_MEOB: 'Finish On or Before',
	CS_MSO: 'Start On',
	CS_MSOA: 'Start On or After',
	CS_MSOOB: 'Start On or Before',
};

export const linkTypeDictionary = {
	PR_FS: 'Finish to Start',
	PR_SF: 'Start to Finish',
	PR_FF: 'Finish to Finish',
	PR_SS: 'Start to Start',
};

export const taskStatusDictionary = {
	TK_Complete: 'Completed',
	TK_NotStart: 'Not Started',
	TK_Active: 'Active',
};

export const taskTypeDictionary = {
	TT_Task: 'Task',
	TT_FinMile: 'Finish Milestone',
	TT_Mile: 'Milestone',
	TT_LOE: 'Level of Effort',
};

export interface RedundantLogicTie {
	'Predecessor ID': string;
	'Predecessor Name': string;
	'Predecessor Task Type': string;
	'Successor ID': string;
	'Successor Name': string;
	'Successor Task Type': string;
	Link: string;
	Lag: number;
}
@Component({
	selector: 'app-schedule-analysis',
	templateUrl: './schedule-analysis.component.html',
	styleUrls: ['./schedule-analysis.component.scss'],
})
export class ScheduleAnalysisComponent implements OnInit, OnDestroy {
	public constraintDict = constraintDict;
	public linkTypeDictionary = linkTypeDictionary;
	public taskStatusDictionary = taskStatusDictionary;
	public taskTypeDictionary = taskTypeDictionary;
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgSearch: SVGIcon = searchIcon;
	@Input() customReport: [ProjectReportInterface, any];
	@Input() isFocus: boolean = false;
	@Input() visualizer: boolean = false;
	@Input() small: boolean = false;
	@Input() isOverview: boolean = false;
	@Input() focusCodeKey: string = '';
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public pageSize = 100;
	public skip = 0;
	public sort: SortDescriptor[] = [];
	public filter: CompositeFilterDescriptor = {
		logic: 'and',
		filters: [],
	};
	selectedColumns: ScheduleAnalysisColumn[] = [];
	loading: boolean = true;
	actvCodesById: Map<number, XerActivityCode> = new Map<number, XerActivityCode>([]);
	actvCodesByTask: Map<number, XerActivityCode[]> = new Map<number, XerActivityCode[]>([]);
	actvCodesByType = new Map<number, Array<XerActivityCode>>([]);
	allActivityTypes: Array<XerActivityType> = [];

	scheduleAnalysisSearchBarString = 'Search All Activities...';
	currentDisplayButton: string = 'totalActivities';
	currentDisplaySet: ExpandedMetricsValues = [];
	unfilteredDisplaySet: ExpandedMetricsValues = [];
	allActivities = new Map<number, TaskArrayInterface>([]);
	remainingActivities = new Map<number, TaskArrayInterface>([]);
	loes = new Map<number, TaskArrayInterface>([]);
	allTasksArray: TaskArrayInterface[] = [];
	allRelationships = new Map<number, TaskPredArrayInterface>([]);
	calendars = new Map<number, CalendarArrayInterface>([]);
	expandedMetrics = new BehaviorSubject<ExpandedMetrics>({} as ExpandedMetrics);
	allTasks = new Map<number, TaskArrayInterface>([]);
	searchItemKeys: [CalendarArrayInterface | TaskArrayInterface | TaskPredArrayInterface, Set<string>][] = [];
	$fs = new BehaviorSubject<number>(null);
	$ss = new BehaviorSubject<number>(null);
	$ff = new BehaviorSubject<number>(null);
	$sf = new BehaviorSubject<number>(null);
	redundantLogic = new Map<number, XerTaskPredecessor>([]);
	multipleTies = new Map<number, XerTaskPredecessor>([]);
	fsLagPenalty: number = 0;
	missingPredSuccPenalty: number = 0;
	oosCriticalPenalty: number = 0;
	oosNearCriticalPenalty: number = 0;
	oosNonCriticalPenalty: number = 0;
	negLagPenalty: number = 0;
	hardConstraintPenalty: number = 0;
	relationshipDensityPenalty: number = 0;
	durationPenalty: number = 0;
	floatPenalty: number = 0;
	ssffProblematicPenalty: number = 0;
	openStartFinishPenalty: number = 0;
	sfPenalty: number = 0;

	sortedRows: { name: string; count: number; scoreImpact: number; label: string }[];

	tableTitle = '';
	tableSearch = '';
	tableDataType: 'Activity' | 'Relationship' = 'Activity';

	isExportRunning: boolean = false;
	searchTerm = '';
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isProblematicScheduleActivitiesBtn: boolean = false;

	scoreImpactGreenColor: string = 'black';
	scoreImpactRedColor: string = 'red';
	hasNotes: boolean = false;

	constructor(
		public project: ProjectDashboardService,
		private rest: RestService,
		public analyticsService: AnalyticsDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public schedStorage: ScheduleStorageService
	) {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
	}

	getQcScore(args: QualityControlVars): QcScoreAndPenalties {
		if (!args.numTotalTasks) {
			return {
				fsLagPenalty: 0,
				missingPredSuccPenalty: 0,
				oosCriticalPenalty: 0,
				oosNearCriticalPenalty: 0,
				oosNonCriticalPenalty: 0,
				negLagPenalty: 0,
				hardConstraintPenalty: 0,
				relationshipDensityPenalty: 0,
				durationPenalty: 0,
				floatPenalty: 0,
				ssffProblematicPenalty: 0,
				openStartFinishPenalty: 0,
				sfPenalty: 0,
				qcScore: 0,
			};
		}
		const relationshipDensity = args.numRelationships / args.numTotalTasks;
		const highDuration = args.numHighDuration / args.numRemainingTasks;
		const highFloat = args.numHighFloat / args.numRemainingTasks;
		const openStartFinish = args.numOpenStartFinish / args.numRemainingTasks;
		const fsLagPenalty = args.numFSLagged > 2 ? Math.min(0.1, (args.numFSLagged - 2) * 0.01) : 0;
		const missingPredSuccPenalty = args.numTaskNoPredSucc > 2 ? Math.min(0.15, (args.numTaskNoPredSucc - 2) * 0.02) : 0;

		const oosCriticalPenalty = args.numOosCritical > 1 ? Math.min(0.05, (args.numOosCritical - 1) * 0.01) : 0;
		const oosNearCriticalPenalty =
			args.numOosNearCritical > 1 ? Math.min(0.025, (args.numOosNearCritical - 1) * 0.005) : 0;
		const pctOosNonCritical = args.numOosNonCritical / args.numRemainingTasks;
		const oosNonCriticalPenalty =
			pctOosNonCritical > 0.01 ? Math.min(0.025, Math.floor(pctOosNonCritical * 100 - 1) * 0.005) : 0;

		const negLagPenalty = args.numNegLag > 0 ? Math.min(0.12, args.numNegLag * 0.01) : 0;
		const hardConstraintPenalty = args.numConstrained > 1 ? Math.min(0.1, (args.numConstrained - 1) * 0.01) : 0;
		function computeUnits(x: number): number {
			if (x < 2) {
				return Math.floor((2 - x) / 0.05);
			} else if (x > 4) {
				return Math.floor((x - 4) / 0.05);
			} else {
				return 0;
			}
		}
		const relationshipDensityPenalty =
			relationshipDensity < 2 || relationshipDensity > 4 ? Math.min(0.05, computeUnits(relationshipDensity) * 0.05) : 0;
		const durationPenalty = highDuration > 0.1 ? Math.min(0.1, Math.floor((highDuration - 0.1) / 0.02) * 0.01) : 0;
		const floatPenalty = highFloat > 0.01 ? Math.min(0.15, Math.floor(highFloat * 100 - 1) * 0.01) : 0;
		const ssffProblematicPenalty = args.numSSFFProblematic > 0 ? 0.05 : 0;
		const openStartFinishPenalty = openStartFinish > 0.01 ? 0.05 : 0;
		const sfPenalty =
			args.numSfRelationships > 0
				? Math.min(0.03, Math.floor((args.numSfRelationships * 100) / args.numRelationships) * 0.01)
				: 0;

		const qcScore =
			1 -
			fsLagPenalty -
			missingPredSuccPenalty -
			oosCriticalPenalty -
			oosNearCriticalPenalty -
			oosNonCriticalPenalty -
			negLagPenalty -
			hardConstraintPenalty -
			relationshipDensityPenalty -
			durationPenalty -
			floatPenalty -
			ssffProblematicPenalty -
			openStartFinishPenalty -
			sfPenalty;

		return {
			fsLagPenalty,
			missingPredSuccPenalty,
			oosCriticalPenalty,
			oosNearCriticalPenalty,
			oosNonCriticalPenalty,
			negLagPenalty,
			hardConstraintPenalty,
			relationshipDensityPenalty,
			durationPenalty,
			floatPenalty,
			ssffProblematicPenalty,
			openStartFinishPenalty,
			sfPenalty,
			qcScore,
		};
	}

	ngOnInit(): void {
		this.project.$expandedMetrics.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (metrics: ExpandedMetrics) => {
			/*if (this.customReport) {
				metrics = this.customReport[1];
			}*/
			metrics = metrics as ExpandedMetrics;
			if (!metrics || Object.keys(metrics)?.length === 0) {
				this.loading = true;
				return;
			}
			this.allActivityTypes = metrics.activityTypes || [];
			this.expandedMetrics.next(metrics);
			this.currentDisplayButton = 'totalActivities';
			this.allActivities.clear();
			this.remainingActivities.clear();
			this.loes.clear();
			this.allTasks.clear();
			const update = this.schedStorage.$allUpdates.value.find((u) => u._id === metrics.updateId);
			const xer = new Xer(
				this.schedStorage.cachedXerData.get(metrics.updateId),
				update.selectedProjectId || update.finishMilestone?.proj_id
			);
			const actvsByTask: Record<
				number,
				Array<{
					task_id: number;
					actv_code_type_id: number;
					actv_code_id: number;
					proj_id: number;
				}>
			> = {};
			const currTaskActv = await this.schedStorage.grabUpdateTable<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>(metrics.updateId, 'TASKACTV');
			for (const ta of currTaskActv) {
				if (!actvsByTask[ta.task_id]) {
					actvsByTask[ta.task_id] = [];
				}
				actvsByTask[ta.task_id].push(ta);
			}
			const tasks = xer.sortedActivities.map((a) => a.raw_entry);
			const preds: XerTaskPredecessor[] = Array.from(xer.activityPredecessors.values()).map((p) => p.raw_entry);
			this.multipleTies = new Map<number, XerTaskPredecessor>(
				multipleTies(preds).map((tie) => [tie.task_pred_id, tie])
			);
			const invalidLogics: XerTaskPredecessor[] = [];
			xer.reschedule();
			for (const [predId, succId] of xer.invalidDependencies) {
				const preds = xer.activitiesMap.get(succId)?.predecessors.filter((p) => p.prevActivityId === predId) || [];
				invalidLogics.push(...preds.map((p) => p.raw_entry));
			}
			this.redundantLogic = new Map<number, XerTaskPredecessor>(
				[...redundantTies(xer), ...invalidLogics].map((logic) => [logic.task_pred_id, logic])
			);
			Promise.all(tasks).then(() => {
				this.actvCodesById.clear();
				this.actvCodesByTask.clear();
				this.actvCodesByType.clear();
				for (const type of this.allActivityTypes) {
					this.actvCodesByType.set(type.actv_code_type_id, []);
				}
				for (const code of metrics.activityCodes) {
					const siblings: XerActivityCode[] = this.actvCodesByType.get(code.actv_code_type_id) || [];
					const codeName: string = code?.actv_code_name || '';
					const shortName: string = code?.short_name || '';
					const fullName: string =
						(codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
					if (siblings && fullName && !siblings.some((s) => s.actv_code_id === code.actv_code_id)) {
						siblings.push(code);
					}

					this.actvCodesByType.set(code.actv_code_type_id, siblings);
					this.actvCodesById.set(code.actv_code_id, code);
				}
				const tasksTakingIntoAccountForFocusFiltering = [];
				for (const task of tasks) {
					const actvCodesForTask: XerActivityCode[] = (actvsByTask[task.task_id] || []).map((ta) =>
						this.actvCodesById.get(ta.actv_code_id)
					);
					this.actvCodesByTask.set(task.task_id, actvCodesForTask);
					if (
						this.isFocus &&
						!this.actvCodesByTask
							.get(task.task_id)
							.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
					) {
						continue;
					}
					tasksTakingIntoAccountForFocusFiltering.push(task);
					// task.customStart = !!task.act_start_date ? task.act_start_date : task.target_start_date;
					// task.customEnd = !!task.act_end_date ? task.act_end_date : task.target_end_date;
					this.allActivities.set(task.task_id, task);
					if (task.task_type === 'TT_Task') {
						this.allTasks.set(task.task_id, task);
					}
					if (task.status_code !== 'TK_Complete' && task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
						this.remainingActivities.set(task.task_id, task);
					}
					if (task.task_type === 'TT_LOE') {
						this.loes.set(task.task_id, task);
					}
				}
				this.allTasksArray = tasksTakingIntoAccountForFocusFiltering;

				this.allRelationships.clear();
				const relationships = xer.activityPredecessors.values() || [];
				for (const relationship of relationships) {
					if (
						this.isFocus &&
						!this.actvCodesByTask
							.get(relationship.raw_entry.pred_task_id)
							?.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
					) {
						continue;
					}
					this.allRelationships.set(relationship.id, relationship.raw_entry);
				}

				this.project.$currentProjectReport
					.pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
					.subscribe((ogReport) => {
						const report: CurrentProjectReport = {
							...ogReport,
							...this.customReport?.[0],
							project: ogReport.project,
						} as unknown as CurrentProjectReport;
						if (report?.project?.company) {
							const qcVars = {
								numConstrained: 0,
								numTotalTasks: 0,
								numRemainingTasks: 0,
								numFSLagged: 0,
								numTaskNoPredSucc: 0,
								numActualsAfterDataDate: 0,
								numRelationships: 0,
								numOosCritical: 0,
								numOosNearCritical: 0,
								numOosNonCritical: 0,
								numNegLag: 0,
								numHighDuration: 0,
								numHighFloat: 0,
								numNoProgressWithActual: 0,
								numNoDeltaInRemaining: 0,
								numCritical: 0,
								numOpenStartFinish: 0,
								numSSFFProblematic: 0,
								numSfRelationships: 0,
								numTasks: 0,
								numProblematicFloat: 0,
							};
							if (this.isFocus) {
								//todo: figure out out of sequence filtering for activity codes - kf
								metrics = {
									...metrics,
									missingPredecessors: metrics.missingPredecessors.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									missingSuccessors: metrics.missingSuccessors.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									negativeLags: metrics.negativeLags.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									openStartFinish: metrics.openStartFinish.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									loes: metrics.loes.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									totalRelationships: metrics.totalRelationships.filter((relationship) =>
										this.actvCodesByTask
											.get(relationship.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									milestones: metrics.milestones.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									ff: metrics.ff.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									fs: metrics.fs.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									sf: metrics.sf.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									ss: metrics.ss.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									hardConstraints: metrics.hardConstraints.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									highFloatActivities: metrics.highFloatActivities.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									highDurationActivities: metrics.highDurationActivities.filter((task) =>
										this.actvCodesByTask
											.get(task.task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									fsProblematicLags: metrics.fsProblematicLags.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
									ssFFProblematicLags: metrics.ssFFProblematicLags.filter((task) =>
										this.actvCodesByTask
											.get(task.pred_task_id)
											.some((actv) => actv.actv_code_name + '%--//--%' + actv.short_name === this.focusCodeKey)
									),
								};
								this.expandedMetrics.next(metrics);

								const historicalValues = this.customReport?.[0]?.qualityControl?.historicalByCode;
								for (const historicalByCodeKey in historicalValues) {
									const reportValsHistorical = historicalValues[historicalByCodeKey][this.focusCodeKey];
									if (reportValsHistorical?.length) {
										const normalizedArr = [];
										for (let i = 0; i < report.updateIds.length; i++) {
											const reportVal = i < reportValsHistorical.length ? reportValsHistorical[i] : 0;
											normalizedArr.push(reportVal === null || reportVal === undefined ? 0 : reportVal);
										}
										qcVars[historicalByCodeKey] = normalizedArr[report.updateIds.length - 1];
									} else {
										qcVars[historicalByCodeKey] = 0;
									}
								}
								qcVars.numConstrained = metrics.hardConstraints.length;
								qcVars.numOpenStartFinish = metrics.openStartFinish.length;
								qcVars.numHighFloat = metrics.highFloatActivities.length;
								qcVars.numHighDuration = metrics.highDurationActivities.length;
								const penalties = this.getQcScore(qcVars);

								this.fsLagPenalty = penalties.fsLagPenalty * -100;
								this.missingPredSuccPenalty = penalties.missingPredSuccPenalty * -100;
								this.oosCriticalPenalty = penalties.oosCriticalPenalty * -100;
								this.oosNearCriticalPenalty = penalties.oosNearCriticalPenalty * -100;
								this.oosNonCriticalPenalty = penalties.oosNonCriticalPenalty * -100;
								this.negLagPenalty = penalties.negLagPenalty * -100;
								this.hardConstraintPenalty = penalties.hardConstraintPenalty * -100;
								this.relationshipDensityPenalty = penalties.relationshipDensityPenalty * -100;
								this.durationPenalty = penalties.durationPenalty * -100;
								this.floatPenalty = penalties.floatPenalty * -100;
								this.ssffProblematicPenalty = penalties.ssffProblematicPenalty * -100;
								this.openStartFinishPenalty = penalties.openStartFinishPenalty * -100;
								this.sfPenalty = penalties.sfPenalty * -100;

								this.$fs.next(
									metrics.fs?.length ? (100 * metrics.fs.length) / (metrics.totalRelationships.length || 1) : 0
								);
								this.$ss.next(
									metrics.ss?.length ? (100 * metrics.ss.length) / (metrics.totalRelationships.length || 1) : 0
								);
								this.$ff.next(
									metrics.ff?.length ? (100 * metrics.ff.length) / (metrics.totalRelationships.length || 1) : 0
								);
								this.$sf.next(
									metrics.sf?.length ? (100 * metrics.sf.length) / (metrics.totalRelationships.length || 1) : 0
								);

								this.redundantLogic.forEach((value, key) => {
									if (this.allActivities.get(value.pred_task_id) === undefined) {
										this.redundantLogic.delete(key);
									}
								});
								this.multipleTies.forEach((value, key) => {
									if (this.allActivities.get(value.pred_task_id) === undefined) {
										this.multipleTies.delete(key);
									}
								});
								this.loadNewDisplaySet(this.currentDisplayButton);
							} else {
								this.fsLagPenalty = report?.qualityControl?.fsLagPenalty;
								this.missingPredSuccPenalty = report?.qualityControl?.missingPredSuccPenalty;
								this.oosCriticalPenalty = report?.qualityControl?.oosCriticalPenalty;
								this.oosNearCriticalPenalty = report?.qualityControl?.oosNearCriticalPenalty;
								this.oosNonCriticalPenalty = report?.qualityControl?.oosNonCriticalPenalty;
								this.negLagPenalty = report?.qualityControl?.negLagPenalty;
								this.hardConstraintPenalty = report?.qualityControl?.hardConstraintPenalty;
								this.relationshipDensityPenalty = report?.qualityControl?.relationshipDensityPenalty;
								this.durationPenalty = report?.qualityControl?.durationPenalty;
								this.floatPenalty = report?.qualityControl?.floatPenalty;
								this.ssffProblematicPenalty = report?.qualityControl?.ssffProblematicPenalty;
								this.openStartFinishPenalty = report?.qualityControl?.openStartFinishPenalty;
								this.sfPenalty = report?.qualityControl?.sfPenalty;
							}

							const rows = [
								{
									name: 'FS With Lags',
									count: this.expandedMetrics.value.fsProblematicLags?.length,
									scoreImpact: this.fsLagPenalty,
									label: 'fsWithLags',
								},
								{
									name: 'Missing Predecessors or Successors',
									count:
										this.expandedMetrics.value.missingSuccessors?.length +
										this.expandedMetrics.value.missingPredecessors?.length,
									scoreImpact: this.missingPredSuccPenalty,
									label: 'missingPredSuccessors',
								},
								{
									name: 'Out of Sequence (Critical)',
									count: this.expandedMetrics.value.outOfSequenceCritical?.length,
									scoreImpact: this.oosCriticalPenalty,
									label: 'oosCrit',
								},
								{
									name: 'Out of Sequence (Near Critical)',
									count: this.expandedMetrics.value.outOfSequenceNearCritical?.length,
									scoreImpact: this.oosNearCriticalPenalty,
									label: 'oosNearCrit',
								},
								{
									name: 'Out of Sequence (Non-Critical)',
									count:
										this.expandedMetrics.value.outOfSequence?.length -
										this.expandedMetrics.value.outOfSequenceNearCritical?.length -
										this.expandedMetrics.value.outOfSequenceCritical?.length,
									scoreImpact: this.oosNonCriticalPenalty,
									label: 'oosNonCrit',
								},
								{
									name: 'Negative Lags',
									count: this.expandedMetrics.value.negativeLags?.length,
									scoreImpact: this.negLagPenalty,
									label: 'negativeLags',
								},
								{
									name: 'Hard Constraints',
									count: this.expandedMetrics.value.hardConstraints?.length,
									scoreImpact: this.hardConstraintPenalty,
									label: 'hardConstraints',
								},
								{
									name: 'Logic Density',
									count: this.allRelationships.size / this.allActivities.size,
									scoreImpact: this.relationshipDensityPenalty,
									label: 'logicDensity',
								},
								{
									name: 'High Duration (RD > 44)',
									count: this.expandedMetrics.value.highDurationActivities?.length,
									scoreImpact: this.durationPenalty,
									label: 'highDurationActivities',
								},
								{
									name: 'High Float (TF > 80 + Critical Float)',
									count: this.expandedMetrics.value.highFloatActivities?.length,
									scoreImpact: this.floatPenalty,
									label: 'highFloatActivities',
								},
								{
									name: 'SS/FF with Problematic Lags',
									count: this.expandedMetrics.value.ssFFProblematicLags?.length,
									scoreImpact: this.ssffProblematicPenalty,
									label: 'ssFFWithLags',
								},
								{
									name: 'Open Start/Finish',
									count: this.expandedMetrics.value.openStartFinish?.length,
									scoreImpact: this.openStartFinishPenalty,
									label: 'openStartFinish',
								},
								{
									name: 'Start to Finish Relationships',
									count: this.expandedMetrics.value.sf?.length,
									scoreImpact: this.sfPenalty,
									label: 'sf_prob',
								},
							];

							this.sortedRows = rows.sort((a, b) => {
								// First compare by scoreImpact (descending order)
								if (b.scoreImpact !== 0 && a.scoreImpact !== 0) {
									return a.scoreImpact - b.scoreImpact;
								}

								// If scoreImpact is 0 for both, then compare by count (descending order)
								if (b.scoreImpact === 0 && a.scoreImpact === 0) {
									return b.count - a.count;
								}

								// If one of the scoreImpacts is 0, the other takes priority
								return a.scoreImpact - b.scoreImpact;
							});

							this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(
								report?.project?.company
							);
						}
					});

				this.calendars.clear();
				const calendars = metrics.calendars || [];
				for (const calendar of calendars) {
					this.calendars.set(calendar.clndr_id, calendar);
				}

				if (!this.isFocus) {
					this.$fs.next(metrics.fs?.length ? (100 * metrics.fs.length) / (metrics.totalRelationships.length || 1) : 0);
					this.$ss.next(metrics.ss?.length ? (100 * metrics.ss.length) / (metrics.totalRelationships.length || 1) : 0);
					this.$ff.next(metrics.ff?.length ? (100 * metrics.ff.length) / (metrics.totalRelationships.length || 1) : 0);
					this.$sf.next(metrics.sf?.length ? (100 * metrics.sf.length) / (metrics.totalRelationships.length || 1) : 0);

					this.loadNewDisplaySet(this.currentDisplayButton);
				}
			});
		});
		this.project.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 10)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	loadNewDisplaySet(
		content: string = 'totalActivities',
		metrics = this.customReport?.[1] || this.expandedMetrics.value
	) {
		this.skip = 0;
		let switchFlag = false;
		const problematicScheduleActivitiesBtns: string[] = [
			'highDurationActivities',
			'highFloatActivities',
			'actualsPastDD',
			'ssFFWithLags',
			'softConstraints',
			'missingPredSuccessors',
			'negativeLags',
			'fsWithLags',
			'hardConstraints',
			'oosCrit',
			'oosNearCrit',
			'oosNonCrit',
			'openStartFinish',
			'logicDensity',
			'sf_prob',
			'multipleTies',
			'redundantLogic',
		];
		if (this.isOverview && content === this.currentDisplayButton) {
			this.currentDisplayButton = 'totalActivities';
		} else {
			this.currentDisplayButton = content;
		}
		this.isProblematicScheduleActivitiesBtn = problematicScheduleActivitiesBtns.includes(this.currentDisplayButton);
		//since sf is repeated in potential schedule issues I added this so it loads the correct table with all the data needed related to sf then switch back to sf_prob at the end to make sure the correct box is highlighted
		if (content === 'sf_prob') {
			switchFlag = true;
			this.currentDisplayButton = 'sf';
		}
		const matchingBtnData = allColumns.buttons.find((btn) => btn.name === this.currentDisplayButton);
		if (matchingBtnData === undefined) {
			return;
		}
		const filterOutOfSequence = (filterSet) => {
			return metrics.outOfSequence.filter((item) => {
				const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
				const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
				item.succCode = task?.task_code;
				item.predCode = pred_task?.task_code;
				return filterSet?.find((p) => p.predCode === item.predCode && p.succCode === item.succCode);
			});
		};
		switch (this.currentDisplayButton) {
			case 'missingPredSuccessors':
				{
					this.currentDisplaySet = [...metrics.missingPredecessors, ...metrics.missingSuccessors];
				}
				break;
			case 'Tasks':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.task_type === 'TT_Task') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'remainingActivities':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.status_code !== 'TK_Complete' && task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'totalActivities':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'LOES':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => task.task_type === 'TT_LOE');
				}
				break;
			case 'oosCrit':
				{
					this.currentDisplaySet = filterOutOfSequence(metrics.outOfSequenceCritical);
				}
				break;
			case 'oosNearCrit':
				{
					this.currentDisplaySet = filterOutOfSequence(metrics.outOfSequenceNearCritical);
				}
				break;
			case 'oosNonCrit':
				{
					this.currentDisplaySet = metrics.outOfSequence.filter((item) => {
						const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
						const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
						item.succCode = task?.task_code;
						item.predCode = pred_task?.task_code;
						const isOosCrit = metrics.outOfSequenceCritical?.find(
							(p) => p.predCode === item.predCode && p.succCode === item.succCode
						);
						const isOosNearCrit = metrics.outOfSequenceNearCritical?.find(
							(p) => p.predCode === item.predCode && p.succCode === item.succCode
						);
						return isOosCrit === undefined && isOosNearCrit === undefined ? item : undefined;
					});
				}
				break;
			case 'redundantLogic':
				{
					this.currentDisplaySet = [...this.redundantLogic.values()] as unknown as TaskPredArrayInterface[];
				}
				break;
			case 'multipleTies':
				{
					this.currentDisplaySet = [...this.multipleTies.values()] as unknown as TaskPredArrayInterface[];
				}
				break;
			default: {
				this.currentDisplaySet = metrics[matchingBtnData.displaySet];
			}
		}

		this.tableDataType = matchingBtnData.dataType;
		this.tableTitle = matchingBtnData.title;
		this.scheduleAnalysisSearchBarString = `Search ${this.tableTitle}...`;
		this.unfilteredDisplaySet = this.currentDisplaySet?.length ? [...this.currentDisplaySet] : [];
		this.searchItemKeys = [];
		for (const item of this.unfilteredDisplaySet) {
			const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
			const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
			const taskFilterKeys = ['task_id', 'task_name', 'task_code'];
			const lowercaseValuesForItemAsArr: string[] = [];
			if (task) {
				const taskLowercaseValues: string[] = [...getLowercaseValues(task, taskFilterKeys)];
				taskLowercaseValues.forEach((val: string) => lowercaseValuesForItemAsArr.push(val));
				item.customStart = task.act_start_date ? task.act_start_date : task.early_start_date;
				item.early_start_date = task.act_start_date ? null : task.early_start_date ? task.early_start_date : undefined;
				item.customEnd = task.act_end_date ? task.act_end_date : task.early_end_date;
				item.early_end_date = task.act_end_date ? null : task.early_end_date ? task.early_end_date : undefined;
				item.succCode = task.task_code;
				item.succName = task.task_name;
				item.succType = task.task_type;
				item.succStartAct = task.act_start_date;
				item.succStartEarly = task.act_start_date ? null : task.early_start_date ? task.early_start_date : undefined;
				if (item.succStartEarly) {
					item.succStartAct = item.succStartEarly;
				}
				item.succEndAct = task.act_end_date;
				item.succEndEarly = task.act_end_date ? null : task.early_end_date ? task.early_end_date : undefined;
				if (item.succEndEarly) {
					item.succEndAct = item.succEndEarly;
				}
				item.succRd = task.remain_drtn_hr_cnt / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
				const openStartFinishItem = this.expandedMetrics.value.openStartFinish?.find(
					(eitem) => eitem.task_id === task.task_id
				);
				if (openStartFinishItem) {
					item.issueType =
						openStartFinishItem.openStart && openStartFinishItem.openFinish
							? 'Open Start & Finish'
							: openStartFinishItem.openStart
								? 'Open Start'
								: openStartFinishItem.openFinish
									? 'Open Finish'
									: '';
				}
				if (matchingBtnData.columnsDisplayed.includes(16)) {
					item.calendar = this.calendars.get(+task.clndr_id)?.clndr_name;
				}
				const hoursFields = [
					{
						taskField: 'remain_drtn_hr_cnt',
						customField: 'remainingHrs',
					},
					{
						taskField: 'target_drtn_hr_cnt',
						customField: 'odHrs',
					},
					{
						taskField: 'total_float_hr_cnt',
						customField: 'tfHrs',
					},
				];
				hoursFields.forEach((field) => {
					if (field.taskField in task) {
						item[field.customField] = Math.round(
							task[field.taskField] / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8)
						);
					} else {
						item[field.customField] = 0;
					}
				});
			}
			if (pred_task) {
				const predTaskLowercaseValues: string[] = [...getLowercaseValues(task, taskFilterKeys)];
				predTaskLowercaseValues.forEach((val: string) => lowercaseValuesForItemAsArr.push(val));
				item.predCode = pred_task.task_code;
				item.predName = pred_task.task_name;
				item.predType = pred_task.task_type;
				item.predStartAct = pred_task.act_start_date;
				item.predStartEarly = pred_task.act_start_date
					? null
					: pred_task.early_start_date
						? pred_task.early_start_date
						: undefined;
				if (item.predStartEarly) {
					item.predStartAct = item.predStartEarly;
				}
				item.predEndAct = pred_task.act_end_date;
				item.predEndEarly = pred_task.act_end_date
					? null
					: pred_task.early_end_date
						? pred_task.early_end_date
						: undefined;
				if (item.predEndEarly) {
					item.predEndAct = item.predEndEarly;
				}
				item.predRd = pred_task.remain_drtn_hr_cnt / (this.calendars.get(+pred_task.clndr_id)?.day_hr_cnt || 8);
				/*const isOosCrit = metrics.outOfSequenceCritical?.find(
					(p) => p.predCode === item.predCode && p.succCode === item.succCode
				);
				const isOosNearCrit = metrics.outOfSequenceNearCritical?.find(
					(p) => p.predCode === item.predCode && p.succCode === item.succCode
				);
				item.criticality = isOosCrit ? 'Critical' : isOosNearCrit ? 'Near Critical' : 'Non Critical';*/
			}
			this.searchItemKeys.push([item, new Set<string>(lowercaseValuesForItemAsArr)]);
			if ('lag_hr_cnt' in item) {
				item.lagHrs = this.divByCalendar(item.lag_hr_cnt, task || pred_task);
			} else {
				item.lagHrs = 0;
			}
		}
		if (this.tableSearch) {
			this.updateFilter({ searchTerm: this.tableSearch });
		}
		this.loadData();
		this.selectedColumns = [];
		allColumns.columns.forEach((column: ScheduleAnalysisColumn) => {
			if (matchingBtnData.columnsDisplayed.includes(column.id)) {
				this.selectedColumns.push(column);
			}
		});
		if (switchFlag) {
			this.currentDisplayButton = 'sf_prob';
		}
		this.loading = false;
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm.toLowerCase();
		this.tableSearch = searchTerm;
		let filteredDisplaySet: ExpandedMetricsValues = [];
		if (!searchTerm) {
			filteredDisplaySet = this.unfilteredDisplaySet || [];
		} else {
			for (const [item, valuesToLower] of this.searchItemKeys) {
				let alreadyAddedThisItem: boolean = false;
				for (const valLower of valuesToLower) {
					if (valLower.includes(searchTerm) && !alreadyAddedThisItem) {
						filteredDisplaySet.push(item);
						alreadyAddedThisItem = true;
					}
				}
			}
		}

		// update the rows
		this.currentDisplaySet = filteredDisplaySet;
		this.loadActivities();
	}

	getCalendar(calendar_id: number) {
		for (let i = 0; i < this.project.$expandedMetrics.value.calendars.length; i++) {
			if (this.project.$expandedMetrics.value.calendars[i]?.clndr_id === calendar_id) {
				return this.project.$expandedMetrics.value.calendars[i];
			}
		}
		return null;
	}

	divByCalendar(value: any, row: any) {
		return value / (this.getCalendar(row?.clndr_id)?.day_hr_cnt || 8) || value / 8;
	}

	doExport($event: MouseEvent) {
		this.isExportRunning = true;
		this.rest.postToExporter('qc/' + this.project.$currentProjectPageId.value, this.allTasksArray).subscribe({
			next: (res: any) => {
				saveAs(
					res,
					'QC_' +
						this.project.$currentProjectReport.value.projectTable.projectName +
						'_' +
						this.analyticsService.yyyymmdd() +
						'.xlsx'
				);
				this.isExportRunning = false;
			},
			error: (err: any) => {
				this.isExportRunning = false;
				console.log(err);
			},
		});
	}

	//do not remove this function. -MS
	fn: any = (task) => {
		if (task.task_type === 'TT_Task') {
			return task;
		}
		return undefined;
	};

	//do not remove this function. :) -RS
	fn2: any = (task) => {
		if (task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
			return task;
		}
		return undefined;
	};

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public loadData(): void {
		this.currentDisplaySet = filterBy(this.currentDisplaySet, this.filter);
		this.loadActivities();
	}

	public loadActivities(): void {
		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	public filterChange(filter: CompositeFilterDescriptor): void {
		this.filter = filter;
		this.loadData();
	}
}
