import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CalculationFieldInterface } from '../../../../models/ProjectReport/ProjectReport';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';

@Component({
	selector: 'app-index-trending',
	templateUrl: './index-trending.component.html',
	styleUrls: ['./index-trending.component.scss'],
})
export class IndexTrendingComponent implements OnInit {
	@Input() isOverview: boolean = false;
	@Input() beiHistorical: number[];
	@Input() cpliHistorical: number[];
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	beiData: SeriesData[] = [];
	cpliData: SeriesData[] = [];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Score',
				visible: true,
			},
			labels: {
				format: '{0:n2}',
			},
		},
	];
	hasNotes: boolean = false;

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		if (this.beiHistorical && this.cpliHistorical) {
			this.updateChartData(
				undefined,
				this.beiHistorical.map((val, i) => ({
					BEI: val * 100,
					CPLI: this.cpliHistorical[i] * 100,
				}))
			);
		} else {
			this.projectService.$currentProjectReport
				.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
				.subscribe((report) => {
					if (!report?.calculationFieldsHistorical) {
						return;
					}
					this.updateChartData(report);
				});
		}

		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 20)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates chart values with new ones
	 * @param report
	 * @param customVals
	 */
	updateChartData(report: CurrentProjectReport, customVals?: Array<{ BEI: number; CPLI: number }>): void {
		const valuesByUpdate = customVals || report?.calculationFieldsHistorical;
		const beiData = [];
		const cpliData = [];
		const categories = [];
		valuesByUpdate.forEach((update, i) => {
			const category = i === 0 ? 'Baseline' : 'Update ' + i + (!i ? ' ®' : '');
			categories.push(category);
			beiData.push({
				category,
				value: update.BEI / 100,
			});
			cpliData.push({
				category,
				value: update.CPLI / 100,
			});
		});
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: beiData,
				name: 'Baseline Execution Index',
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: cpliData,
				name: 'Critical Path Length Index',
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		console.log('seriesData', seriesData);
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(beiData, this.beiData)) {
			this.beiData = beiData;
		}
		if (hasObjChanged(cpliData, this.cpliData)) {
			this.cpliData = cpliData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}
}
