import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PfTableValue } from '../../../../models/ProjectReport/ProjectReport';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { PFTableValues } from '../risk-performance-factor/risk-performance-factor/performance-factor.component';
import { PlotBand } from '@progress/kendo-angular-charts';

@Component({
	selector: 'app-performance-factors',
	templateUrl: './performance-factors.component.html',
	styleUrls: ['./performance-factors.component.scss'],
})
export class PerformanceFactorsComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	hideComponent = false;
	parentFromCode = new Map<string, PFTableValues>();
	saveClicked = new BehaviorSubject<boolean>(false);
	saveButtonEnabled: boolean = false;
	selectedCodeArray: Array<string> = [];
	showNoSelection: boolean = true;
	nothingSelected: boolean = false;
	report;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	selectedActivityTypeName: string = null;
	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 100,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 100,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Planned / Actual Duration',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
		},
	];
	hasNotes: boolean = false;

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			if (!report?.pfTableHistorical) {
				return;
			}

			this.report = report;
			this.updateChartData(report);
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 6)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates chart values with new ones
	 * @param report
	 */
	updateChartData(report: CurrentProjectReport): void {
		if (!this.selectedCodeArray.length) {
			this.nothingSelected = true;
			this.seriesData = null;
			this.showNoSelection = true;
			return;
		}
		this.showNoSelection = false;

		const codesPfData: Map<string, SeriesData[]> = new Map<string, SeriesData[]>();
		const colors: Map<string, string> = new Map<string, string>();
		const valuesByUpdate: PfTableValue[][] = report?.pfTableHistorical;

		const colorOptions: string[] = [
			'Red',
			'Blue',
			'Green',
			'Yellow',
			'Orange',
			'Purple',
			'Pink',
			'Brown',
			'Gray',
			'Black',
			'White',
			'Cyan',
			'Magenta',
			'Lime',
			'Indigo',
			'Violet',
			'Turquoise',
			'Teal',
			'Lavender',
			'Maroon',
			'Beige',
			'Tan',
			'Salmon',
			'Olive',
			'Gold',
			'Silver',
			'Coral',
			'Khaki',
			'Crimson',
			'Ivory',
			'Navy',
			'Peach',
			'Plum',
			'Chocolate',
			'Amber',
			'Mint',
			'Emerald',
			'Jade',
			'Sapphire',
			'Ruby',
			'Charcoal',
			'Rose',
			'Periwinkle',
			'Burgundy',
			'Chartreuse',
			'Aquamarine',
			'Mustard',
			'Azure',
			'Mauve',
			'Slate',
		];

		const categories = [];
		let j = 0;
		valuesByUpdate.forEach((update: PfTableValue[], i) => {
			const category =
				i === 0 ? 'Baseline' : 'Update ' + i + (report?.baselineUpdateId === report?.updateIds[i] ? ' ®' : '');
			categories.push(category);

			update.forEach((activityCode: PfTableValue) => {
				if (this.parentFromCode.get(activityCode.activityCode)?.activityCode === this.selectedActivityTypeName) {
					if (!colors.has(activityCode.activityCode)) {
						colors.set(activityCode.activityCode, colorOptions[j]);
						j++;
					}
				}
				if (
					this.selectedCodeArray.includes(activityCode.description) &&
					this.parentFromCode.get(activityCode.activityCode)?.activityCode === this.selectedActivityTypeName
				) {
					const newValue = { category: categories[i], value: activityCode.pf * 100 };

					if (codesPfData.has(activityCode.activityCode)) {
						const existingValues = codesPfData.get(activityCode.activityCode) || [];
						if (existingValues.length < i + 1 && activityCode.numActivities > 0) {
							existingValues.push(newValue);
							codesPfData.set(activityCode.activityCode, existingValues);
						}
					} else {
						codesPfData.set(activityCode.activityCode, [newValue]);
					}
				}
			});

			/*for (let j = 0; j < update.length; j++) {
				const activityCode = update[j];
				if (this.selectedCodeArray.includes(activityCode.description)) {
					if (codesPfData.has(activityCode.activityCode)) {
						const previousVal = codesPfData.get(activityCode.activityCode);
						previousVal.push({ category: categories[i], value: activityCode.pf * 100 });
						codesPfData.set(activityCode.activityCode, previousVal);
					} else {
						codesPfData.set(activityCode.activityCode, [{ category: categories[i], value: activityCode.pf * 100 }]);
					}
				}
			}*/
		});

		const seriesData: SeriesDataSettings[] = [];
		for (const [name, data] of codesPfData) {
			const normalizedData: SeriesData[] = this.getNormalizedData(data, categories);
			seriesData.push({
				type: 'line',
				color: colors.get(name),
				data: normalizedData,
				name,
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			});
		}
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		this.nothingSelected = false;
	}

	/**
	 * fills in any updates where numActivities === 0 or before the code was made/activities assigned with gaps in the
	 * chart instead of aligning them with wrong updates
	 * @param data
	 * @param categories
	 */
	getNormalizedData(data: SeriesData[], categories: string[]): SeriesData[] {
		if (data?.length === categories?.length) {
			return data;
		}
		const normalizedData: SeriesData[] = [];
		categories.forEach((c: string) => {
			const matchingData: SeriesData = data.find((d: SeriesData) => d.category === c);
			normalizedData.push(matchingData ? matchingData : { category: c, value: null });
		});
		return normalizedData;
	}

	updateSelectedCodes(e) {
		this.selectedCodeArray = e;
		this.updateChartData(this.report);
	}

	initializeParentMap(e) {
		this.parentFromCode = e;
		this.updateChartData(this.report);
	}

	submit() {
		this.saveClicked.next(true);
	}

	cancelChange(ev: boolean) {
		this.saveButtonEnabled = ev;
	}

	activityCodeDropDownNameChange(ev: string) {
		if (ev) {
			this.selectedActivityTypeName = ev;
			this.updateChartData(this.report);
		}
	}

	//commented this out because it was setting showNoSelection to false when just the overall activity code type was selected and not any activity codes, so it would result in a weird blank chart being shown. -kf
	showNoSelectionText(ev: boolean) {
		//this.showNoSelection = ev;
	}

	minimizeComponentIfNoOptions(ev: boolean) {
		if (ev) {
			this.hideComponent = true;
		}
	}
}
