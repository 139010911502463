<div class='card' id="monteCarloComponent">
	<kendo-gridlayout
		[rows]="['42.4px', (user?.userType === 'saasRisk' && !(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length) || !showMonteCarlo ? '150px' : (showMonteCarlo && (($isRunningMC | async) || initializingMCJob)) ? '363.1px' : '460px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Monte Carlo Simulation
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "monte-carlo-risk-register"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
			<div style='display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; position: relative; margin: auto 10px auto auto;'>
				<kendo-circularprogressbar
					style="transform: scale(0.1);"
					[indeterminate]="true"
					[value]="30"
					[progressColor]='"#ffb500"'
					id='rr-mc-spin'
					*ngIf='showMonteCarlo && (($isRunningMC | async) || initializingMCJob) && _projectDashboardService.$currentProjectData.value?.updateIds?.length > 0'
				></kendo-circularprogressbar>
				<ion-button
					style="font-size: 13px; height: 29.594px; margin: 0;"
					[style.--background]='"#2ebb2e"'
					(click)="saveRegisters()"
					[disabled]='recalcing || initializingMCJob'
					size='small'>
					{{showMonteCarlo && (($isRunningMC | async) || initializingMCJob) && _projectDashboardService.$currentProjectData.value?.updateIds?.length > 0 ? 'Calculating' : 'Calculate'}}
				</ion-button>
				<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
					@if (isOverview) {
						<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='_projectDashboardService.goTo(18)' style="background-color: #ffb500;"></button>
					}
					<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='_projectDashboardService.openNotesWindow({id: 18, name: "Monte Carlo Simulation"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
				</span>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='showMonteCarlo && (($isRunningMC | async) || initializingMCJob) && _projectDashboardService.$currentProjectData.value?.updateIds?.length > 0' style='background: url("https://consultaegis.com/wp-content/uploads/2022/12/iStock-600667654-1-1440x810.jpg") no-repeat center center; background-size: cover; border-radius: 0 0 4px 4px;'>
			<div style='display: flex; flex-direction: column; justify-content: center; margin: 0 auto; height: 100%;'>
				<div style='background-color: white; height: 120px;'>
					<p style='text-align: center;' *ngIf='initializingMCJob && ($mcJobProgressPct | async) === 0'>Initializing Calculation Request... this might take a few seconds.<br><small>A progress bar will appear once we've begun your simulation.</small></p>
					<div *ngIf='!initializingMCJob || ($mcJobProgressPct | async) > 0' style='text-align: center'>
						<p>Monte Carlo simulation is in progress. The page will refresh upon completion, <b>this process can take up to 3 minutes</b></p>
						<br/>
						<p style='text-align: center; margin: 0;' >{{(100 * ($mcJobProgressPct | async)) | number: '1.0-0'}}% processed</p>
					</div>
					<kendo-progressbar [value]="($mcJobProgressPct | async) * 100" [indeterminate]='initializingMCJob && ($mcJobProgressPct | async) === 0' [label]='{format: "percent"}'></kendo-progressbar>
				</div>
				<div style='color: white; border-radius: 0 0 4px 4px; background: rgba(0,19,120,0.78); height: calc(100% - 120px);'>
					<div style='border-radius: 0 0 4px 4px;'>
						<div style='font-size: 19px; padding-top: 30px; text-align: center;'>Did you know?</div>
						<p style='padding: 20px 25px; text-align: center;'>{{selectedRhinoFact}}</p>
						<p style='text-align: center; padding-bottom: 15px;'>
							<ion-button href='https://consultaegis.com/about/#block_82709170c0c07c7bddc83816ded336e9' target='_blank' fill='clear' size='small' style='color: yellow'>Why Rhinos?</ion-button>
						</p>
					</div>
				</div>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='!showMonteCarlo'>
			<div class='warning-div'>
				Monte Carlo requires Global Impact, or at least 1 Risk with Pre-Mitigation Activity Impact AND tagged activities
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='showMonteCarlo && (((($isRunningMC | async) || initializingMCJob) && (_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length === 0) || (($isRunningMC | async) === false && !initializingMCJob) && (_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length === 0)' style='border-radius: 0 0 4px 4px;'>
			<div class='warning-div'>
				Monte Carlo Requires a schedule to calculate.
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="showMonteCarlo && ($isRunningMC | async) === false && !initializingMCJob">
			<kendo-gridlayout
				[rows]="['100%']"
				[cols]="['calc(20% - (10px / 3))', 'calc(50% - (10px / 3))', 'calc(30% - (10px / 3))']"
				[gap]="{ rows: 0, cols: 5 }"
				class='grid'
				*ngIf='user?.userType !== "saasRisk" || (user?.userType === "saasRisk" && (_projectDashboardService.$currentProjectReport | async)?.updateIds?.length)'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' style='padding: 5px 0 5px 5px;'>
					<div class='left-tab'>
						<div class='pre-mitigation-title'>
							Pre-Mitigation Summary
						</div>
						<div class='date-bar'>
							Global Impact: -{{(_projectDashboardService.$currentProjectData.value?.preferences?.monteCarlo?.pfVariance?.[0] ?? 0.1) * 100 | number: '1.0-0'}}% / +{{(_projectDashboardService.$currentProjectData.value?.preferences?.monteCarlo?.pfVariance?.[1] ?? 0.1) * 100 | number: '1.0-0'}}%
						</div>
						<div class='current-p85-row'>
							<div class='date-bar'>
								Current: {{$currentCompletion | async | date: 'MM/dd/yy'}}
							</div>
							<div class='date-bar'>
								P85: {{$p85 | async | date: 'MM/dd/yy'}}
							</div>
						</div>

						<div class='date-bar'>
							P85 Delta: {{(($delta | async) || 0) * -1}} CDs
						</div>
						<div id="monteCarloP85Gauge" style='height: 208px; display: flex; flex-direction: column; justify-content: center; padding-top: 20px'>
							<app-gauge
								[value]='$performanceFactorScore'
								[colors]='pfColors'
								[label]='label'
								[idToWatchFor]="'monteCarloP85Gauge'"
							>
							</app-gauge>

						</div>
						<div class='date-bar'>
							Early: {{$earliest | async | date: 'MM/dd/yy'}}
						</div>
						<div class='date-bar'>
							Average: {{$avg | async | date: 'MM/dd/yy'}}
						</div>
						<div class='date-bar'>
							Late: {{$latest | async | date: 'MM/dd/yy'}}
						</div>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' style='padding: 5px 0;'>
					<div class='mc-graph-table-container'>
						<!--						<kendo-tabstrip class='tabstrip'>
													<kendo-tabstrip-tab title="Risk Analysis" [selected]='true'>-->
						<!--								<ng-template kendoTabContent>-->
						<kendo-grid
							[data]="gridView"
							[kendoGridBinding]="gridData"
							[height]="445"
							[resizable]='false'
							[sortable]="true"
							[sort]="sort"
							(sortChange)="sortChange($event)"
							[(selectedKeys)]='selectedRegisters'
							kendoGridSelectBy="riskId"
							[selectable]='{
								checkboxOnly: true,
								mode: "multiple"
							}'
							[rowSelected]="isRowSelected"
							id='monte-carlo-risk-selection-grid'
						>
							<ng-template kendoGridNoRecordsTemplate>
								<p>Calculate to enable</p>
							</ng-template>
							<kendo-grid-checkbox-column [width]='26' [hidden]='recalcing'></kendo-grid-checkbox-column>
							<kendo-grid-column
								title="Id"
								field='riskId'
								[width]='62'
							>
								<ng-template kendoGridCellTemplate let-dataItem="dataItem">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.riskId}}
											</span>
								</ng-template>
							</kendo-grid-column>
							<kendo-grid-column
								field="riskName"
								title="Name"
							>
								<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.riskName}}
											</span>
								</ng-template>
								<ng-template kendoGridFooterTemplate>
									Aggregate (Selected Risks)
								</ng-template>
							</kendo-grid-column>
							<kendo-grid-column-group title="Pre-Mitigation">
								<kendo-grid-column
									field="preMitigationActivityImpact"
									title="Activity Impact"
									[class]='"text-center"'
									[width]='87'
									[headerStyle]="{'background-color': '#f5c2c2'}"
									[footerStyle]="{'background-color': '#f5c2c2'}"
								>
									<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
												{{dataItem.preMitigationActivityImpact * 100 | number: '1.0-0'}}%
											</span>
									</ng-template>
									<ng-template kendoGridFooterTemplate>
										Pre:
									</ng-template>
								</kendo-grid-column>
								<kendo-grid-column
									field="preMitigationScheduleImpact"
									title="Schedule Impact"
									[class]='"text-center"'
									[width]='87'
									[headerStyle]="{'background-color': '#f5c2c2', 'padding': '0px'}"
									[footerStyle]="{'background-color': '#f5c2c2'}"
								>
									<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.preMitigationScheduleImpact != null ? dataItem.preMitigationScheduleImpact * -1 : ''}}
											</span>
									</ng-template>


									<ng-template kendoGridFooterTemplate>
										{{totalPreSchedImpact}}
									</ng-template>
								</kendo-grid-column>
							</kendo-grid-column-group>
							<kendo-grid-column-group title="Post-Mitigation">
								<kendo-grid-column
									field="postMitigationActivityImpact"
									title="Activity Impact"
									[class]='"text-center"'
									[width]='87'
									[headerStyle]="{'background-color': '#c0d6ec'}"
									[footerStyle]="{'background-color': '#c0d6ec'}"
								>
									<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.postMitigationActivityImpact * 100 | number: '1.0-0'}}%
											</span>
									</ng-template>
									<ng-template kendoGridFooterTemplate>
										Post:
									</ng-template>
								</kendo-grid-column>
								<kendo-grid-column
									field="postMitigationScheduleImpact"
									title="Schedule Impact"
									[width]='87'
									[class]='"text-center"'
									[headerStyle]="{'background-color': '#c0d6ec', 'padding': '0px'}"
									[footerStyle]="{'background-color': '#c0d6ec'}"
								>
									<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.postMitigationScheduleImpact != null ? dataItem.postMitigationScheduleImpact * -1 : ''}}
											</span>
									</ng-template>
									<ng-template kendoGridFooterTemplate>
										{{totalPostSchedImpact}}
									</ng-template>

								</kendo-grid-column>
							</kendo-grid-column-group>
							<kendo-grid-column
								field="impactDelta"
								title="Post Mitigation ∆"
								[width]='100'
								[class]='"text-center"'
								[headerStyle]="{'background-color': '#3a7dd3', color: 'white', 'padding': '0px'}"
								[footerStyle]="{'background-color': '#3a7dd3', color: 'white'}"
							>
								<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
											<span [class.crossout]='!isRowItemSelected(dataItem)'>
											{{dataItem.impactDelta != null ? dataItem.impactDelta * -1 : ''}}
											</span>
								</ng-template>
								<ng-template kendoGridFooterTemplate>
									{{totalPostSchedImpact - totalPreSchedImpact}}
								</ng-template>
							</kendo-grid-column>
							<kendo-grid-column
								field="impactDelta"
								title="Mitigation Cost Per Day"
								[width]='100'
								[class]='"text-center"'
								[headerStyle]="{'background-color': '#eeb900', color: 'white'}"
								[footerStyle]="{'background-color': '#eeb900', color: 'white'}"
							>
								<ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
									<span [class.crossout]='!isRowItemSelected(dataItem)'>
										{{dataItem?.costDelta === undefined ? '' : dataItem?.costDelta == null ? '-' : '$' + (dataItem?.costDelta | number: '1.0-0')}}
									</span>
								</ng-template>
								<ng-template kendoGridFooterTemplate>
									{{'$' + (totalCostDelta | number: '1.0-0')}}
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='3'>
					<div class='mc-hit-graph-container'>
						<kendo-chart style='height: 100%' [seriesColors]='["#f5c2c2", "#c0d6ec", "#dc3838", "#2f7fc9"]' [popupSettings]='{popupClass: "probability-popup"}'>
							<kendo-chart-tooltip [shared]="true">
								<ng-template
									kendoChartSharedTooltipTemplate
									let-category="category"
									let-points="points"
								>
									<div><b><u>{{ category | date: 'MM/dd/yy'}}</u></b></div>
									<div *ngIf='points[0]'>
										{{ points[0]?.series.name }}: <b>{{ points[0]?.value }} <span *ngIf='points[0]?.series.name.toLowerCase().includes("probability")'>%</span>
										<span *ngIf='!points[0]?.series.name.toLowerCase().includes("probability") && points.length > 2'>
											({{points[points[2]?.series.name.includes(points[0]?.series.name) ? 2 : 3]?.value || 0}}%)
										</span></b>
									</div>
									<div *ngIf='points[1]'>
										{{ points[1]?.series.name }}: <b>{{ points[1]?.value }}<span *ngIf='points[1]?.series.name.toLowerCase().includes("probability")'>%</span>
										<span *ngIf='!points[1]?.series.name.toLowerCase().includes("probability")'>
											({{points[points[3]?.series.name.includes(points[1]?.series.name) ? 3 : 2]?.value || 0}}%)
										</span></b>
									</div>
								</ng-template>
							</kendo-chart-tooltip>
							<kendo-chart-legend position="bottom" orientation="horizontal" align='center'>
							</kendo-chart-legend>
							<kendo-chart-series>
								<kendo-chart-series-item
									*ngFor="let item of likelihoodSeries"
									[data]="item.items"
									[name]="item.value"
									field="hits"
									categoryField="fixedDate"
									type="column"
									axis='hits'
									missingValues="interpolate"
									[spacing]="0"
								>
								</kendo-chart-series-item>
								<kendo-chart-series-item
									*ngFor="let item of probabilitySeries"
									[data]="item.items"
									[name]="item.value"
									field="value"
									categoryField="fixedDate"
									type="line"
									axis="probability"
									[style]='"smooth"'
									[markers]="{ visible: false }"
									missingValues="interpolate"
								>
								</kendo-chart-series-item>
							</kendo-chart-series>
							<kendo-chart-category-axis>
								<kendo-chart-category-axis-item
									[rangeLabels]="{ visible: true }"
									[labels]="{ rotation: 'auto' }"
									[maxDivisions]='10'
									[axisCrossingValue]='[0, 99999]'
								>
								</kendo-chart-category-axis-item>
							</kendo-chart-category-axis>
							<kendo-chart-value-axis>
								<kendo-chart-value-axis-item
									[title]="{ text: 'Hits' }"
									[min]="0"
									name='hits'
								>
									<kendo-chart-value-axis-item-labels [position]="'onAxis'">
									</kendo-chart-value-axis-item-labels>
								</kendo-chart-value-axis-item>
								<kendo-chart-value-axis-item
									[title]="{ text: 'Probability' }"
									[min]="0"
									[max]='100'
									[plotBands]="p85Band"
									name='probability'
								>
									<kendo-chart-value-axis-item-labels [content]="labelContentPct" [position]="'end'">
									</kendo-chart-value-axis-item-labels>
								</kendo-chart-value-axis-item>
							</kendo-chart-value-axis>

						</kendo-chart>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
			<kendo-gridlayout
				[rows]="['100%']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 0 }"
				class='grid'
				*ngIf='user?.userType === "saasRisk" && !(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<div style='display:flex; justify-content:center; align-items:center; height: 100%;'>
						Monte Carlo requires an update to calculate. Please upload one.
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotes) {
			<app-overview-notes [componentId]="18"></app-overview-notes>
		}
	</kendo-gridlayout>
</div>
