import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { CalendarArrayInterface, SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import {
	actvHasCode,
	isLookaheadCriticalPlanned,
	isLookaheadNearCriticalPlanned,
	isLookaheadNonCriticalPlanned,
	nearLongest,
} from '../../../../util/tasks';
import { RestService } from '../../../../services/common/rest.service';
import {
	AxisSettings,
	KendoColumn,
	PlotLineType,
	SeriesData,
	SeriesDataSettings,
} from '../../../../models/ChartSettings';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { hasObjChanged } from '../../../../util/projects';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import {
	Activity,
	ActivityInfo,
	Xer,
	XerActivity,
	XerActivityCode,
	XerActivityType,
	XerProject,
	XerTaskActivity,
} from '@rhinoworks/xer-parse';
import { IButton } from '../../../../models/Project';
import { haveCommonItem } from '../../../../util/strings';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { caretAltDownIcon, fileExcelIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ExpandedMetrics } from '../../../../models/ProjectReport/ExpandedMetrics';
import {
	findNLeastFloatPaths,
	isCriticalPlanned,
	isCriticalPlannedStart,
	isNearCriticalPlanned,
	isNearCriticalPlannedStart,
	isNonCriticalPlanned,
	isNonCriticalPlannedStart,
	isPlannedToBeFinishedBy,
	isPlannedToBeStartedBy,
} from '@rhinoworks/analytics-calculations';
import { addDays } from 'date-fns';

export const allColumns = require('./activity-completion-columns.json') as {
	columns: KendoColumn[];
};

export enum ActvCompletionView {
	overview = 'overview',
	critical = 'Critical',
	noncritical = 'Non Critical',
	nearcritical = 'Near Critical',
	total = 'Total',
}

export type ActvCompletionActv = SlimmedTaskCommon & {
	tfHrs?: number;
	latest_status?: 'Not Started' | 'Completed' | 'Incomplete' | 'Deleted' | 'Started';
	latest_end_date?: Date;
	latest_start_date?: Date;
	is_actual_start?: boolean;
	is_actual_end?: boolean;
	category?: ActvCompletionView;
	activityCodes?: string; //comma separated shortcode string, mainly for excel export
};

export const statusDict = {
	TK_Complete: 'Completed',
	TK_NotStart: 'Not Started',
	TK_Active: 'Incomplete',
};

export type ActvCodeFilterItem = CodeFilterItem & {
	subCodes: SubCodeFilterItem[];
};

function union<T>(array1: T[], array2: T[]): T[] {
	return [...new Set([...array1, ...array2])];
}

export type CodeFilterItem = {
	alwaysDisabled: boolean;
	disabled: boolean;
	id: number;
	name: string;
	codeName: string;
	sequenceNumber: number;
	shortName: string;
	totalAssignments: number;
	parentId?: number;
};

export type SubCodeFilterItem = CodeFilterItem & {
	parentName: string;
	alwaysDisabled: boolean;
};
declare let saveAs;
@Component({
	selector: 'app-activity-completion',
	templateUrl: './activity-completion.component.html',
	styleUrls: ['./activity-completion.component.scss'],
})
export class ActivityCompletionComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgSearch: SVGIcon = searchIcon;
	icons = {
		caretDown: caretAltDownIcon,
	};
	@Input() visualizer: boolean = false;
	@Input() hideBars: boolean = false;
	@Input() hideTrending: boolean = false;
	@Input() isOverview: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() finishMilestone: XerActivity;
	@Input() hideBtns: boolean = false;
	@Input() hideNotes: boolean = false;
	@Input() defaultSelectedActivityCodes: SubCodeFilterItem;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	ActvCompletionView = ActvCompletionView;
	$criticalPercentComplete = new BehaviorSubject<number>(0);
	$nearCriticalPercentComplete = new BehaviorSubject<number>(0);
	$nonCriticalPercentComplete = new BehaviorSubject<number>(0);
	$totalPercentComplete = new BehaviorSubject<number>(0);
	$criticalPercentCompleteStart = new BehaviorSubject<number>(0);
	$nearCriticalPercentCompleteStart = new BehaviorSubject<number>(0);
	$nonCriticalPercentCompleteStart = new BehaviorSubject<number>(0);
	$totalPercentCompleteStart = new BehaviorSubject<number>(0);
	$criticalPercentCompleteAll = new BehaviorSubject<number>(0);
	$nearCriticalPercentCompleteAll = new BehaviorSubject<number>(0);
	$nonCriticalPercentCompleteAll = new BehaviorSubject<number>(0);
	$totalPercentCompleteAll = new BehaviorSubject<number>(0);
	categories: string[] = ['Critical\n(Longest Path)', 'Near Critical', 'Non Critical', 'Total'];
	seriesData: SeriesDataSettings[] = [];
	seriesDataStart: SeriesDataSettings[] = [];
	seriesDataAll: SeriesDataSettings[] = [];
	allSubCodes;
	lookahead30SeriesData: SeriesDataSettings[] = [];
	lookahead60SeriesData: SeriesDataSettings[] = [];
	lookahead90SeriesData: SeriesDataSettings[] = [];
	lookahead30SeriesDataStart: SeriesDataSettings[] = [];
	lookahead60SeriesDataStart: SeriesDataSettings[] = [];
	lookahead90SeriesDataStart: SeriesDataSettings[] = [];
	lookahead30SeriesDataAll: SeriesDataSettings[] = [];
	lookahead60SeriesDataAll: SeriesDataSettings[] = [];
	lookahead90SeriesDataAll: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Activity Starts & Finishes',
				visible: true,
			},
			labels: {
				format: '{0}',
			},
			majorGridLines: {
				visible: true,
			},
		},
	];
	trendCategories: string[] = [];
	trendSeriesData: SeriesDataSettings[] = [];
	trendSeriesDataStart: SeriesDataSettings[] = [];
	allTotalSeriesData: SeriesDataSettings[] = [];
	trendValueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Completion Ratio',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			min: 0,
			max: 100,
			majorGridLines: {
				visible: true,
			},
		},
	];
	trendPlotLines: PlotLineType[] = [
		{
			color: '#DF5353',
			dashType: 'dash',
			label: '100% Target',
			value: 100,
			width: 1,
		},
		{
			color: '#4fc931',
			dashType: 'dash',
			label: '80% Target',
			value: 80,
			width: 1,
		},
	];
	tableView: ActvCompletionView = ActvCompletionView.overview;
	allPrevActivities = new Map<string, ActvCompletionActv>([]);
	totalActivityCodes = [];
	currentDisplaySet: ActvCompletionActv[] = [];
	unfilteredDisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead30DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead60DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead90DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredDisplaySetStart: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead30DisplaySetStart: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead60DisplaySetStart: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead90DisplaySetStart: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	tableSearch = '';
	exportProcessing: boolean = false;
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	loading: boolean = true;
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'target_end_date',
		},
	];
	public pageSize = 100;
	public skip = 0;
	selectedColumns: KendoColumn[] = [];
	searchTerm = '';
	currentXer: Xer;
	lastXer: Xer;
	latestUpdateActivityMap: Map<string, Activity> = new Map<string, Activity>([]);
	criticalPlanned: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	criticalActual: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nearCriticalPlanned: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nearCriticalActual: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nonCriticalPlanned: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nonCriticalActual: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	totalPlanned: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	totalActual: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	criticalPlannedStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	criticalActualStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nearCriticalPlannedStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nearCriticalActualStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nonCriticalPlannedStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	nonCriticalActualStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	totalPlannedStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	totalActualStart: Map<string, Activity[]> = new Map<string, Activity[]>([]);
	public selectedActivityCodes: SubCodeFilterItem[] = [];
	startTotalPerCode: Map<string, number> = new Map<string, number>();
	finishTotalPerCode: Map<string, number> = new Map<string, number>();
	start30TotalPerCode: Map<string, number> = new Map<string, number>();
	finish30TotalPerCode: Map<string, number> = new Map<string, number>();
	start60TotalPerCode: Map<string, number> = new Map<string, number>();
	finish60TotalPerCode: Map<string, number> = new Map<string, number>();
	start90TotalPerCode: Map<string, number> = new Map<string, number>();
	finish90TotalPerCode: Map<string, number> = new Map<string, number>();
	allActivityCodes: ActvCodeFilterItem[] = [];
	nonDisabledActivityCodes: ActvCodeFilterItem[] = [];
	codesTag: string = '';
	@ViewChild('activityCodesMultiselectTree', { static: true }) public multiSelectTree: any;
	public lastUpdateId: string;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isLookahead: boolean = false;
	calendars = new Map<number, CalendarArrayInterface>([]);
	public timespanButtons: IButton[] = [
		{
			text: '30',
			value: 30,
			selected: true,
			disabled: false,
		},
		{
			text: '60',
			value: 60,
			disabled: false,
		},
		{
			text: '90',
			value: 90,
			disabled: false,
		},
	];
	selectedTimespan: number = 30;
	selectedBasedOn: string = 'All';
	public forwardOrBackwardButtons: IButton[] = [
		{
			text: 'Actual',
			value: -1,
			selected: true,
			disabled: false,
		},
		{
			text: 'Planned',
			value: 1,
			disabled: false,
		},
	];
	public basedOnButtons: IButton[] = [
		{
			text: 'All',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Start',
			value: 1,
			disabled: false,
		},
		{
			text: 'Finish',
			value: 2,
			disabled: false,
		},
	];
	hasNotes: boolean = false;
	hasNotesTrending: boolean = false;
	eligibleFocusTabCodes: XerActivityCode[] = [];
	eligibleFocusTabCodesAndTypes: number[] = [];
	filteredTasksPerCode: Map<string, Activity[]> = new Map<string, Activity[]>([]);

	constructor(
		public project: ProjectDashboardService,
		private restService: RestService,
		public storage: ScheduleStorageService,
		public navBarStorage: NavigationBarStorageService
	) {
		this.itemDisabled = this.itemDisabled.bind(this);
	}

	ngOnInit(): void {
		this.selectedActivityCodes = [];
		this.project.$currentProjectData.subscribe((project) => {
			this.lastUpdateId = project?.updateIds[project.updateIds.length - 2];
			if (project) {
				const savedNotes = project.componentNotes?.find((n) => n.id === 7)?.notes;
				const savedNotesTrending = project.componentNotes?.find((n) => n.id === 8)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
				this.hasNotesTrending =
					savedNotesTrending?.length && savedNotesTrending[savedNotesTrending?.length - 1]?.note !== '';
			}
		});

		forkJoin([
			this.project.$currentProjectReport.pipe(
				filter((report) => report != null),
				take(1)
			),
			this.storage.$allUpdates.pipe(
				filter((updates) => updates && updates.length === this.project.$currentProjectData.value?.updateIds.length),
				take(1)
			),
		]).subscribe(([report, updates]) => {
			this.updateData(updates[updates.length - 2], updates[updates.length - 1], report, false);
		});
		this.project.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll), debounceTime(100)).subscribe((report) => {
			// this.getScheduleTrendData(report);
			if (report?.project?.company) {
				this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
			}
		});

		this.project.$expandedMetrics.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (metrics: ExpandedMetrics) => {
			metrics = metrics as ExpandedMetrics;
			if (!metrics) {
				return;
			}

			this.calendars.clear();
			const calendars = metrics.calendars || [];
			for (const calendar of calendars) {
				this.calendars.set(calendar.clndr_id, calendar);
			}
		});

		if (this.hideBtns) {
			this.forwardOrBackwardButtons = [
				{
					text: 'Actual',
					value: -1,
					selected: false,
					disabled: false,
				},
				{
					text: 'Planned',
					value: 1,
					selected: true,
					disabled: false,
				},
			];
			this.timespanButtons = [
				{
					text: '30',
					value: 30,
					selected: false,
					disabled: false,
				},
				{
					text: '60',
					value: 60,
					selected: false,
					disabled: false,
				},
				{
					text: '90',
					value: 90,
					selected: true,
					disabled: false,
				},
			];
			this.selectedTimespan = 90;
			this.isLookahead = true;
			this.basedOnButtons = [
				{
					text: 'All',
					value: 0,
					selected: true,
					disabled: false,
				},
				{
					text: 'Start',
					value: 1,
					disabled: false,
				},
				{
					text: 'Finish',
					value: 2,
					disabled: false,
				},
			];
			this.selectedBasedOn = 'Finish';
			this.setTableView(this.tableView);
		}
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * update grid data and prepare 2 charts data
	 * @param prevUpdate
	 * @param currUpdate
	 * @param report
	 * @param fromActivityCodeFiltering
	 */
	async updateData(
		prevUpdate: UpdateInterface,
		currUpdate: UpdateInterface,
		report: CurrentProjectReport,
		fromActivityCodeFiltering: boolean = false
	) {
		if (!prevUpdate || !currUpdate || report?.updateIds?.length < 2) {
			return;
		}
		this.loading = true;
		this.selectedColumns =
			this.selectedBasedOn !== 'All'
				? [...allColumns.columns.slice(0, -2), ...allColumns.columns.slice(-1)]
				: allColumns.columns;
		this.criticalPlanned.clear();
		this.criticalActual.clear();
		this.nearCriticalPlanned.clear();
		this.nearCriticalActual.clear();
		this.nonCriticalPlanned.clear();
		this.nonCriticalActual.clear();
		this.totalPlanned.clear();
		this.totalActual.clear();
		this.criticalPlannedStart.clear();
		this.criticalActualStart.clear();
		this.nearCriticalPlannedStart.clear();
		this.nearCriticalActualStart.clear();
		this.nonCriticalPlannedStart.clear();
		this.nonCriticalActualStart.clear();
		this.totalPlannedStart.clear();
		this.totalActualStart.clear();
		const criticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.criticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const criticalDataStart: SeriesData[] = (
			report.activityStartingGraph.projectTrendGraph.criticalStartPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nearCriticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.nearCriticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nearCriticalDataStart: SeriesData[] = (
			report.activityStartingGraph.projectTrendGraph.nearCriticalStartPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nonCriticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.nonCriticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nonCriticalDataStart: SeriesData[] = (
			report.activityStartingGraph.projectTrendGraph.nonCriticalStartPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const totalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.totalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const totalDataStart: SeriesData[] = (
			report.activityStartingGraph.projectTrendGraph.totalStartPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const categories: string[] = [];
		const currXerData = (await this.storage.grabUpdateXer(currUpdate._id))?.xerData;
		const currXer = new Xer(currXerData, currUpdate.selectedProjectId || currUpdate.finishMilestone?.proj_id);
		const critPath = this.isFocus
			? findNLeastFloatPaths(
					currXer.activitiesByCode.get(this.finishMilestone?.task_code || currUpdate.finishMilestone?.task_code),
					1,
					currXer.sortedActivities
				)?.[0]
			: undefined;
		const currCritActivities = this.isFocus
			? critPath.path
					.map((pred) => pred.prevActivity)
					.concat(
						currXer.activitiesByCode.get(this.finishMilestone?.task_code || currUpdate.finishMilestone?.task_code)
					)
			: currXer.sortedActivities.filter((a) => a.isCritical);
		const currNearCriticals = this.isFocus ? nearLongest(currXer, critPath.float / 60) : nearLongest(currXer);
		this.currentXer = currXer;
		const prevXerData = (await this.storage.grabUpdateXer(prevUpdate._id)).xerData;
		const prevXer = new Xer(prevXerData, prevUpdate.selectedProjectId || prevUpdate.finishMilestone?.proj_id);
		const prevNearCriticals = this.isFocus ? nearLongest(prevXer, critPath.float / 60) : nearLongest(prevXer);
		const prevCritActivities = this.isFocus
			? findNLeastFloatPaths(
					prevXer.activitiesByCode.get(this.finishMilestone?.task_code || prevUpdate.finishMilestone?.task_code),
					1,
					prevXer.sortedActivities
				)?.[0]
					?.path.map((pred) => pred.prevActivity)
					.concat(
						prevXer.activitiesByCode.get(this.finishMilestone?.task_code || prevUpdate.finishMilestone?.task_code)
					)
			: prevXer.sortedActivities.filter((a) => a.isCritical);
		this.lastXer = prevXer;
		this.allActivityCodes = this.storage.generateActivityCodeFilter(
			currXer,
			this.selectedActivityCodes.concat(this.defaultSelectedActivityCodes || [])
		);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.nonDisabledActivityCodes = this.getNonDisabledItems(this.allActivityCodes);
		this.allSubCodes = allSubCodes;

		const calculations = (selectedActivityCodes: Set<{ shortName: string; codeName: string }>, simming = true) => {
			//focus tab filters pool of overall tasks to ones included in selected code, so subsequent filters should exclude this code

			const category = 'Update ' + (report.updateIds.length - 1) + (currUpdate?.baseline ? ' ®' : '');
			categories.push(category);
			const criticalPlanned: Activity[] = [];
			const criticalActual: Activity[] = [];
			const nearCriticalPlanned: Activity[] = [];
			const nearCriticalActual: Activity[] = [];
			const nonCriticalPlanned: Activity[] = [];
			const nonCriticalActual: Activity[] = [];
			const criticalPlannedStart: Activity[] = [];
			const criticalActualStart: Activity[] = [];
			const nearCriticalPlannedStart: Activity[] = [];
			const nearCriticalActualStart: Activity[] = [];
			const nonCriticalPlannedStart: Activity[] = [];
			const nonCriticalActualStart: Activity[] = [];
			const criticalLookahead30: Activity[] = [];
			const criticalLookahead60: Activity[] = [];
			const criticalLookahead90: Activity[] = [];
			const nearCriticalLookahead30: Activity[] = [];
			const nearCriticalLookahead60: Activity[] = [];
			const nearCriticalLookahead90: Activity[] = [];
			const nonCriticalLookahead30: Activity[] = [];
			const nonCriticalLookahead60: Activity[] = [];
			const nonCriticalLookahead90: Activity[] = [];
			let totalLookahead30: Activity[];
			let totalLookahead60: Activity[] = [];
			let totalLookahead90: Activity[] = [];
			const criticalLookahead30Start: Activity[] = [];
			const criticalLookahead60Start: Activity[] = [];
			const criticalLookahead90Start: Activity[] = [];
			const nearCriticalLookahead30Start: Activity[] = [];
			const nearCriticalLookahead60Start: Activity[] = [];
			const nearCriticalLookahead90Start: Activity[] = [];
			const nonCriticalLookahead30Start: Activity[] = [];
			const nonCriticalLookahead60Start: Activity[] = [];
			const nonCriticalLookahead90Start: Activity[] = [];
			let totalLookahead30Start: Activity[] = [];
			let totalLookahead60Start: Activity[] = [];
			let totalLookahead90Start: Activity[] = [];
			const currentActivities: number[] = [];
			const tasksForFocusCodeMap: number[] = this.defaultSelectedActivityCodes
				? currXer.taskActivities
						.filter((m) => m.actv_code_id === this.defaultSelectedActivityCodes.id)
						.map((c) => c.task_id)
				: [];
			for (const taskPrev of prevXer.sortedActivities) {
				const activityCurrently = currXer.activitiesByCode.get(taskPrev.code);
				if (
					!activityCurrently ||
					activityCurrently._activity.taskType === 'TT_LOE' ||
					(this.defaultSelectedActivityCodes && !tasksForFocusCodeMap.includes(activityCurrently.id))
				) {
					continue;
				}
				if (selectedActivityCodes?.size && !actvHasCode(activityCurrently, Array.from(selectedActivityCodes))) {
					continue;
				}
				currentActivities.push(activityCurrently.id);
				const isCritPrev = prevCritActivities.includes(taskPrev);
				const passTaskPrev: ActivityInfo = {
					...taskPrev.actvInfo,
					isCritical: isCritPrev,
					longestPath: isCritPrev,
				};
				const isCritCurr = currCritActivities.includes(activityCurrently);
				const isNearCritCurr = currNearCriticals.has(activityCurrently);
				const isNonCritCurr = !isCritCurr && !isNearCritCurr;
				const isNearCritPrev = prevNearCriticals.has(taskPrev);
				const isNonCritPrev = !isCritPrev && !isNearCritPrev;
				const currentDataDate = activityCurrently.recalcDate;
				if (isCriticalPlanned(passTaskPrev, currentDataDate) && isCritPrev) {
					criticalPlanned.push(taskPrev);
					if (activityCurrently?.isComplete) {
						criticalActual.push(activityCurrently);
					}
				}

				if (isNearCriticalPlanned(passTaskPrev, currentDataDate) && isNearCritPrev) {
					nearCriticalPlanned.push(taskPrev);
					if (activityCurrently?.isComplete) {
						nearCriticalActual.push(activityCurrently);
					}
				}

				if (isNonCriticalPlanned(passTaskPrev, currentDataDate) && isNonCritPrev) {
					nonCriticalPlanned.push(taskPrev);
					if (activityCurrently?.isComplete) {
						nonCriticalActual.push(activityCurrently);
					}
				}

				if (!taskPrev.start) {
					if (isCriticalPlannedStart(passTaskPrev, currentDataDate) && isCritPrev) {
						criticalPlannedStart.push(taskPrev);
						if (activityCurrently?.start) {
							criticalActualStart.push(activityCurrently);
						}
					}

					if (isNearCriticalPlannedStart(passTaskPrev, currentDataDate) && isNearCritPrev) {
						nearCriticalPlannedStart.push(taskPrev);
						if (activityCurrently?.start) {
							nearCriticalActualStart.push(activityCurrently);
						}
					}

					if (isNonCriticalPlannedStart(passTaskPrev, currentDataDate) && isNonCritPrev) {
						nonCriticalPlannedStart.push(taskPrev);
						if (activityCurrently?.start) {
							nonCriticalActualStart.push(activityCurrently);
						}
					}
				}
				if (!activityCurrently.isComplete) {
					if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 90, false, isCritCurr)) {
						criticalLookahead90.push(activityCurrently);
						if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 60, false, isCritCurr)) {
							criticalLookahead60.push(activityCurrently);
							if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 30, false, isCritCurr)) {
								criticalLookahead30.push(activityCurrently);
							}
						}
					}
					if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 90, true, isCritCurr)) {
						criticalLookahead90Start.push(activityCurrently);
						if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 60, true, isCritCurr)) {
							criticalLookahead60Start.push(activityCurrently);
							if (isLookaheadCriticalPlanned(activityCurrently, currentDataDate, 30, true, isCritCurr)) {
								criticalLookahead30Start.push(activityCurrently);
							}
						}
					}
				}
				if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 90, false, isNearCritCurr)) {
					nearCriticalLookahead90.push(activityCurrently);
					if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 60, false, isNearCritCurr)) {
						nearCriticalLookahead60.push(activityCurrently);
						if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 30, false, isNearCritCurr)) {
							nearCriticalLookahead30.push(activityCurrently);
						}
					}
				}
				if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 90, true, isNearCritCurr)) {
					nearCriticalLookahead90Start.push(activityCurrently);
					if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 60, true, isNearCritCurr)) {
						nearCriticalLookahead60Start.push(activityCurrently);
						if (isLookaheadNearCriticalPlanned(activityCurrently, currentDataDate, 30, true, isNearCritCurr)) {
							nearCriticalLookahead30Start.push(activityCurrently);
						}
					}
				}
				if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 90, false, isNonCritCurr)) {
					nonCriticalLookahead90.push(activityCurrently);
					if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 60, false, isNonCritCurr)) {
						nonCriticalLookahead60.push(activityCurrently);
						if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 30, false, isNonCritCurr)) {
							nonCriticalLookahead30.push(activityCurrently);
						}
					}
				}
				if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 90, true, isNonCritCurr)) {
					nonCriticalLookahead90Start.push(activityCurrently);
					if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 60, true, isNonCritCurr)) {
						nonCriticalLookahead60Start.push(activityCurrently);
						if (isLookaheadNonCriticalPlanned(activityCurrently, currentDataDate, 30, true, isNonCritCurr)) {
							nonCriticalLookahead30Start.push(activityCurrently);
						}
					}
				}
			}
			const codeMappingForCurrentTasks: XerTaskActivity[] = this.currentXer.taskActivities.filter(
				(a) =>
					currentActivities.findIndex((c) => c === a.task_id) !== -1 &&
					this.defaultSelectedActivityCodes?.id !== a.actv_code_id
			);
			const x: number[] = [...new Set(codeMappingForCurrentTasks.map((item) => item.actv_code_id))];
			const y: number[] = [...new Set(codeMappingForCurrentTasks.map((item) => item.actv_code_type_id))];
			this.eligibleFocusTabCodesAndTypes = [...x, ...y];
			this.unfilteredLookahead30DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead60DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead90DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead30DisplaySetStart = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead60DisplaySetStart = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead90DisplaySetStart = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			totalLookahead30 = [...criticalLookahead30, ...nearCriticalLookahead30, ...nonCriticalLookahead30];
			totalLookahead60 = [...criticalLookahead60, ...nearCriticalLookahead60, ...nonCriticalLookahead60];
			totalLookahead90 = [...criticalLookahead90, ...nearCriticalLookahead90, ...nonCriticalLookahead90];
			totalLookahead30Start = [
				...criticalLookahead30Start,
				...nearCriticalLookahead30Start,
				...nonCriticalLookahead30Start,
			];
			totalLookahead60Start = [
				...criticalLookahead60Start,
				...nearCriticalLookahead60Start,
				...nonCriticalLookahead60Start,
			];
			totalLookahead90Start = [
				...criticalLookahead90Start,
				...nearCriticalLookahead90Start,
				...nonCriticalLookahead90Start,
			];
			this.fitDataToType(criticalLookahead30, ActvCompletionView.critical, 'unfilteredLookahead30DisplaySet');
			this.fitDataToType(nearCriticalLookahead30, ActvCompletionView.nearcritical, 'unfilteredLookahead30DisplaySet');
			this.fitDataToType(nonCriticalLookahead30, ActvCompletionView.noncritical, 'unfilteredLookahead30DisplaySet');
			this.fitDataToType(criticalLookahead60, ActvCompletionView.critical, 'unfilteredLookahead60DisplaySet');
			this.fitDataToType(nearCriticalLookahead60, ActvCompletionView.nearcritical, 'unfilteredLookahead60DisplaySet');
			this.fitDataToType(nonCriticalLookahead60, ActvCompletionView.noncritical, 'unfilteredLookahead60DisplaySet');
			this.fitDataToType(criticalLookahead90, ActvCompletionView.critical, 'unfilteredLookahead90DisplaySet');
			this.fitDataToType(nearCriticalLookahead90, ActvCompletionView.nearcritical, 'unfilteredLookahead90DisplaySet');
			this.fitDataToType(nonCriticalLookahead90, ActvCompletionView.noncritical, 'unfilteredLookahead90DisplaySet');
			this.fitDataToType(criticalLookahead30Start, ActvCompletionView.critical, 'unfilteredLookahead30DisplaySetStart');
			this.fitDataToType(
				nearCriticalLookahead30Start,
				ActvCompletionView.nearcritical,
				'unfilteredLookahead30DisplaySetStart'
			);
			this.fitDataToType(
				nonCriticalLookahead30Start,
				ActvCompletionView.noncritical,
				'unfilteredLookahead30DisplaySetStart'
			);
			this.fitDataToType(criticalLookahead60Start, ActvCompletionView.critical, 'unfilteredLookahead60DisplaySetStart');
			this.fitDataToType(
				nearCriticalLookahead60Start,
				ActvCompletionView.nearcritical,
				'unfilteredLookahead60DisplaySetStart'
			);
			this.fitDataToType(
				nonCriticalLookahead60Start,
				ActvCompletionView.noncritical,
				'unfilteredLookahead60DisplaySetStart'
			);
			this.fitDataToType(criticalLookahead90Start, ActvCompletionView.critical, 'unfilteredLookahead90DisplaySetStart');
			this.fitDataToType(
				nearCriticalLookahead90Start,
				ActvCompletionView.nearcritical,
				'unfilteredLookahead90DisplaySetStart'
			);
			this.fitDataToType(
				nonCriticalLookahead90Start,
				ActvCompletionView.noncritical,
				'unfilteredLookahead90DisplaySetStart'
			);
			this.lookahead30SeriesData = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead30?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead30?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead30?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead30?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead30SeriesDataStart = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead30Start?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead30Start?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead30Start?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead30Start?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead30SeriesDataAll = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead30Start?.length + criticalLookahead30?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead30Start?.length + nearCriticalLookahead30?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead30Start?.length + nonCriticalLookahead30?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead30Start?.length + totalLookahead30?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead60SeriesData = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead60?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead60?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead60?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead60?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead60SeriesDataStart = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead60Start?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead60Start?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead60Start?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead60Start?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead60SeriesDataAll = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead60Start?.length + criticalLookahead60?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead60Start?.length + nearCriticalLookahead60?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead60Start?.length + nonCriticalLookahead60?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead60Start?.length + totalLookahead60?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead90SeriesData = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead90?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead90?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead90?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead90?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead90SeriesDataStart = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead90Start?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead90Start?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead90Start?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead90Start?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			this.lookahead90SeriesDataAll = [
				{
					type: 'column',
					data: [
						{
							category: 'Critical\n(Longest Path)',
							value: criticalLookahead90Start?.length + criticalLookahead90?.length || 0,
						},
						{
							category: 'Near Critical',
							value: nearCriticalLookahead90Start?.length + nearCriticalLookahead90?.length || 0,
						},
						{
							category: 'Non Critical',
							value: nonCriticalLookahead90Start?.length + nonCriticalLookahead90?.length || 0,
						},
						{
							category: 'Total',
							value: totalLookahead90Start?.length + totalLookahead90?.length || 0,
						},
					],
					name: 'Planned',
					visible: true,
					color: '#8a8a8a',
					marker: 'rect',
				},
			];
			const totalPlanned: Activity[] = [...criticalPlanned, ...nearCriticalPlanned, ...nonCriticalPlanned];
			const totalActual: Activity[] = [...criticalActual, ...nearCriticalActual, ...nonCriticalActual];
			this.criticalPlanned.set(prevUpdate._id, criticalPlanned);
			this.criticalActual.set(currUpdate._id, criticalActual);
			this.nearCriticalPlanned.set(prevUpdate._id, nearCriticalPlanned);
			this.nearCriticalActual.set(currUpdate._id, nearCriticalActual);
			this.nonCriticalPlanned.set(prevUpdate._id, nonCriticalPlanned);
			this.nonCriticalActual.set(currUpdate._id, nonCriticalActual);
			this.totalPlanned.set(prevUpdate._id, totalPlanned);
			this.totalActual.set(currUpdate._id, totalActual);
			const totalPlannedStart: Activity[] = [
				...criticalPlannedStart,
				...nearCriticalPlannedStart,
				...nonCriticalPlannedStart,
			];
			const totalActualStart: Activity[] = [
				...criticalActualStart,
				...nearCriticalActualStart,
				...nonCriticalActualStart,
			];
			this.criticalPlannedStart.set(prevUpdate._id, criticalPlannedStart);
			this.criticalActualStart.set(currUpdate._id, criticalActualStart);
			this.nearCriticalPlannedStart.set(prevUpdate._id, nearCriticalPlannedStart);
			this.nearCriticalActualStart.set(currUpdate._id, nearCriticalActualStart);
			this.nonCriticalPlannedStart.set(prevUpdate._id, nonCriticalPlannedStart);
			this.nonCriticalActualStart.set(currUpdate._id, nonCriticalActualStart);
			this.totalPlannedStart.set(prevUpdate._id, totalPlannedStart);
			this.totalActualStart.set(currUpdate._id, totalActualStart);
			this.unfilteredDisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredDisplaySetStart = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.fitDataToType(this.criticalPlanned.get(prevUpdate?._id), ActvCompletionView.critical);
			this.fitDataToType(this.nearCriticalPlanned.get(prevUpdate?._id), ActvCompletionView.nearcritical);
			this.fitDataToType(this.nonCriticalPlanned.get(prevUpdate?._id), ActvCompletionView.noncritical);
			this.fitDataToType(this.criticalPlannedStart.get(prevUpdate?._id), ActvCompletionView.critical, '', true);
			this.fitDataToType(this.nearCriticalPlannedStart.get(prevUpdate?._id), ActvCompletionView.nearcritical, '', true);
			this.fitDataToType(this.nonCriticalPlannedStart.get(prevUpdate?._id), ActvCompletionView.noncritical, '', true);
			this.currentDisplaySet = this.getDisplaySet(this.tableView);
			this.loading = false;
			this.$criticalPercentComplete.next(
				100 *
					(this.criticalActual.get(currUpdate._id)?.length / (this.criticalPlanned.get(prevUpdate._id)?.length || 1))
			);
			this.$criticalPercentCompleteStart.next(
				100 *
					(this.criticalActualStart.get(currUpdate._id)?.length /
						(this.criticalPlannedStart.get(prevUpdate._id)?.length || 1))
			);
			const critDom: number =
				(this.criticalPlannedStart.get(prevUpdate._id)?.length || 0) +
				(this.criticalPlanned.get(prevUpdate._id)?.length || 0);
			this.$criticalPercentCompleteAll.next(
				100 *
					((this.criticalActualStart.get(currUpdate._id)?.length + this.criticalActual.get(currUpdate._id)?.length) /
						(critDom || 1))
			);
			this.$nearCriticalPercentComplete.next(
				100 *
					(this.nearCriticalActual.get(currUpdate._id)?.length /
						(this.nearCriticalPlanned.get(prevUpdate._id)?.length || 1))
			);
			this.$nearCriticalPercentCompleteStart.next(
				100 *
					(this.nearCriticalActualStart.get(currUpdate._id)?.length /
						(this.nearCriticalPlannedStart.get(prevUpdate._id)?.length || 1))
			);
			const nearCritDom: number =
				(this.nearCriticalPlannedStart.get(prevUpdate._id)?.length || 0) +
				(this.nearCriticalPlanned.get(prevUpdate._id)?.length || 0);
			this.$nearCriticalPercentCompleteAll.next(
				100 *
					((this.nearCriticalActualStart.get(currUpdate._id)?.length +
						this.nearCriticalActual.get(currUpdate._id)?.length) /
						(nearCritDom || 1))
			);
			this.$nonCriticalPercentComplete.next(
				100 *
					(this.nonCriticalActual.get(currUpdate._id)?.length /
						(this.nonCriticalPlanned.get(prevUpdate._id)?.length || 1))
			);
			this.$nonCriticalPercentCompleteStart.next(
				100 *
					(this.nonCriticalActualStart.get(currUpdate._id)?.length /
						(this.nonCriticalPlannedStart.get(prevUpdate._id)?.length || 1))
			);
			const nonCritDom: number =
				(this.nonCriticalPlannedStart.get(prevUpdate._id)?.length || 0) +
				(this.nonCriticalPlanned.get(prevUpdate._id)?.length || 0);
			this.$nonCriticalPercentCompleteAll.next(
				100 *
					((this.nonCriticalActualStart.get(currUpdate._id)?.length +
						this.nonCriticalActual.get(currUpdate._id)?.length) /
						(nonCritDom || 1))
			);
			this.$totalPercentComplete.next(
				100 * (this.totalActual.get(currUpdate._id)?.length / (this.totalPlanned.get(prevUpdate._id)?.length || 1))
			);
			this.$totalPercentCompleteStart.next(
				100 *
					(this.totalActualStart.get(currUpdate._id)?.length /
						(this.totalPlannedStart.get(prevUpdate._id)?.length || 1))
			);
			const totalDom: number =
				(this.totalPlannedStart.get(prevUpdate._id)?.length || 0) +
				(this.totalPlanned.get(prevUpdate._id)?.length || 0);
			this.$totalPercentCompleteAll.next(
				100 *
					((this.totalActualStart.get(currUpdate._id)?.length + this.totalActual.get(currUpdate._id)?.length) /
						(totalDom || 1))
			);
			const plannedData: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.criticalPlanned.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Near Critical',
					value: this.nearCriticalPlanned.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Non Critical',
					value: this.nonCriticalPlanned.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Total',
					value: this.totalPlanned.get(prevUpdate._id)?.length || 0,
				},
			];
			const plannedDataStart: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.criticalPlannedStart.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Near Critical',
					value: this.nearCriticalPlannedStart.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Non Critical',
					value: this.nonCriticalPlannedStart.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Total',
					value: this.totalPlannedStart.get(prevUpdate._id)?.length || 0,
				},
			];
			const plannedDataAll: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value:
						this.criticalPlannedStart.get(prevUpdate._id)?.length + this.criticalPlanned.get(prevUpdate._id)?.length ||
						0,
				},
				{
					category: 'Near Critical',
					value:
						this.nearCriticalPlannedStart.get(prevUpdate._id)?.length +
							this.nearCriticalPlanned.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Non Critical',
					value:
						this.nonCriticalPlannedStart.get(prevUpdate._id)?.length +
							this.nonCriticalPlanned.get(prevUpdate._id)?.length || 0,
				},
				{
					category: 'Total',
					value:
						this.totalPlannedStart.get(prevUpdate._id)?.length + this.totalPlanned.get(prevUpdate._id)?.length || 0,
				},
			];
			const completedData: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.criticalActual.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Near Critical',
					value: this.nearCriticalActual.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Non Critical',
					value: this.nonCriticalActual.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Total',
					value: this.totalActual.get(currUpdate._id)?.length || 0,
				},
			];
			const startedData: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.criticalActualStart.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Near Critical',
					value: this.nearCriticalActualStart.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Non Critical',
					value: this.nonCriticalActualStart.get(currUpdate._id)?.length || 0,
				},
				{
					category: 'Total',
					value: this.totalActualStart.get(currUpdate._id)?.length || 0,
				},
			];
			const completedDataAll: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value:
						(this.criticalActualStart.get(currUpdate._id)?.length || 0) +
						(this.criticalActual.get(currUpdate._id)?.length || 0),
				},
				{
					category: 'Near Critical',
					value:
						(this.nearCriticalActualStart.get(currUpdate._id)?.length || 0) +
						(this.nearCriticalActual.get(currUpdate._id)?.length || 0),
				},
				{
					category: 'Non Critical',
					value:
						(this.nonCriticalActualStart.get(currUpdate._id)?.length || 0) +
						(this.nonCriticalActual.get(currUpdate._id)?.length || 0),
				},
				{
					category: 'Total',
					value:
						(this.totalActualStart.get(currUpdate._id)?.length || 0) +
						(this.totalActual.get(currUpdate._id)?.length || 0),
				},
			];
			const percentageCompletedData: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.$criticalPercentComplete.value || 0,
				},
				{
					category: 'Near Critical',
					value: this.$nearCriticalPercentComplete.value || 0,
				},
				{
					category: 'Non Critical',
					value: this.$nonCriticalPercentComplete.value || 0,
				},
				{
					category: 'Total',
					value: this.$totalPercentComplete.value || 0,
				},
			];
			const percentageCompletedDataStart: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: this.$criticalPercentCompleteStart.value || 0,
				},
				{
					category: 'Near Critical',
					value: this.$nearCriticalPercentCompleteStart.value || 0,
				},
				{
					category: 'Non Critical',
					value: this.$nonCriticalPercentCompleteStart.value || 0,
				},
				{
					category: 'Total',
					value: this.$totalPercentCompleteStart.value || 0,
				},
			];
			const percentageCompletedDataAll: SeriesData[] = [
				{
					category: 'Critical\n(Longest Path)',
					value: (this.$criticalPercentCompleteStart.value || 0) + (this.$criticalPercentComplete.value || 0),
				},
				{
					category: 'Near Critical',
					value: (this.$nearCriticalPercentCompleteStart.value || 0) + (this.$nearCriticalPercentComplete.value || 0),
				},
				{
					category: 'Non Critical',
					value: (this.$nonCriticalPercentCompleteStart.value || 0) + (this.$nonCriticalPercentComplete.value || 0),
				},
				{
					category: 'Total',
					value: (this.$totalPercentCompleteStart.value || 0) + (this.$totalPercentComplete.value || 0),
				},
			];
			if (!simming) {
				this.getScheduleTrendData(
					plannedData,
					completedData,
					percentageCompletedData,
					criticalData,
					nearCriticalData,
					nonCriticalData,
					totalData,
					plannedDataStart,
					startedData,
					percentageCompletedDataStart,
					criticalDataStart,
					nearCriticalDataStart,
					nonCriticalDataStart,
					totalDataStart,
					plannedDataAll,
					completedDataAll,
					percentageCompletedDataAll,
					categories
				);
			}
		};

		const sim = (code: CodeFilterItem & { subCodes?: CodeFilterItem[] }, isLookahead, selectedTimespan) => {
			// Check if `codes` is an array; if so, process each item in it
			if (code.subCodes?.length) {
				for (const subCode of code.subCodes) {
					sim(subCode, isLookahead, selectedTimespan); // Call `sim` on each item in the array
				}
			} else {
				calculations(new Set([code]));
			}
			this.startTotalPerCode.set(code.name, this.$totalPercentCompleteStart.value);
			this.finishTotalPerCode.set(code.name, this.$totalPercentComplete.value);
			this.start30TotalPerCode.set(code.name, this.unfilteredLookahead30DisplaySetStart?.Total?.length);
			this.finish30TotalPerCode.set(code.name, this.unfilteredLookahead30DisplaySet?.Total?.length);
			this.start60TotalPerCode.set(code.name, this.unfilteredLookahead60DisplaySetStart?.Total?.length);
			this.finish60TotalPerCode.set(code.name, this.unfilteredLookahead60DisplaySet?.Total?.length);
			this.start90TotalPerCode.set(code.name, this.unfilteredLookahead90DisplaySetStart?.Total?.length);
			this.finish90TotalPerCode.set(code.name, this.unfilteredLookahead90DisplaySet?.Total?.length);
		};

		if (this.selectedBasedOn === 'All') {
			this.startTotalPerCode.clear();
			this.finishTotalPerCode.clear();
			this.start30TotalPerCode.clear();
			this.finish30TotalPerCode.clear();
			this.start60TotalPerCode.clear();
			this.finish60TotalPerCode.clear();
			this.start90TotalPerCode.clear();
			this.finish90TotalPerCode.clear();
			for (const code of this.nonDisabledActivityCodes) {
				sim(code, this.isLookahead, this.selectedTimespan);
			}
		}
		calculations(new Set(this.selectedActivityCodes), false);

		if (!fromActivityCodeFiltering) {
			const allSubCodes = [];
			this.allActivityCodes.forEach((code) => {
				code.subCodes.forEach((subCode) => {
					const subcodeColumn = {
						title: subCode.name,
						shortName: subCode.shortName,
					};

					allSubCodes.push(subcodeColumn);
				});
			});
			this.nonDisabledActivityCodes = this.getNonDisabledItems(this.allActivityCodes);
			this.allSubCodes = allSubCodes;
		}
		this.forwardOrBackwardButtons[0].disabled =
			this.totalPlannedStart.get(this.lastUpdateId)?.length + this.totalPlanned.get(this.lastUpdateId)?.length === 0;
		this.forwardOrBackwardButtons[1].disabled =
			this.lookahead30SeriesDataAll[0].data[3].value +
				this.lookahead60SeriesDataAll[0].data[3].value +
				this.lookahead90SeriesDataAll[0].data[3].value ===
			0;
		this.timespanButtons[0].disabled = this.lookahead30SeriesDataAll[0].data[3].value === 0;
		this.timespanButtons[1].disabled = this.lookahead60SeriesDataAll[0].data[3].value === 0;
		this.timespanButtons[2].disabled = this.lookahead90SeriesDataAll[0].data[3].value === 0;

		if (!fromActivityCodeFiltering) {
			let btnSelected: boolean = false;
			this.forwardOrBackwardButtons.forEach((btn: IButton) => {
				if (!btnSelected && !btn.disabled) {
					btn.selected = true;
					btnSelected = true;
				} else {
					btn.selected = false;
				}
			});
			let timespanBtnSelected: boolean = false;
			this.timespanButtons.forEach((btn: IButton) => {
				if (!timespanBtnSelected && !btn.disabled) {
					btn.selected = true;
					timespanBtnSelected = true;
					this.selectedTimespan = btn.value;
				} else {
					btn.selected = false;
				}
			});
		}

		this.isLookahead = this.forwardOrBackwardButtons[1].selected;
		this.loadActivities();
	}

	/**
	 * takes task data and fits it to type being used by the grids. also updates some values in the task to be from the
	 * correct update and be of the correct type
	 * @param taskArray
	 * @param type
	 * @param lookaheadField
	 */
	fitDataToType(
		taskArray: Activity[],
		type: ActvCompletionView,
		lookaheadField = '',
		useStarts: boolean = false
	): void {
		if (taskArray !== undefined) {
			taskArray.forEach((actv) => {
				const displayActv: ActvCompletionActv = { ...actv.raw_entry };
				if (displayActv.total_float_hr_cnt) {
					displayActv.tfHrs = Math.round(
						displayActv.total_float_hr_cnt / (this.calendars.get(+displayActv.clndr_id)?.day_hr_cnt || 8)
					);
				} else {
					displayActv.tfHrs = 0;
				}
				const matchingActvInCurrentUpdate =
					lookaheadField !== ''
						? this.lastXer.activitiesByCode.get(actv.code)
						: this.currentXer.activitiesByCode.get(actv.code);
				displayActv.activityCodes = (Array.from(actv.activityCodes) || [])
					.map((ac) => ac.parentActivityCodeId + '%//%' + ac.shortName)
					.join(', ');
				displayActv.category = type;
				displayActv.target_start_date = actv._activity.earlyStart;
				displayActv.target_end_date = actv._activity.earlyFinish;
				if (matchingActvInCurrentUpdate) {
					displayActv.latest_start_date = matchingActvInCurrentUpdate.start;
					displayActv.latest_end_date = matchingActvInCurrentUpdate.finish;
					displayActv.latest_status = statusDict[matchingActvInCurrentUpdate._activity.statusCode];
				} else {
					displayActv.latest_status = 'Deleted';
				}

				// Added this section because the field and otherField implementation wouldn't allow for date sorting in the kendo grid. I only use the one field, so it's bounded for kendo sorting.
				// If the actual date does not exist, assign the otherField value to the main field. If the actual date does exist, assign null to the otherField. In the HTML it will check if the otherField is null or not,
				// and assign the ' A' accordingly. - KF
				if (!displayActv.latest_start_date) {
					displayActv.latest_start_date = actv._activity.earlyStart;
					displayActv.is_actual_start = false; //used the flag of is_actual instead of making target null since target dates are used in excel exporter
				} else {
					displayActv.act_start_date = matchingActvInCurrentUpdate.start;
					displayActv.is_actual_start = true;
				}
				if (!displayActv.latest_end_date) {
					displayActv.latest_end_date = actv._activity.earlyFinish;
					displayActv.is_actual_end = false;
				} else {
					displayActv.act_end_date = matchingActvInCurrentUpdate.finish;
					displayActv.is_actual_end = true;
				}

				if (useStarts) {
					const latestUpdateActv: Activity = this.latestUpdateActivityMap.get(actv.code);
					if (latestUpdateActv?._activity.statusCode === 'TK_Active' && latestUpdateActv?.start) {
						displayActv.latest_status = 'Started';
					}
					if (lookaheadField === '') {
						this.unfilteredDisplaySetStart[type].push(displayActv);
						this.unfilteredDisplaySetStart.Total.push(displayActv);
					} else {
						this[lookaheadField][type].push(displayActv);
						this[lookaheadField].Total.push(displayActv);
					}
				} else {
					if (lookaheadField === '') {
						this.unfilteredDisplaySet[type].push(displayActv);
						this.unfilteredDisplaySet.Total.push(displayActv);
					} else {
						this[lookaheadField][type].push(displayActv);
						this[lookaheadField].Total.push(displayActv);
					}
				}
			});
		}
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isTimespan
	 */
	public selectionChange(
		selected: boolean,
		btn: IButton,
		isTimespan: boolean = false,
		isBasedOn: boolean = false
	): void {
		if (btn.disabled) {
			return;
		}
		btn.selected = selected;
		if (btn.selected) {
			if (isTimespan) {
				this.selectedTimespan = btn.value;
			} else if (isBasedOn) {
				/*if (btn.text === 'All') {
					this.tableView = ActvCompletionView.overview;
				}*/
				this.selectedBasedOn = btn.text;
				this.selectedColumns =
					this.selectedBasedOn !== 'All'
						? [...allColumns.columns.slice(0, -2), ...allColumns.columns.slice(-1)]
						: allColumns.columns;
				this.valueAxisItemSettings[0].title.text =
					this.selectedBasedOn === 'All' ? 'Activity Starts & Finishes' : 'Activity Count';
			} else {
				this.isLookahead = btn.text === 'Planned';
			}
			this.setTableView(this.tableView);
		}
	}

	/**
	 * determines if there is an intersection between a task's activity codes and the multiselect's selection
	 * @param task
	 */
	activityCodeIntersection(task: Activity): boolean {
		let returnVal = false;
		for (const actvCode of task.activityCodes) {
			if (this.selectedActivityCodes.some((selectedCode) => selectedCode.shortName === actvCode.shortName)) {
				returnVal = true;
				break;
			}
		}
		return returnVal;
	}

	/**
	 * sets table view to a filtered scope
	 * @param view
	 */
	setTableView(view: ActvCompletionView) {
		this.tableView = view;
		// if (view === ActvCompletionView.overview) {
		// 	view = ActvCompletionView.total; //makes sure activity codes can be disabled/enabled based on total activities for current display set
		// }
		this.currentDisplaySet = this.getDisplaySet(view);
		setTimeout(() => {
			this.loadActivities();
			this.searchTerm = '';
			this.reset();
			this.updateTrendLineVisibility(view);
		});
	}

	getDisplaySet(view: ActvCompletionView): ActvCompletionActv[] {
		const realView = view === ActvCompletionView.overview ? ActvCompletionView.total : view;
		// if (this.selectedBasedOn === 'All' && view === ActvCompletionView.overview) {
		// 	view = ActvCompletionView.total;
		// }
		const finishDisplaySet: ActvCompletionActv[] = this.isLookahead
			? this.selectedTimespan === 30
				? this.unfilteredLookahead30DisplaySet[realView]
				: this.selectedTimespan === 60
					? this.unfilteredLookahead60DisplaySet[realView]
					: this.unfilteredLookahead90DisplaySet[realView]
			: this.unfilteredDisplaySet[realView];
		const startDisplaySet: ActvCompletionActv[] = this.isLookahead
			? this.selectedTimespan === 30
				? this.unfilteredLookahead30DisplaySetStart[realView]
				: this.selectedTimespan === 60
					? this.unfilteredLookahead60DisplaySetStart[realView]
					: this.unfilteredLookahead90DisplaySetStart[realView]
			: this.unfilteredDisplaySetStart[realView];
		const displaySet: ActvCompletionActv[] =
			this.selectedBasedOn === 'Start'
				? startDisplaySet
				: this.selectedBasedOn === 'Finish'
					? finishDisplaySet
					: this.combine(startDisplaySet, finishDisplaySet);
		return displaySet;
	}

	combine(a: ActvCompletionActv[], b: ActvCompletionActv[]): ActvCompletionActv[] {
		a.forEach((actv: ActvCompletionActv) => {
			actv.target = 'Start';
		});
		b.forEach((actv: ActvCompletionActv) => {
			actv.target = 'Finish';
		});
		return [...a, ...b];
		/*const newArr: ActvCompletionActv[] = [...a];
		b.forEach((item: ActvCompletionActv) => {
			if (newArr.findIndex((i) => i.task_id === item.task_id) === -1) {
				newArr.push(item);
			}
		});
		return newArr;*/
	}

	/**
	 * updates trend chart and bar chart with latest data
	 * @param plannedData
	 * @param completedData
	 * @param percentageCompletedData
	 * @param criticalData
	 * @param nearCriticalData
	 * @param nonCriticalData
	 * @param totalData
	 * @param plannedDataStart
	 * @param completedDataStart
	 * @param percentageCompletedDataStart
	 * @param criticalDataStart
	 * @param nearCriticalDataStart
	 * @param nonCriticalDataStart
	 * @param totalDataStart
	 * @param plannedDataAll
	 * @param completedDataAll
	 * @param percentageCompletedDataAll
	 * @param categories
	 */
	getScheduleTrendData(
		plannedData: SeriesData[],
		completedData: SeriesData[],
		percentageCompletedData: SeriesData[],
		criticalData: SeriesData[],
		nearCriticalData: SeriesData[],
		nonCriticalData: SeriesData[],
		totalData: SeriesData[],
		plannedDataStart: SeriesData[],
		completedDataStart: SeriesData[],
		percentageCompletedDataStart: SeriesData[],
		criticalDataStart: SeriesData[],
		nearCriticalDataStart: SeriesData[],
		nonCriticalDataStart: SeriesData[],
		totalDataStart: SeriesData[],
		plannedDataAll: SeriesData[],
		completedDataAll: SeriesData[],
		percentageCompletedDataAll: SeriesData[],
		categories: string[]
	) {
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'column',
				data: plannedData,
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
			},
			{
				type: 'column',
				data: completedData,
				name: 'Completed',
				visible: true,
				color: '#DF5353',
			},
			{
				type: 'column',
				data: percentageCompletedData,
				name: 'Percentage Completed',
				visible: false,
				color: 'black',
			},
		];

		const seriesDataStart: SeriesDataSettings[] = [
			{
				type: 'column',
				data: plannedDataStart,
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
			},
			{
				type: 'column',
				data: completedDataStart,
				name: 'Started',
				visible: true,
				color: '#DF5353',
			},
			{
				type: 'column',
				data: percentageCompletedDataStart,
				name: 'Percentage Completed',
				visible: false,
				color: 'black',
			},
		];

		const seriesDataAll: SeriesDataSettings[] = [
			{
				type: 'column',
				data: plannedDataAll,
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
			},
			{
				type: 'column',
				data: completedDataAll,
				name: 'Started',
				visible: true,
				color: '#DF5353',
			},
			{
				type: 'column',
				data: percentageCompletedDataAll,
				name: 'Percentage Completed',
				visible: false,
				color: 'black',
			},
		];

		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}

		if (hasObjChanged(this.seriesDataStart, seriesDataStart)) {
			this.seriesDataStart = seriesDataStart;
		}

		if (hasObjChanged(this.seriesDataAll, seriesDataAll)) {
			this.seriesDataAll = seriesDataAll;
		}

		if (hasObjChanged(this.trendCategories, categories)) {
			this.trendCategories = categories;
		}
		const trendSeriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: criticalData,
				name: 'Critical',
				visible: true,
				color: '#DF5353',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nearCriticalData,
				name: 'Near Critical',
				visible: true,
				color: '#4fc931',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nonCriticalData,
				name: 'Non Critical',
				visible: true,
				color: '#0059FF',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: totalData,
				name: 'Total',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];

		const trendSeriesDataStart: SeriesDataSettings[] = [
			{
				type: 'line',
				data: criticalDataStart,
				name: 'Critical',
				visible: true,
				color: '#DF5353',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nearCriticalDataStart,
				name: 'Near Critical',
				visible: true,
				color: '#4fc931',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nonCriticalDataStart,
				name: 'Non Critical',
				visible: true,
				color: '#0059FF',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: totalDataStart,
				name: 'Total',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];

		const allTotalSeriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: totalDataStart,
				name: 'Early Start Total',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: totalData,
				name: 'Early Finish Total',
				visible: true,
				color: 'gray',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.trendSeriesData, trendSeriesData)) {
			this.trendSeriesData = trendSeriesData;
		}
		if (hasObjChanged(this.trendSeriesDataStart, trendSeriesDataStart)) {
			this.trendSeriesDataStart = trendSeriesDataStart;
		}
		if (hasObjChanged(this.allTotalSeriesData, allTotalSeriesData)) {
			this.allTotalSeriesData = allTotalSeriesData;
		}
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm?.toLowerCase();
		this.tableSearch = searchTerm;
		let filteredDisplaySet: ActvCompletionActv[] = [];
		const unfilteredDisplaySet: ActvCompletionActv[] = this.getDisplaySet(this.tableView);
		if (!searchTerm) {
			filteredDisplaySet = unfilteredDisplaySet;
		} else {
			for (const task of unfilteredDisplaySet) {
				if (
					task.task_code?.toLowerCase()?.includes(searchTerm) ||
					task.task_name?.toLowerCase()?.includes(searchTerm)
				) {
					filteredDisplaySet.push(task);
				}
			}
		}

		// update the rows
		this.currentDisplaySet = filteredDisplaySet || [];
		this.loadActivities();
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		this.updateTagText(ev);

		const allUpdates = this.storage.$allUpdates.value;
		const prevUpdate = allUpdates[allUpdates.length - 2];
		const currUpdate = allUpdates[allUpdates.length - 1];
		const enabledCodeIds = new Set<number>([]);

		for (const actv of this.currentDisplaySet) {
			const task = this.currentXer.activitiesByCode.get(actv.task_code);
			for (const actvCode of task.activityCodes) {
				enabledCodeIds.add(actvCode.id);
			}
		}
		this.updateData(prevUpdate, currUpdate, this.project.$currentProjectReport.value, true).then(() => {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (ev?.length && !enabledCodeIds.has(subCode.id)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});
			this.nonDisabledActivityCodes = this.getNonDisabledItems(this.allActivityCodes);
			/*this.allActivityCodes.forEach((code) => {
				code.disabled =
					this.selectedActivityCodes.length > 0
						? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
						: false;
			});*/
		});
	}

	getNonDisabledItems(items: ActvCodeFilterItem[]) {
		return items
			.filter((item) => !item.disabled && !item.alwaysDisabled) // Filter out disabled or alwaysDisabled items
			.map((item) => {
				// Clone the item, but filter its subCodes as well
				const nonDisabledSubCodes = item.subCodes.filter((subCode) => !subCode.disabled && !subCode.alwaysDisabled);
				return {
					...item,
					subCodes: nonDisabledSubCodes, // Include only non-disabled subCodes
				};
			});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	public itemDisabled(dataItem: any) {
		const isDisabledFocusTab: boolean =
			dataItem.shortName === this.defaultSelectedActivityCodes?.shortName ||
			this.eligibleFocusTabCodesAndTypes.findIndex((c: number) => c === dataItem.id) === -1;
		return this.defaultSelectedActivityCodes ? isDisabledFocusTab : dataItem.alwaysDisabled || dataItem.disabled;
	}

	doExport() {
		this.exportProcessing = true;
		this.restService
			.postToExporter('activity-completion/', {
				name: this.project.$currentProjectData.value.name + '\n' + cleanDateUTC(new Date(), 'MMMM d, yyyy'),
				sub_codes: this.allSubCodes,
				data: this.getDisplaySet(ActvCompletionView.total),
				basedOn: this.selectedBasedOn,
			})
			.subscribe(
				(res: any) => {
					saveAs(
						res,
						this.selectedBasedOn === 'Start'
							? 'AegisAnalytics-ExportActivityStartData.xlsx'
							: 'AegisAnalytics-ExportActivityCompletionData.xlsx'
					);
					this.exportProcessing = false;
				},
				(err) => {
					console.log(err);
					this.exportProcessing = false;
				}
			);
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	/**
	 * sets activities grid data to be the latest data at the current scroll/page spot
	 */
	public loadActivities(): void {
		//making sure on page load before setTableView is called, if on Overview get the activity Code Filters of total for current display set
		if (this.tableView === ActvCompletionView.overview) {
			const view = ActvCompletionView.total;
			this.currentDisplaySet = this.getDisplaySet(view);
		}

		const enabledCodeIds = new Set<number>([]);

		for (const actv of this.currentDisplaySet) {
			const task = this.currentXer.activitiesByCode.get(actv.task_code);
			for (const actvCode of task.activityCodes) {
				enabledCodeIds.add(actvCode.id);
			}
		}
		if (this.selectedActivityCodes?.length <= 0) {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!enabledCodeIds.has(subCode.id)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});
			this.nonDisabledActivityCodes = this.getNonDisabledItems(this.allActivityCodes);
			const exportData = this.getDisplaySet(ActvCompletionView.total);

			exportData?.forEach((row) => {
				this.allSubCodes?.forEach((code) => {
					row[`activityCode_${code.shortName}`] = row.activityCodes.includes(code.shortName) ? 'x' : '';
				});
			});
		}

		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	reset() {
		this.skip = 0;
		this?.grid?.scrollTo({ row: 0, column: 0 });
	}

	/**
	 * makes only selected view line(s) visible in trend chart
	 * @param view
	 */
	updateTrendLineVisibility(view: ActvCompletionView): void {
		if (this.selectedBasedOn === 'Start') {
			this.trendSeriesDataStart.forEach((line) => {
				line.visible = view === 'overview' ? true : line.name === view;
			});
		} else {
			this.trendSeriesData.forEach((line) => {
				line.visible = view === 'overview' ? true : line.name === view;
			});
		}
	}
}
