@if (progressMessageShowing) {
	<div class='warning-div'>
		Progress Trending requires at least 1 update to enable
	</div>
} @else {
	<kendo-chart [seriesColors]="seriesColors">
		<kendo-chart-panes>
			<kendo-chart-pane name="pane" clip="false">
			</kendo-chart-pane>
		</kendo-chart-panes>
		<kendo-chart-title [text]="chartTitle" font="bold 0.75vw Muli, Helvetica Neue, Arial, sans-serif" [padding]="0" [margin]="isFromPortfolio ? {top: -5, bottom: 13} : 6"></kendo-chart-title>
		<!--  <kendo-chart-tooltip [shared]="true">
				<ng-template
					kendoChartSharedTooltipTemplate
					let-category="category"
					let-points="points"
				>
					<div>{{ category }}</div>
					<div *ngFor="let point of points">
						{{ point.series.name }} : {{ point.value | number: '1.0-0' }}%
					</div>
				</ng-template>
			</kendo-chart-tooltip>-->
		<kendo-chart-value-axis>
			<kendo-chart-value-axis-item
				[labels]="{format: '{0}%', font: '12px Muli, Helvetica Neue, Arial, sans-serif', step: 1}"
				[line]='{visible: false}'
				[visible]="!isFromPortfolio"
				[majorUnit]="majorUnit"
			>
			</kendo-chart-value-axis-item>
		</kendo-chart-value-axis>
		<kendo-chart-category-axis>
			<kendo-chart-category-axis-item
				[categories]="categories"
				[crosshair]="{ color: '#bbbbbb', visible: !isFromPortfolio, width: 1 }"
				[labels]="{rotation: 'auto', step: categoryStep | async, font: isFromPortfolio ? '10px Muli, Helvetica Neue, Arial, sans-serif' : '12px Muli, Helvetica Neue, Arial, sans-serif'}"
				[majorGridLines]='{visible: false}'
			>
			</kendo-chart-category-axis-item>
		</kendo-chart-category-axis>
		<kendo-chart-tooltip [visible]='!isFromPortfolio'>
			<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" let-category="category">
				{{category}} - {{value | number : '1.0-0'}}%
			</ng-template>
		</kendo-chart-tooltip>
		<kendo-chart-series>
			<kendo-chart-series-item
				[type]="chartType"
				[style]="'smooth'"
				[data]="data"
				[line]="{color: '#df5353'}"
				[labels]="{ visible: this.isFromPortfolio, padding: 3, font: '8px Muli, Helvetica Neue, Arial, sans-serif', format: '{0}%', background: 'none' }"
				[markers]="{background: '#df5353'}"
			>
			</kendo-chart-series-item>
		</kendo-chart-series>
	</kendo-chart>
}
