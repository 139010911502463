import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectInterface } from '../../../../../models/Project';
import { Hits } from '../../../../../models/ProjectReport/ProjectReport';
import { canRunRisk, createRegisterClass, hasObjChanged, numExpectedMCJobs } from '../../../../../util/projects';
import { RiskRegister } from '../../../../../models/risk';
import { addHours, differenceInCalendarDays, format, fromUnixTime, setHours, startOfDay } from 'date-fns';
import { groupBy, GroupResult, SortDescriptor } from '@progress/kendo-data-query';
import { AxisLabelContentArgs, PlotBand, Series } from '@progress/kendo-angular-charts';
import { performanceFactorScoreOptions } from '../risk-performance-factor/pf-chart-options';
import * as Highcharts from 'highcharts';
import { BehaviorSubject } from 'rxjs';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { RestService } from '../../../../../services/common/rest.service';
import { GaugeColorSettings } from '../../../../../models/ChartSettings';
import { UserService } from '../../../../../services/common/user.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';

interface RegisterInfo extends RiskRegister {
	preMitigationActivityImpact?: number;
	postMitigationActivityImpact?: number;
	preMitigationScheduleImpact?: number;
	postMitigationScheduleImpact?: number;
	impactDelta?: number;
	costDelta?: number;
}

@Component({
	selector: 'app-monte-carlo',
	templateUrl: './monte-carlo.component.html',
	styleUrls: ['./monte-carlo.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MonteCarloComponent implements OnInit {
	@Input() isOverview: boolean = false;
	riskAnalysisData: RegisterInfo[] = [];
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	totalPreSchedImpact = 0;
	totalPostSchedImpact = 0;
	totalCostDelta = 0;
	mitigationHits: Array<
		Hits & {
			title: string;
			date: Date;
		}
	> = [];
	mitigationProbabilities: Array<{
		title: string;
		value: number;
	}> = [];
	user: any;

	public likelihoodSeries: GroupResult[];
	public probabilitySeries: GroupResult[];
	public series: Series[] = [];
	public dataDates: Date[] = [];

	Highcharts = Highcharts;
	performanceFactorScoreOptions = performanceFactorScoreOptions;
	updatedCharts = false;
	pfRiskChart: Highcharts.Chart;

	$currentCompletion = new BehaviorSubject<Date>(undefined);
	$p85 = new BehaviorSubject<Date>(undefined);
	$earliest = new BehaviorSubject<Date>(undefined);
	$latest = new BehaviorSubject<Date>(undefined);
	$avg = new BehaviorSubject<Date>(undefined);
	$delta = new BehaviorSubject<number>(0);
	$isRunningMC = new BehaviorSubject<boolean>(false);
	$mcJobProgressPct = new BehaviorSubject<number>(0);
	initializingMCJob = false;
	showMonteCarlo = false;

	selectedRegisters: number[] = [];
	recalcing = false;

	rhinoFacts: string[] = [
		'One of the most unique facts about rhinos is that their horns are made of keratin, which is also the same key protein that makes up human hair and fingernails. Their horns tend to grow and curve towards the head due to keratin at the front growing at a faster rate than it does in the back. Interestingly, if a rhino loses its horn – due to bulls fighting each other over dominance or territory – it will eventually grow back.',
		'Rhinos Have Special Relationships with Birds. Another one of the many interesting facts about rhinos is that while they tend to be solitary animals, they have a symbiotic relationship with birds. This is most notable with the African rhinos and the African Oxpeckers. The bird would often sit on a rhino’s back and feed off the insects on it. Birds would also provide warning calls should any potential danger or enemies that approaches – as rhinos are quite short-sighted.',
		'There Are Five Different Rhino Species. White rhinos, Black rhinos, Greater one-horned, Sumatran and Javan rhinos. Black and white rhinos are also known together as the African rhinos, as they are found only on the African continent.',
		'Rhinos can weigh over 3 tonnes. Sumatran rhinos are the smallest of all rhinos, but they can still weigh 600kg (that’s almost 95 stone). On the other hand, white rhinos are the largest of the rhino species, weighing up to 3,500 kg. That is more than 550 stone, or well over 3 tonnes, which is mighty impressive considering they mainly eat grass and leaves.',
	];
	selectedRhinoFact: string = '';
	public groups = [{ field: 'ProductName', aggregates: [{ field: 'ProductName', aggregate: 'count' }] }];
	$performanceFactorScore = new BehaviorSubject<number>(0);
	pfColors: GaugeColorSettings[] = [
		{
			to: 50,
			color: '#DF5353',
		},
		{
			from: 50,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	label = 'Likelihood of On-Time Completion';
	unableToCalcReason: string | undefined = undefined;
	public sort: SortDescriptor[] = [
		{
			field: 'riskId',
			dir: 'asc',
		},
	];
	visibleRiskRegisters: RegisterInfo[] = [];
	hasNotes: boolean = false;

	constructor(
		public _projectDashboardService: ProjectDashboardService,
		private restService: RestService,
		public userService: UserService,
		private navBarStorage: NavigationBarStorageService,
		public dialog: MatDialog,
		public scheduleService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		this.selectedRhinoFact = this.rhinoFacts[Math.floor(Math.random() * this.rhinoFacts.length)];
		this._projectDashboardService.$currentProjectData.subscribe((projectData) => {
			console.log('monte carlo updateddata', projectData);
			const wasRunningMC = this.$isRunningMC.getValue();
			const nowRunningMC = Object.keys(projectData.mcJobs || {})?.length > 0 || !projectData.isMonteCarloDone;
			if (nowRunningMC) {
				this.$isRunningMC.next(true);
			}
			console.log({ wasRunningMC, nowRunningMC });
			if (wasRunningMC && wasRunningMC !== nowRunningMC) {
				this.refreshWindow();
				return;
			}
			const numExpected = numExpectedMCJobs(projectData);
			const numActual =
				numExpected -
					Object.values(projectData?.mcJobs || {}).reduce((sum, updateJobs) => sum + updateJobs.length, 0) || 0;
			this.$mcJobProgressPct.next(numActual / numExpected);
			if (projectData) {
				const savedNotes = projectData.componentNotes?.find((n) => n.id === 18)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
		this._projectDashboardService.$currentProjectReport.subscribe((projectData) => {
			console.log('monte carlo', projectData);
			this.showMonteCarlo =
				!!projectData.project.preferences.monteCarlo.pfVariance[0] ||
				!!projectData.project.preferences.monteCarlo.pfVariance[1] ||
				!!projectData.project.riskMitigation?.filter(
					(register) => register.preMitigation.activityImpact && register.impactedTaskCodes.length
				)?.length;

			const numExpected = numExpectedMCJobs(projectData.project);
			const numActual =
				numExpected -
					Object.values(projectData.project?.mcJobs || {}).reduce((sum, updateJobs) => sum + updateJobs.length, 0) || 0;
			this.initializingMCJob =
				(!projectData.project.isMonteCarloDone && !Object.keys(projectData.project.mcJobs || {})?.length) ||
				(!!numExpected && !numActual);
			if (!projectData.project.updateIds.length && this.userService.user.value.userType === 'saasRisk') {
				this.initializingMCJob = false;
			}
			if (!this.initializingMCJob) {
				this.navBarStorage.monteCarloCalculating = false;
			}
			if (
				!projectData.project.isMonteCarloDone &&
				canRunRisk(projectData.project) &&
				numExpected &&
				numActual < numExpected
			) {
				this.$mcJobProgressPct.next(numActual / numExpected);
			}

			this.getPerformanceFactorScore(projectData);
			this.$currentCompletion.next(new Date(projectData?.projectTable?.currentCompletion));

			const registers = projectData.project?.riskMitigation || [];
			const riskAnalysisData: RegisterInfo[] = [];

			const selectedRegisters: number[] = [];
			for (let i = 0; i < registers.length; i++) {
				const registerChange = registers[i];

				const register: RegisterInfo = createRegisterClass(Object.assign({}, registerChange));
				register.preMitigationActivityImpact = register.preMitigation.activityImpact;
				register.postMitigationActivityImpact = register.postMitigation.activityImpact;
				riskAnalysisData.push(register);
				if (registerChange.isDraft) {
					continue;
				}

				if (!register.disabled) {
					selectedRegisters.push(register.riskId);
				}
			}

			const reportRegisterHistory = projectData.registerHistorical || [];
			const mitigationHits: Array<
				Hits & {
					title: string;
					date: Date;
					fixedDate: Date;
				}
			> = [];
			const mitigationProbabilities: Array<{
				title: string;
				value: number;
				date: Date;
				fixedDate: Date;
			}> = [];
			let preMitigationHitTallyTotal = 0;
			let postMitigationHitTallyTotal = 0;
			let p85Date: Date;

			let earliest: Date;
			let latest: Date;
			let avg: Date;

			const dataDates = projectData.completionVarianceGraph.dataDateArray || [];
			const updateIds = projectData.project.updateIds || [];
			const preMitigationHistory: number[] = [];
			const postMitigationHistory: number[] = [];
			const deltaHistory: number[] = [];
			const dataDatesHistory: Date[] = [];
			const dateLabelsSet = new Set<string>([]);
			const updateRiskIds = new Map<string, Set<number>>([]);
			for (const register of reportRegisterHistory) {
				const riskId = +register.riskId;
				const gridRiskRegisterIndex = riskAnalysisData.findIndex((risk) => +risk.riskId === +riskId);
				const gridRiskRegister = riskAnalysisData[gridRiskRegisterIndex];
				const updateNumber = updateIds.indexOf(register.updateId);
				const currentUpdateRiskIds = updateRiskIds.get(register.updateId) || new Set<number>([]);
				currentUpdateRiskIds.add(register.riskId);
				updateRiskIds.set(register.updateId, currentUpdateRiskIds);
			}
			let totalCostDelta = 0;
			const idsCountedTowardsTotal = new Set<number>([]);
			for (const register of reportRegisterHistory) {
				const riskId = +register.riskId;
				const gridRiskRegisterIndex = riskAnalysisData.findIndex(
					(risk) => +risk.riskId === +riskId || riskAnalysisData.length === 1
				);
				const gridRiskRegister = riskAnalysisData[gridRiskRegisterIndex];
				const updateNumber = updateIds.indexOf(register.updateId);
				const dataDate = dataDates[updateNumber]
					? setHours(addHours(new Date(dataDates[updateNumber]), 12), 12)
					: undefined;

				if (updateNumber === updateIds.length - 1) {
					if (gridRiskRegister) {
						gridRiskRegister.preMitigationScheduleImpact = register.preMitigationDateImpact || 0;
						gridRiskRegister.postMitigationScheduleImpact = register.postMitigationDateImpact || 0;

						gridRiskRegister.impactDelta =
							gridRiskRegister.postMitigationScheduleImpact - gridRiskRegister.preMitigationScheduleImpact;
						gridRiskRegister.costDelta =
							gridRiskRegister.postMitigation?.estCost && gridRiskRegister?.impactDelta
								? gridRiskRegister.postMitigation?.estCost / (gridRiskRegister.impactDelta * -1 || 1)
								: null;
						if (
							gridRiskRegister.costDelta !== null &&
							gridRiskRegister.costDelta !== undefined &&
							!idsCountedTowardsTotal.has(gridRiskRegister.riskId)
						) {
							totalCostDelta += gridRiskRegister.costDelta;
							idsCountedTowardsTotal.add(gridRiskRegister.riskId);
						}

						riskAnalysisData[gridRiskRegisterIndex] = gridRiskRegister;
					}
				}
				if (
					updateRiskIds.get(register.updateId)?.size === 1 ||
					(updateRiskIds.get(register.updateId)?.size > 1 && riskId === -1)
				) {
					if (dataDate) {
						dataDatesHistory[updateNumber] = dataDate;
						preMitigationHistory[updateNumber] = -register.preMitigationDateImpact || 0;
						postMitigationHistory[updateNumber] = -register.postMitigationDateImpact || 0;

						deltaHistory[updateNumber] =
							(register.preMitigationDateImpact || 0) - (register.postMitigationDateImpact || 0);
					}

					if (updateNumber === updateIds.length - 1) {
						const registerPreHits = [...register.preMitigationHits].sort((a, b) => a.unixDate - b.unixDate);
						const registerPostHits = [...register.postMitigationHits].sort((a, b) => a.unixDate - b.unixDate);
						this.totalPreSchedImpact = -register.preMitigationDateImpact;
						this.totalPostSchedImpact = -register.postMitigationDateImpact;
						for (const hit of registerPreHits) {
							const date = startOfDay(fromUnixTime(hit.unixDate));
							const dateLabel = format(date, 'MM/dd/yy');
							if (hit.hits > 0) {
								dateLabelsSet.add(dateLabel);
								preMitigationHitTallyTotal += hit.hits;
							}
							mitigationHits.push({
								...hit,
								date,
								fixedDate: new Date(startOfDay(hit.unixDate * 1000)),
								title: 'Pre-Mitigation',
							});
						}
						for (const hit of registerPostHits) {
							const date = startOfDay(fromUnixTime(hit.unixDate));
							const dateLabel = format(date, 'MM/dd/yy');
							if (hit.hits > 0) {
								dateLabelsSet.add(dateLabel);
								postMitigationHitTallyTotal += hit.hits;
							}
							mitigationHits.push({
								...hit,
								date,
								fixedDate: new Date(startOfDay(hit.unixDate * 1000)),
								title: 'Post-Mitigation',
							});
						}
						let preMitigationHitTally = 0;
						for (const hit of registerPreHits) {
							const date = startOfDay(fromUnixTime(hit.unixDate));
							preMitigationHitTally += hit.hits;
							mitigationProbabilities.push({
								title: 'Pre-Mitigation Probability',
								value: (100 * preMitigationHitTally) / preMitigationHitTallyTotal,
								fixedDate: new Date(startOfDay(hit.unixDate * 1000)),
								date,
							});
							if (!p85Date && preMitigationHitTally / preMitigationHitTallyTotal >= 0.85) {
								p85Date = date;
							}
							if (!avg && preMitigationHitTally / preMitigationHitTallyTotal >= 0.5) {
								avg = date;
							}
							if (!earliest || date < earliest) {
								earliest = date;
							}
							if (!latest || date > latest) {
								latest = date;
							}
						}

						let postMitigationHitTally = 0;
						for (const hit of registerPostHits) {
							const date = startOfDay(fromUnixTime(hit.unixDate));
							postMitigationHitTally += hit.hits;
							mitigationProbabilities.push({
								title: 'Post-Mitigation Probability',
								value: (100 * postMitigationHitTally) / postMitigationHitTallyTotal,
								fixedDate: new Date(startOfDay(hit.unixDate * 1000)),
								date,
							});
						}
					}
				}
			}
			this.totalCostDelta = totalCostDelta;
			this.$p85.next(p85Date);
			this.$earliest.next(earliest);
			this.$latest.next(latest);
			this.$avg.next(avg);
			this.$delta.next(differenceInCalendarDays(p85Date, this.$currentCompletion.getValue()));
			this.mitigationHits = mitigationHits;
			this.mitigationProbabilities = mitigationProbabilities;
			const likelihoodSeries = groupBy(mitigationHits, [{ field: 'title' }]) as GroupResult[];
			if (hasObjChanged(this.likelihoodSeries, likelihoodSeries)) {
				this.likelihoodSeries = likelihoodSeries;
			}
			const probabilitySeries = groupBy(mitigationProbabilities, [{ field: 'title' }]) as GroupResult[];
			if (hasObjChanged(this.probabilitySeries, probabilitySeries)) {
				this.probabilitySeries = probabilitySeries;
			}
			this.selectedRegisters = selectedRegisters;
			const series: Series[] = [
				{
					type: 'column',
					data: deltaHistory.filter((h) => h !== undefined),
					name: 'Delta',
					color: '#707070',
				},
				{
					type: 'line',
					data: preMitigationHistory.filter((h) => h !== undefined),
					name: 'Pre-Mitigation',
					color: '#f5c2c2',
				},
				{
					type: 'line',
					data: postMitigationHistory.filter((h) => h !== undefined),
					name: 'Post Mitigation',
					color: '#90c0de',
				},
			];
			if (hasObjChanged(this.series, series)) {
				this.series = series;
			}
			const dataDatesDisplay = dataDatesHistory.filter((n) => n !== undefined);
			if (hasObjChanged(this.dataDates, dataDatesDisplay)) {
				this.dataDates = dataDatesDisplay;
			}

			this.riskAnalysisData = riskAnalysisData;
			const visibleRiskRegisters = riskAnalysisData.filter(
				(risk) => !risk.isDraft && risk.preMitigation.activityImpact && risk.impactedTaskCodes.length > 0
			);
			this.visibleRiskRegisters = visibleRiskRegisters;
			this.loadRisks();
		});
	}

	getPerformanceFactorScore(report: CurrentProjectReport) {
		const overallScore = report?.riskPage?.performanceFactor?.overallScore;
		const normalizedScore = overallScore >= 100 ? 100 : overallScore <= 0 ? 0 : overallScore;
		this.$performanceFactorScore.next(normalizedScore || 0);
	}

	public labelContentPct = (e: AxisLabelContentArgs): string => e.value + '%';

	public p85Band: PlotBand[] = [
		{
			from: 85,
			to: 86,
			color: '#ff7f6e',
			opacity: 0.6,
		},
	];
	pfRiskChartInstance(x: Highcharts.Chart): void {
		this.pfRiskChart = x;
	}

	/**
	 * sort change handler
	 * @param sort
	 */
	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadRisks();
	}

	/**
	 * load risks into grid
	 */
	loadRisks(): void {
		this.gridView = {
			data: this.visibleRiskRegisters,
			total: this.visibleRiskRegisters.length,
		};
		this.gridData = this.visibleRiskRegisters;
	}

	saveRegisters() {
		for (let i = 0; i < this.riskAnalysisData.length; i++) {
			this.riskAnalysisData[i].disabled = !this.selectedRegisters.includes(this.riskAnalysisData[i].riskId);
		}

		this.recalcing = true;
		this.initializingMCJob = true;
		this.navBarStorage.monteCarloCalculating = true;
		const projectData = this._projectDashboardService.$currentProjectData.value;
		if (!projectData) {
			return;
		}
		let unassignedCustomFields = projectData?.preferences?.riskMitigation?.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		const data = structuredClone(this.riskAnalysisData) || [];
		this.restService
			.post('project/riskregister/' + this._projectDashboardService.$currentProjectPageId.value, {
				data,
				hiddenColumns: {
					riskPage: Array.from(projectData.preferences.riskMitigation.hiddenMitigationColumns?.riskPage || []),
					fullscreen: Array.from(projectData.preferences.riskMitigation.hiddenMitigationColumns?.fullscreen || []),
					scheduleSelector: Array.from(
						projectData.preferences.riskMitigation.hiddenMitigationColumns?.scheduleSelector || []
					),
				},
				unassignedCustomFields: {
					riskOwner: {
						name: Array.from(unassignedCustomFields?.riskOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.riskOwner?.email) || [],
					},
					costOwner: {
						name: Array.from(unassignedCustomFields?.costOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.costOwner?.email) || [],
					},
					responsibility: {
						name: Array.from(unassignedCustomFields?.responsibility?.name) || [],
						email: Array.from(unassignedCustomFields?.responsibility?.email) || [],
					},
					category: Array.from(unassignedCustomFields?.category) || [],
				},
				lastRecalcedMonteCarlo: new Date(),
			})
			.subscribe((resp) => {
				this.restService.post(`report/calculate/${projectData._id}`, { onlySimulate: true }).subscribe(
					(val) => {
						this.recalcing = false;
					},
					(response) => {
						console.log('POST call in error', response);
						this.recalcing = false;
					},
					() => {}
				);
			});
		this.scheduleService.$manualIdUpdate.next(this._projectDashboardService.$currentProjectReport.value?.project?._id);
	}

	public isRowSelected = (e?: RowArgs, rowIndex?: number): boolean =>
		this.selectedRegisters.includes(e.dataItem.riskId);
	public isRowItemSelected = (dataItem: RegisterInfo): boolean => this.selectedRegisters.includes(dataItem.riskId);

	refreshWindow() {
		setTimeout(() => {
			window.location.reload();
		}, 100);
	}

	navToSchedules() {
		document.body.scrollTo(0, 0);
		this.navBarStorage.selectedTab = 'schedules';
		const url = new URLSearchParams(new URL(window.location.href).search);
		url.set('tab', this.navBarStorage.selectedTab);
		history.pushState(null, '', window.location.pathname + '?' + url.toString());
		this.navBarStorage.$tabPointer.next(this.navBarStorage.selectedTab);
	}
}
