import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { hasObjChanged } from '../../../../util/projects';
import {
	FloatConsumptionTableCardComponent,
	FloatConsumptionView,
} from './float-consumption-table-card/float-consumption-table-card.component';
import { AxisLabelsPosition, LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { RestService } from '../../../../services/common/rest.service';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { caretAltDownIcon, fileExcelIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import {
	ActvCodeFilterItem,
	ActvCompletionActv,
	SubCodeFilterItem,
} from '../activity-completion/activity-completion.component';
import { dataFromXer } from '../../../../util/tasks';
import { XerActivity, XerActivityCode, XerActivityType, XerData, XerTaskActivity } from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { TotalFloatIndexArgs } from '@rhinoworks/analytics-calculations';
import { haveCommonItem } from '../../../../util/strings';
import { union } from 'lodash';

interface FloatHistoricalChartData {
	data: number[];
	name: string;
}

@Component({
	selector: 'app-float-consumption',
	templateUrl: './float-consumption.component.html',
	styleUrls: ['./float-consumption.component.scss'],
})
export class FloatConsumptionComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	@Input() visualizer: boolean = false;
	@Input() isOverview: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() hideNotes: boolean = false;
	@Input() focusTabDisabledCode: boolean = false;
	@Input() floatHistorical: TotalFloatIndexArgs[];
	@Input() focusTabSelectedActvCode: SubCodeFilterItem[];
	categoryLabelsPosition: AxisLabelsPosition = 'start';
	selectedFloatConsumptionView: FloatConsumptionView = FloatConsumptionView.averageFloat;
	cardActivitiesLabel = new Map<FloatConsumptionView, string>([]);
	floatConsumptionView = FloatConsumptionView;
	isPrevAvgFloatGreater: boolean = false;
	isPrevNegFloatGreater: boolean = false;
	isPrevCritPathFloatGreater: boolean = false;
	isPrevNearCritFloatGreater: boolean = false;
	isPrevMonthFloatGreater: boolean = false;
	isPrevLargeFloatGreater: boolean = false;

	totalActivityCodes = [];
	public selectedActivityCodes: { shortName: string; codeName: string; parentName: string }[] = [];
	allActivityCodes: ActvCodeFilterItem[] = [];
	allSubCodes;
	codesTag: string = '';
	icons = {
		caretDown: caretAltDownIcon,
	};
	loading: boolean = false;

	eventsSubject: Subject<void> = new Subject<void>();
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	categories: string[] = [];
	chartData: FloatHistoricalChartData[] = [];
	FLOAT_VIEW_TO_SERIES = new Map<FloatConsumptionView, string[]>([
		[FloatConsumptionView.averageFloat, ['Avg']],
		[FloatConsumptionView.negative, ['<0']],
		[FloatConsumptionView.criticalPathFloat, ['0']],
		[FloatConsumptionView.nearCriticalFloat, ['1-7']],
		[FloatConsumptionView.monthFloat, ['8-30']],
		[FloatConsumptionView.largeFloat, ['30+']],
	]);
	SERIES_TO_FLOAT_VIEW = new Map<string, FloatConsumptionView>([
		['Avg', FloatConsumptionView.averageFloat],
		['<0', FloatConsumptionView.negative],
		['0', FloatConsumptionView.criticalPathFloat],
		['1-7', FloatConsumptionView.nearCriticalFloat],
		['8-30', FloatConsumptionView.monthFloat],
		['30+', FloatConsumptionView.largeFloat],
	]);
	@ViewChild('gridCard') gridCard: FloatConsumptionTableCardComponent;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	hasNotes: boolean = false;
	focusTabHistoricalVals: Array<TotalFloatIndexArgs & { updateId?: string }> = [];
	actvCodes: XerActivityCode[] = [];
	focusTabActvCode: XerActivityCode[];
	focusTabTasks: XerActivity[] = [];
	focusTabPrevTasks: XerActivity[] = [];
	hasHistoricalData: boolean = false;
	doSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public projectService: ProjectDashboardService,
		public restService: RestService,
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService,
		public storage: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
			.subscribe((report) => {
				if (!report?.floatHistorical) {
					return;
				}
				if (report?.project?.company) {
					this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
				}
				if (this.storage.$allUpdates.value.length === report.updateIds.length) {
					this.updateChartData(
						this.storage.$allUpdates.value,
						{
							...report,
							floatHistorical: report.floatHistorical,
						},
						false
					);
				}
			});

		this.storage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (this.projectService.$currentProjectReport.value) {
				const report = this.projectService.$currentProjectReport.value;
				this.updateChartData(
					updates,
					{
						...report,
						floatHistorical: report.floatHistorical,
					},
					false
				);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 5)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	async updateChartData(
		updates: UpdateInterface[],
		report: CurrentProjectReport & { floatHistorical: TotalFloatIndexArgs[] },
		skipFilterReset: boolean = false
	) {
		if (!updates) {
			return;
		}
		const focusTabCodeHistoricalVals: Array<TotalFloatIndexArgs & { updateId?: string }> = [];
		if (report?.actvCodeFloatHistorical) {
			for (let i = 0; i < updates?.length; i++) {
				for (let j = 0; j < this.focusTabSelectedActvCode?.length; j++) {
					const updateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> = report.actvCodeFloatHistorical[i];
					const recordKey: string =
						this.focusTabSelectedActvCode[j]?.codeName + '%--//--%' + this.focusTabSelectedActvCode[j]?.shortName;
					const updateVal: TotalFloatIndexArgs & { updateId?: string } = updateFloatHistoricalByCode[recordKey];
					if (updateVal) {
						updateVal.updateId = report.updateIds[i];
						focusTabCodeHistoricalVals.push(updateVal);
					} else {
						focusTabCodeHistoricalVals.push({
							floatAverage: null,
							floatLarge: null,
							floatMonth: null,
							floatNegative: null,
							floatNone: null,
							floatWeek: null,
							updateId: report.updateIds[i],
						});
					}
				}
			}
		}
		this.focusTabHistoricalVals = focusTabCodeHistoricalVals;
		const selectedActivityCodes = new Set<string>(this.selectedActivityCodes.map((item) => item.shortName));
		const latestUpdateXerData: XerData = await this.storage.grabUpdateXerData(updates[updates.length - 1]?._id);
		const prevUpdateXerData: XerData =
			updates?.length > 1 ? await this.storage.grabUpdateXerData(updates[updates.length - 2]?._id) : null;
		const currActvCodes = await this.storage.grabUpdateTable<XerActivityCode>(
			updates[updates.length - 1]._id,
			'ACTVCODE'
		);
		const currTaskActv = await this.storage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(updates[updates.length - 1]._id, 'TASKACTV');
		const currActvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of currTaskActv) {
			if (!currActvsByTask[ta.task_id]) {
				currActvsByTask[ta.task_id] = [];
			}
			currActvsByTask[ta.task_id].push(ta);
		}
		const currAllTasks = await this.storage.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK');
		this.allActivityCodes = this.generateActivityCodeFilter(latestUpdateXerData, prevUpdateXerData);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;

		const currShortCodes = new Map<number, string[]>(
			currAllTasks.map((task) => [
				task.task_id,
				currActvCodes
					.filter((ac) => currActvsByTask[task.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
					.map((t) => t.short_name) || [],
			])
		);
		const tasks = new Map<string, any>(
			currAllTasks
				.filter(
					(task) =>
						task.task_type !== 'TT_LOE' &&
						(!selectedActivityCodes.size ||
							haveCommonItem(new Set(currShortCodes.get(task.task_id)), selectedActivityCodes))
				)
				.map((task) => [
					task.task_code,
					{
						...task,
						activityCodeShortnames: currShortCodes.get(task.task_id),
						activityCodes: currShortCodes.get(task.task_id) || [],
					},
				])
		);

		let totalActivityCodes = [];
		for (const [key, value] of tasks.entries()) {
			totalActivityCodes = union(value?.activityCodes, totalActivityCodes);
		}
		this.totalActivityCodes = totalActivityCodes;
		const categories: string[] = (report.updateIds || []).map((a, index) =>
			index === 0 ? 'Baseline' : `Update ${index}` + (report?.baselineUpdateId === report.updateIds[index] ? ' ®' : '')
		);
		const floatHistorical: Array<TotalFloatIndexArgs & { updateId?: string }> = this.focusTabSelectedActvCode?.length
			? focusTabCodeHistoricalVals
			: this.floatHistorical || report.floatHistorical || [];
		this.hasHistoricalData = floatHistorical?.length > 0;
		const totalNumWithFloats: number[] = floatHistorical.map(
			(a) => a.floatLarge + a.floatNegative + a.floatNone + a.floatWeek + a.floatMonth || 1
		);
		const chartData = [
			{
				data: floatHistorical.map((a, index) => a.floatAverage),
				name: 'Avg',
				color: '#001489',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatNegative || 0) / (totalNumWithFloats[index] || 1)),
				name: '<0',
				color: 'rgb(229,113,113)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatNone || 0) / (totalNumWithFloats[index] || 1)),
				name: '0',
				color: 'rgb(255,229,109)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatWeek || 0) / (totalNumWithFloats[index] || 1)),
				name: '1-7',
				color: 'rgb(133,215,115)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatMonth || 0) / (totalNumWithFloats[index] || 1)),
				name: '8-30',
				color: 'rgb(40, 112, 247)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatLarge || 0) / (totalNumWithFloats[index] || 1)),
				name: '30+',
				color: 'rgb(181,99,255)',
			},
		];

		if (hasObjChanged(chartData, this.chartData)) {
			this.chartData = chartData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}

	public onLegendItemClick(e: LegendItemClickEvent): void {
		e.preventDefault();

		const seriesName = e.series.name;
		const view = this.SERIES_TO_FLOAT_VIEW.get(seriesName);
		this.selectedFloatConsumptionView =
			this.selectedFloatConsumptionView === view ? FloatConsumptionView.averageFloat : view;
	}

	changeFloatConsumptionView(floatConsumptionView: FloatConsumptionView) {
		this.selectedFloatConsumptionView = floatConsumptionView;
		this.doSearch.next(true);
	}

	setCardActivities(cardSet: Map<FloatConsumptionView, string>) {
		this.cardActivitiesLabel = cardSet;
	}

	prevAvgFloatGreater(value: boolean) {
		this.isPrevAvgFloatGreater = value;
	}
	prevNegFloatGreater(value: boolean) {
		this.isPrevNegFloatGreater = value;
	}
	prevCritPathFloatGreater(value: boolean) {
		this.isPrevCritPathFloatGreater = value;
	}
	prevNearCritFloatGreater(value: boolean) {
		this.isPrevNearCritFloatGreater = value;
	}
	prevMonthFloatGreater(value: boolean) {
		this.isPrevMonthFloatGreater = value;
	}
	prevLargeFloatGreater(value: boolean) {
		this.isPrevLargeFloatGreater = value;
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		if (ev && ev.length > 1) {
			// @ts-ignore
			this.selectedActivityCodes = [ev[ev.length - 1]];
		}
		this.focusTabSelectedActvCode = [ev[ev.length - 1]].map((obj) => ({
			...obj,
			parentName: null,
		}));
		this.updateTagText([ev[ev.length - 1]]);

		const report = this.projectService.$currentProjectReport.value;
		this.updateChartData(
			this.storage.$allUpdates.value,
			{
				...report,
				floatHistorical: report.floatHistorical,
			},
			true
		).then(() => {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!this.totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});

			/*this.allActivityCodes.forEach((code) => {
				code.disabled =
					this.selectedActivityCodes.length > 0
						? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
						: false;
			});*/
		});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0 || ev[0] === undefined) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param data
	 */
	generateActivityCodeFilter(data: XerData, prevData?: XerData): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = dataFromXer<XerActivityType>(data, 'ACTVTYPE').data;
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.actv_code_type ||
									(selActvCode?.parentName === actvType.actv_code_type && selActvCode?.parentName !== undefined)
							),
				id: actvType.actv_code_type_id,
				name: actvType.actv_code_type.toString(),
				sequenceNumber: Number(actvType.seq_num),
				shortName: actvType.actv_code_type,
				subCodes: [],
				codeName: '',
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes: XerActivityCode[] = dataFromXer<XerActivityCode>(data, 'ACTVCODE').data;
		const tasks: XerActivity[] = dataFromXer<XerActivity>(data, 'TASK').data;
		const prevTasks: XerActivity[] = prevData === null ? null : dataFromXer<XerActivity>(prevData, 'TASK').data;
		const prevActvCodes: XerActivityCode[] =
			prevData === null ? null : dataFromXer<XerActivityCode>(prevData, 'ACTVCODE').data;
		const taskActv: XerTaskActivity[] = dataFromXer<XerTaskActivity>(data, 'TASKACTV').data;
		const prevTaskActv: XerTaskActivity[] =
			prevData === null ? null : dataFromXer<XerTaskActivity>(prevData, 'TASKACTV').data;
		if (this.focusTabSelectedActvCode?.length) {
			const focusTabTaskIds: number[] = taskActv
				.filter((t: XerTaskActivity) => this.focusTabSelectedActvCode.some((code) => t.actv_code_id === code.id))
				.map((a) => a.task_id);
			const selectedActvCodesInPrevActv: XerActivityCode[] =
				prevData === null
					? null
					: prevActvCodes.filter((c) => this.focusTabSelectedActvCode.some((code) => c.short_name === code.shortName));
			const focusTabPrevTaskIds: number[] =
				prevData === null
					? null
					: prevTaskActv
							.filter((t: XerTaskActivity) =>
								selectedActvCodesInPrevActv.some((code) => t.actv_code_id === code.actv_code_id)
							)
							.map((a) => a.task_id);
			this.focusTabTasks = tasks.filter((t: XerActivity) => focusTabTaskIds.includes(t.task_id));
			this.focusTabPrevTasks =
				prevData === null ? null : prevTasks.filter((t: XerActivity) => focusTabPrevTaskIds.includes(t.task_id));
			this.focusTabActvCode = actvCodes.filter((c) =>
				this.focusTabSelectedActvCode.some((code) => c.actv_code_id === code.id)
			);
		}

		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.actv_code_type_id);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.actv_code_id,
					name: actvCode?.actv_code_name?.toString() || actvCode?.short_name?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.seq_num,
					shortName: actvCode.short_name,
					codeName: actvCode.actv_code_name,
					totalAssignments: actvCode.total_assignments || 0,
				};
				if (actvCode.total_assignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.total_assignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	public itemDisabled(dataItem: { alwaysDisabled?: boolean; disabled?: boolean }) {
		return dataItem.alwaysDisabled || dataItem.disabled;
	}
}
