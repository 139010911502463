import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AxisSettings, GaugeColorSettings, SeriesData, SeriesDataSettings } from '../../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';

@Component({
	selector: 'app-critical-path-risk',
	templateUrl: './critical-path-risk.component.html',
	styleUrls: ['./critical-path-risk.component.scss'],
})
export class CriticalPathRiskComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$overallScore = new BehaviorSubject<number>(0);
	cprColors: GaugeColorSettings[] = [
		{
			to: 50,
			color: '#DF5353',
		},
		{
			from: 50,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	label: string = '';
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Total Float (cd)',
				visible: true,
			},
			labels: {
				format: '{0}',
			},
		},
	];
	hasNotes: boolean = false;

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.getCriticalPathRiskScore(report);
			});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 21)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates cpr gauge and chart values based on the latest incoming report
	 * @param report
	 */
	getCriticalPathRiskScore(report: CurrentProjectReport): void {
		const cpr = report?.riskPage?.criticalPathRisk;
		if (!cpr) {
			return;
		}
		const overallScore = cpr?.overallScore || 0;
		this.$overallScore.next(overallScore);
		const firstPath: SeriesData[] = [];
		const secondPath: SeriesData[] = [];
		const thirdPath: SeriesData[] = [];
		const categories = [];
		cpr.cprTrends.firstPathTrend.forEach((y, index) => {
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (report?.baselineUpdateId === report?.updateIds[index] ? ' ®' : '');
			categories.push(category);
			firstPath.push({
				category,
				value: y,
			});
		});
		cpr.cprTrends.secondPathTrend.forEach((y, index) => {
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (report?.baselineUpdateId === report?.updateIds[index] ? ' ®' : '');
			secondPath.push({
				category,
				value: y,
			});
		});
		cpr.cprTrends.thirdPathTrend.forEach((y, index) => {
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (report?.baselineUpdateId === report?.updateIds[index] ? ' ®' : '');
			thirdPath.push({
				category,
				value: y,
			});
		});
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: firstPath,
				name: 'First Path',
				visible: true,
				color: '#DF5353',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: secondPath,
				name: 'Second Path',
				visible: true,
				color: '#0059FF',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: thirdPath,
				name: 'Third Path',
				visible: true,
				color: 'gray',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		this.label = overallScore >= 85 ? 'Low Risk' : overallScore >= 50 ? 'Moderate Risk' : 'High Risk';
	}
}
