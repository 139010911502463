import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExpandedMetrics, ProjectReportInterface, ReportGenerator } from '@rhinoworks/analytics-calculations';
import { GenerateReportParams } from '../../app/app.worker';

@Injectable({
	providedIn: 'root',
})
export class WebWorkerService implements OnDestroy {
	private worker: Worker;
	private taskCounter = 0; // Incremental counter for task IDs
	private taskSubjects = new Map<number, Subject<unknown>>();
	constructor() {
		if (typeof Worker !== 'undefined') {
			this.worker = new Worker(new URL('../../app/app.worker', import.meta.url));
			this.worker.addEventListener('message', this.handleWorkerMessage.bind(this));
		} else {
			console.error('Web Workers are not supported in this environment.');
		}
	}

	private generateTaskId(): number {
		return ++this.taskCounter;
	}

	private handleWorkerMessage(event: MessageEvent) {
		const { taskId, result, error } = event.data;

		const subject = this.taskSubjects.get(taskId);
		if (subject) {
			if (error) {
				subject.error(error);
			} else {
				subject.next(result);
				subject.complete();
			}
			this.taskSubjects.delete(taskId); // Cleanup
		}
	}

	private runTask<T>(task: string, payload): Observable<T> {
		const taskId = this.generateTaskId();
		const result$ = new Subject<T>();

		if (!this.worker) {
			result$.error('Web Worker is not available.');
			return result$.asObservable();
		}

		this.taskSubjects.set(taskId, result$); // Map the task ID to its subject
		this.worker.postMessage({ taskId, task, payload });

		return result$.asObservable();
	}

	// Public methods to expose tasks
	generateReport(params: GenerateReportParams) {
		return this.runTask<{ report: ProjectReportInterface; expandedMetrics: ExpandedMetrics }>('generateReport', params);
	}

	ngOnDestroy(): void {
		if (this.worker) {
			this.worker.terminate();
		}
	}
}
