import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalculationsService } from '../project/calculations.service';
import { RestService } from '../common/rest.service';
import { differenceInDays, format, isBefore, isSameDay } from 'date-fns';
import { ProjectDashboardService } from '../project/project.service';
import { ProjectInterface } from '../../models/Project';
import { DropdownMenuItemData } from '../../components/portfolio/portfolio.component';
import { CompanyInterface } from '../../models/company';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NavigationBarStorageService } from '../common/navigation-bar-storage.service';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';

export type PortfolioProject = ProjectInterface & { rawReport?: ProjectReportInterface };

@Injectable({
	providedIn: 'root',
})
export class AnalyticsDashboardService {
	tooltipType = new BehaviorSubject<string>('');
	$tooltipType: Observable<string>;
	editProfileOpen = new BehaviorSubject<boolean>(null);
	$editProfileOpen: Observable<boolean>;
	addUpdateOpen = new BehaviorSubject<boolean>(null);
	$addUpdateOpen: Observable<boolean>;
	addUpdateOpenSchedulesTab = new BehaviorSubject<boolean>(null);
	$addUpdateOpenSchedulesTab: Observable<boolean>;
	shouldBeSoftAdd = new BehaviorSubject<boolean>(false);
	$shouldBeSoftAdd: Observable<boolean>;
	riskCalcMethodOpen = new BehaviorSubject<boolean>(null);
	$riskCalcMethodOpen: Observable<boolean>;
	projectDetailsOpen = new BehaviorSubject<boolean>(null);
	$projectDetailsOpen: Observable<boolean>;
	unsavedScheduleChangesOpen = new BehaviorSubject<boolean>(null);
	$unsavedScheduleChangesOpen: Observable<boolean>;
	ganttGroupByOpen = new BehaviorSubject<boolean>(null);
	$ganttGroupByOpen: Observable<boolean>;
	updateReportOpen = new BehaviorSubject<boolean>(null);
	$updateReportOpen: Observable<boolean>;
	baselineOpen = new BehaviorSubject<boolean>(null);
	$baselineOpen: Observable<boolean>;
	addProjectOpen = new BehaviorSubject<boolean>(null);
	$addProjectOpen: Observable<boolean>;
	globalTooltipType = new BehaviorSubject<string>('');
	$globalTooltipType: Observable<string>;
	riskSettingsOpen = new BehaviorSubject<boolean>(null);
	$riskSettingsOpen: Observable<boolean>;
	user = new BehaviorSubject(null);
	$user: Observable<any>;
	riskMetricsOptions = new BehaviorSubject(null);
	$riskMetricsOptions: Observable<any>;
	projectReport = new BehaviorSubject(null);
	$projectReport: Observable<any>;
	$closeAll = new BehaviorSubject<boolean>(false);

	resetFilters = new BehaviorSubject<boolean>(null);
	$resetFilters: Observable<boolean>;

	$companies = new BehaviorSubject<Array<CompanyInterface>>([]);
	clientList: DropdownMenuItemData[] = [];

	ccdUpperLimit: number | null = null;
	ccdLowerLimit: number | null = null;
	$allProjects = new BehaviorSubject<Array<PortfolioProject>>(undefined);
	selectedRiskMetricsType: Array<string> = ['All'];
	selectedReliabilityScore = 'All';
	selectedRiskScore = 'All';
	selectedAegisScore = 'All';
	selectedQCScore = 'All';
	selectedPastPeriodPerformance = 'All';
	selectedCompletionStatus = 'All';
	selectedUpload = 'All';
	selectedAegisPOC = 'All';
	selectedCrashes: Array<string> = ['All'];
	selectedRegions: Array<string> = ['All'];
	selectedClients: Array<string> = ['All'];
	selectedProjectTypes: Array<string> = ['All'];
	selectedProjectStatus: 'All' | 'Active' | 'Archived' = 'Active';
	$activeFilters: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set<string>([]));
	static isGoogleMapsScriptLoaded: boolean = false;
	canEditPortfolioLocalStorage = false;
	ganttSort = new BehaviorSubject<SortDescriptor[]>(null);
	navOutsideProj = false;
	navUrl: string = null;
	floatConsumptionLoading: boolean = true;

	constructor(
		private _httpClient: HttpClient,
		private calculationsService: CalculationsService,
		private restService: RestService,
		private _projectDashboardService: ProjectDashboardService,
		private navBarStorage: NavigationBarStorageService
	) {
		this.$tooltipType = this.tooltipType.asObservable();
		this.$editProfileOpen = this.editProfileOpen.asObservable();
		this.$addUpdateOpen = this.addUpdateOpen.asObservable();
		this.$addUpdateOpenSchedulesTab = this.addUpdateOpenSchedulesTab.asObservable();
		this.$shouldBeSoftAdd = this.shouldBeSoftAdd.asObservable();
		this.$riskCalcMethodOpen = this.riskCalcMethodOpen.asObservable();
		this.$projectDetailsOpen = this.projectDetailsOpen.asObservable();
		this.$unsavedScheduleChangesOpen = this.unsavedScheduleChangesOpen.asObservable();
		this.$ganttGroupByOpen = this.ganttGroupByOpen.asObservable();
		this.$updateReportOpen = this.updateReportOpen.asObservable();
		this.$baselineOpen = this.baselineOpen.asObservable();
		this.$addProjectOpen = this.addProjectOpen.asObservable();
		this.$globalTooltipType = this.globalTooltipType.asObservable();
		this.$riskSettingsOpen = this.riskSettingsOpen.asObservable();
		this.$user = this.user.asObservable();
		this.$riskMetricsOptions = this.riskMetricsOptions.asObservable();
		this.$projectReport = this.projectReport.asObservable();
		this.$resetFilters = this.resetFilters.asObservable();
	}

	/**
	 * Resolver
	 *
	 * @param route
	 * @param state
	 * @returns
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise<void>(async (resolve, reject) => {
			if (route.url[0]?.path !== 'project') {
				this.fetchProjects(resolve);
			}
			this.getCompanies().subscribe(async (companyData) => {
				const companies: CompanyInterface[] = companyData.Companies;
				console.log({ companies });

				const companiesWithProjects = [];
				const allCompanies = new Map<string, CompanyInterface>([]);
				this._projectDashboardService.availableClientNames = [];
				for (const company of companies) {
					//if (!company.isArchived && company.projectIds?.length > 0) {
					if (!company.isArchived && company.name) {
						companiesWithProjects.push(company);
						allCompanies.set(company._id, company);
						this._projectDashboardService.availableClientNames.push(company.name);
					}
				}
				this._projectDashboardService.availableClientNames.sort();
				if (companiesWithProjects) {
					this.$companies.next(companiesWithProjects);
					this._projectDashboardService.companiesById = allCompanies;
					this._projectDashboardService.allCompanies = Array.from(allCompanies.values());
					this.clientList = [];
					const clientNames = new Set<string>([]);
					for (const company of companiesWithProjects) {
						let clientName = company.name || '';
						clientName = clientName.toLowerCase();
						if (clientName && !clientNames.has(clientName)) {
							clientNames.add(clientName);
							this.clientList.push({
								value: company._id,
								viewValue: company.name,
							});
							this.clientList.sort((a, b) => (a.viewValue > b.viewValue ? 1 : -1));
						}
					}
					this.clientList.splice(0, 0, { value: '', viewValue: 'All' });
				}
				resolve();
			});
		});
	}

	fetchProjects(resolve?: () => any) {
		console.log('fetching projects...');
		// IF YOU NEED TO ACCESS A NEW PROPERTY ON THE PROJECT OBJECT FOR PORTFOLIO USE, ADD IT TO THE BACKEND QUERY PROJECTION!
		// This lives in backend's project.controller.ts getPortfolioProjectList() around lines 132 and 173
		return this.getPortfolioProjects().subscribe(async (projectData) => {
			const projects = projectData?.projects || {};
			for (const projectId of Object.keys(projects)) {
				const project = projects[projectId];
				this._projectDashboardService.projectsMap.set(projectId, projects[projectId]);
				this._projectDashboardService.projectReportMap.set(projectId, projects[projectId]?.rawReport);
				projects[projectId].prevVariance = projects[projectId].rawReport?.projectTable?.previousVariance;
				const lastUploaded = new Date(project?.rawReport?.projectOverview?.dataDate);
				const now = new Date();
				projects[projectId].lastUploadDifference = differenceInDays(now, lastUploaded);
				projects[projectId].aegisScore = project.rawReport?.projectScore;
				projects[projectId].riskScore = project.rawReport?.riskScore;
				projects[projectId].qcScore = project?.rawReport?.qualityControl?.qcScore;
			}
			if (projects) {
				this.$allProjects.next(Object.values(projects));
			}
			resolve?.();
		});
	}

	isDefaultFilterValue(val: string | string[] | number) {
		return (
			val == null ||
			(typeof val !== 'number' &&
				(val.length === 0 || val.includes('All') || (typeof val === 'object' && val.includes(''))))
		);
	}

	resetAllFilters() {
		this.ccdUpperLimit = null;
		this.ccdLowerLimit = null;
		this.selectedClients = ['All'];
		this.selectedRegions = ['All'];
		this.selectedCrashes = ['All'];
		this.selectedAegisPOC = 'All';
		this.selectedProjectTypes = ['All'];
		this.selectedPastPeriodPerformance = 'All';
		this.selectedAegisScore = 'All';
		this.selectedQCScore = 'All';
		this.selectedRiskScore = 'All';
		this.selectedReliabilityScore = 'All';
		this.selectedRiskMetricsType = ['All'];
		this.selectedUpload = 'All';
		this.selectedCompletionStatus = 'All';
		this.selectedProjectStatus = 'Active';
		this.$activeFilters.next(new Set<string>(['projectStatus']));
	}

	setFilter(
		filterType: string,
		filterValue: string | number | null = '',
		prevFilterValues?: Array<string>,
		skipUpdateList?: boolean
	): void {
		let prevFilterValueCopy = prevFilterValues?.slice();
		const setFilters = this.$activeFilters.value;
		if (
			(prevFilterValues === undefined && this.isDefaultFilterValue(filterValue)) ||
			(prevFilterValues !== undefined && this.isDefaultFilterValue(prevFilterValues))
		) {
			setFilters.delete(filterType);
		} else {
			setFilters.add(filterType);
		}
		this.$activeFilters.next(setFilters);
		if (filterValue === '') {
			filterValue = 'All';
		}
		if (filterValue === 'All') {
			prevFilterValueCopy = ['All'];
		} else if (prevFilterValues) {
			const allIndex = prevFilterValueCopy?.indexOf('All');
			if (prevFilterValueCopy?.length > 1 && allIndex >= 0) {
				prevFilterValueCopy.splice(allIndex, 1);
			} else if (allIndex < 0 && prevFilterValueCopy?.length === 0) {
				prevFilterValueCopy.push('All');
			}
		}
		switch (filterType) {
			case 'projectType':
				{
					this.selectedProjectTypes = prevFilterValueCopy;
				}
				break;
			case 'client':
				{
					this.selectedClients = prevFilterValueCopy;
				}
				break;
			case 'region':
				{
					this.selectedRegions = prevFilterValueCopy;
				}
				break;
			case 'crash':
				{
					this.selectedCrashes = prevFilterValueCopy;
				}
				break;
			case 'completionStatus':
				{
					this.selectedCompletionStatus = filterValue.toString() || 'All';
				}
				break;
			case 'pastPeriodPerformance':
				{
					this.selectedPastPeriodPerformance = filterValue.toString() || 'All';
				}
				break;
			case 'uploadStatus':
				{
					this.selectedUpload = filterValue.toString() || 'All';
				}
				break;
			case 'aegisScore':
				{
					this.selectedAegisScore = filterValue.toString() || 'All';
				}
				break;
			case 'qcScore':
				{
					this.selectedQCScore = filterValue.toString() || 'All';
				}
				break;
			case 'riskScore':
				{
					this.selectedRiskScore = filterValue.toString() || 'All';
				}
				break;
			case 'reliabilityScore':
				{
					this.selectedReliabilityScore = filterValue.toString() || 'All';
				}
				break;
			case 'aegisPOC':
				{
					this.selectedAegisPOC = filterValue.toString() || 'All';
				}
				break;
			case 'ccdLowerLimit':
				{
					this.ccdLowerLimit = +filterValue || null;
				}
				break;
			case 'ccdUpperLimit':
				{
					this.ccdUpperLimit = +filterValue || null;
				}
				break;
			case 'riskMetricsType':
				{
					this.selectedRiskMetricsType = prevFilterValueCopy;
				}
				break;
			case 'projectStatus': {
				this.selectedProjectStatus = (filterValue.toString() as 'Active' | 'Archived' | 'All') || 'Active';
			}
		}
		if (skipUpdateList !== true) {
			let commaString = '';
			this.$activeFilters.value.forEach((filter) => {
				commaString += filter + ':';
				switch (filter) {
					case 'projectType':
						{
							let ptString = '';
							this.selectedProjectTypes.forEach((pt) => {
								ptString += pt + '+';
							});
							ptString = ptString.substring(0, ptString.length - 1);
							commaString += ptString + ',';
						}
						break;
					case 'client':
						{
							let clientString = '';
							this.selectedClients.forEach((client) => {
								clientString += client + '+';
							});
							clientString = clientString.substring(0, clientString.length - 1);
							commaString += clientString + ',';
						}
						break;
					case 'region':
						{
							let regionString = '';
							this.selectedRegions.forEach((region) => {
								regionString += region + '+';
							});
							regionString = regionString.substring(0, regionString.length - 1);
							commaString += regionString + ',';
						}
						break;
					case 'crash':
						{
							commaString += this.selectedCrashes + ',';
						}
						break;
					case 'completionStatus':
						{
							commaString += this.selectedCompletionStatus + ',';
						}
						break;
					case 'pastPeriodPerformance':
						{
							commaString += this.selectedPastPeriodPerformance + ',';
						}
						break;
					case 'uploadStatus':
						{
							commaString += this.selectedUpload + ',';
						}
						break;
					case 'aegisScore':
						{
							commaString += this.selectedAegisScore + ',';
						}
						break;
					case 'qcScore':
						{
							commaString += this.selectedQCScore + ',';
						}
						break;
					case 'riskScore':
						{
							commaString += this.selectedRiskScore + ',';
						}
						break;
					case 'reliabilityScore':
						{
							commaString += this.selectedReliabilityScore + ',';
						}
						break;
					case 'aegisPOC':
						{
							commaString += this.selectedAegisPOC + ',';
						}
						break;
					case 'ccdLowerLimit':
						{
							commaString += this.ccdLowerLimit + ',';
						}
						break;
					case 'ccdUpperLimit':
						{
							commaString += this.ccdUpperLimit + ',';
						}
						break;
					case 'riskMetricsType':
						{
							let rmtString = '';
							this.selectedRiskMetricsType.forEach((rmt) => {
								rmtString += rmt + '+';
							});
							rmtString = rmtString.substring(0, rmtString.length - 1);
							commaString += rmtString + ',';
						}
						break;
					case 'projectStatus': {
						commaString += this.selectedProjectStatus + ',';
					}
				}
			});
			commaString = commaString.substring(0, commaString.length - 1);
			localStorage.setItem('portfolioActiveFilters', commaString);
		}
	}

	getPortfolioProjects() {
		return this.restService.fetch('projects', {});
	}

	getCompanies() {
		return this.restService.fetch('companies', {});
	}

	yyyymmdd() {
		return format(new Date(), 'yyyyMMdd');
	}

	closeAll() {
		this.$closeAll.next(true);
		this.addUpdateOpenSchedulesTab.next(false);
		this.shouldBeSoftAdd.next(false);
		this.navBarStorage.showingNewProject = false;
	}
}
