import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../services/common/rest.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ifElse } from '@progress/kendo-data-query/dist/npm/funcs';
import { UserService } from '../../../services/common/user.service';

interface WBSItem {
	id: number;
	parent_wbs_id: number | null;
	proj_flag: 'Y' | 'N';
	proj_id: number;
	seq_num: number;
	wbs_name: string;
	wbs_short_name: string;
}

interface WBSTreeNode {
	item: WBSItem;
	children: WBSTreeNode[];
}

interface DbInfo {
	connection_id: string;
	cron_frequency_string: string;
	actions: any[];
	active: boolean;
}

@Component({
	selector: 'app-db-sync-tab',
	templateUrl: './db-sync-tab.component.html',
	styleUrls: ['./db-sync-tab.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DbSyncTabComponent implements OnInit {
	@Input() fromBulkUpdate: boolean = false;
	@Input() fromNewProject: boolean = false;
	@Input() hasExistingSync: boolean;
	@Input() selectedDatabase: string;
	@Output() saveFromNewProject = new EventEmitter<DbInfo>();
	running: boolean = false;
	weeklySelectionValueObject;
	weeklySelectionValue: number = 1;
	monthlySelectionValue: number = 1;
	selectedSyncId: string = '';
	preLoadedProject = null;
	fieldsDisabled: boolean = false;
	preLoadedSavedData: boolean = false;
	editClicked: boolean = false;
	syncFrequency: string;
	selectedMode;
	selectedFrequency;
	isSyncActive: boolean = false;
	disableParent: boolean = false;
	onlyCanSelectSingleSchedule: boolean;
	filtersShowing: boolean = false;

	pcatRaws: any[] = [];
	projectRaws: any[] = [];
	scheduleFormGroup: FormGroup;
	selectionConnectionList: any[] = [];
	rawSelectionConnectionList: any[] = [];
	databaseFailedConnect: boolean = false;

	// db stuff
	selectionDatabaseList: any[] = [];
	rawSelectionDatabaseList: any[] = [];
	selectionPCatList: any[] = [];
	rawSelectionPCatList: any[] = [];
	selectionPCatValList: any[] = [];
	rawSelectionPCatValList: any[] = [];

	selectionProjectList: any[] = [];

	databaseInfo: any = {
		database: '',
		selectedPCat: '',
		selectedPCatVal: '',
		projectNumber: '',
		projectName: '',
		selectedId: '',
		wbsParentId: 0,
	};

	modeOptions = [
		/*{ text: 'Single - Sync one Schedule and take changes as new updates', value: 1 },*/
		{ text: 'Folder - Select a folder that contains the schedules to sync', value: 2 },
		{
			text: 'Filter - Sync schedules that match the filter code and value',
			value: 3,
		},
	];

	frequencyOptions = [
		{ text: 'Daily (12am Eastern)', value: 'daily' },
		{ text: 'Weekly (Select Day of Week, occurs at 12am Eastern)', value: 'weekly' },
		{ text: 'Monthly (Select Day of Month, occurs at 12am Eastern)', value: 'monthly' },
	];

	weeklySelectionOptions = [
		{ text: 'Monday', value: '1' },
		{ text: 'Tuesday', value: '2' },
		{ text: 'Wednesday', value: '3' },
		{ text: 'Thursday', value: '4' },
		{ text: 'Friday', value: '5' },
	];

	ngOnInit() {
		this.restService.getAccount('v2/my/databases/').subscribe((res: any) => {
			console.log(res);
			this.userService?.user.subscribe((data) => {
				if (data) {
					console.log(data);
					const companyList = data.companyIds;
					res = res.filter((database) => {
						const allowedCompanyList = database.company_assignments;
						if (!allowedCompanyList) {
							return true;
						}
						return allowedCompanyList.some((company) => companyList.includes(company.analyticsId));
					});
					console.log(res);
				}
			});

			this.selectionConnectionList = res;
			this.rawSelectionConnectionList = res;
			if (res.length === 1) {
				this.databaseInfo.selectedConnectionID = res[0].id;
				this.databaseInfo.selectedConnection = res[0];
				this.loadNewDatabasesToSelect(res[0]);
				this.cdr.detectChanges();
			}
			console.log('selectionConnectionList', this.selectionConnectionList);

			this.restService.getAccountDbSync('api/v1/schedules/').subscribe((res: any) => {
				console.log(res);
				if (res) {
					const id = this._projectDashboardService.$currentProjectData.value?._id;
					let i = res.length - 1;
					let data = null;
					while (i >= 0) {
						if (res[i].actions[0].analytics_project_id === id) {
							data = res[i];
							break;
						}

						i = i - 1;
					}
					if (data === null) {
						if (this.selectedDatabase) {
							this.loadNewFilterCodesAndProjects(this.selectedDatabase);
						}
						this.hasExistingSync = false;
						return;
					}
					this.hasExistingSync = true;
					this.fieldsDisabled = true;

					this.isSyncActive = data.active;
					this.onlyCanSelectSingleSchedule = true;
					if (data.actions[0].mode === 'filter') {
						this.scheduleFormGroup.get('schedule')?.clearValidators();
						this.scheduleFormGroup.get('filterCode')?.setValidators(Validators.required);
						this.scheduleFormGroup.get('filterValue')?.setValidators(Validators.required);
						this.scheduleFormGroup.get('schedule')?.updateValueAndValidity();
						this.scheduleFormGroup.get('filterCode')?.updateValueAndValidity();
						this.scheduleFormGroup.get('filterValue')?.updateValueAndValidity();
						this.scheduleFormGroup.get('filterCode')?.disable();
						this.scheduleFormGroup.get('filterValue')?.disable();
						this.filtersShowing = true;
						this.selectedMode = this.modeOptions[1]; //change to 2 once single is added back
					}
					/*if (data.actions[0].mode === 'single') {
						this.selectedMode = this.modeOptions[0];
					}*/
					if (data.actions[0].mode === 'folder') {
						this.onlyCanSelectSingleSchedule = false;
						this.selectedMode = this.modeOptions[0]; //change to 1 once single is added back
					}
					this.loadNewFilterCodesAndProjects(
						data.actions[0].database,
						data.actions[0].mode === 'filter' ? 1 : 2,
						this.onlyCanSelectSingleSchedule ? data.actions[0]?.projectNumber : data.actions[0]?.wbsParentId,
						data.actions[0]?.selectedPCat,
						data.actions[0]?.selectedPCatVal
					);
					const split = data.cron_frequency_string.split(' ');
					if (split[2] === '*' && split[3] === '*' && split[4] === '*') {
						this.syncFrequency = 'daily';
						this.selectedFrequency = this.frequencyOptions[0];
					}
					if (split[2] !== '*' && split[3] === '*' && split[4] === '*') {
						this.syncFrequency = 'monthly';
						this.selectedFrequency = this.frequencyOptions[2];
						this.scheduleFormGroup.get('monthlySelection')?.setValidators(Validators.required);
						this.scheduleFormGroup.get('monthlySelection')?.updateValueAndValidity();
						this.monthlySelectionValue = Number(split[2]);
					}
					if (split[4] !== '*') {
						this.syncFrequency = 'weekly';
						this.selectedFrequency = this.frequencyOptions[1];
						this.scheduleFormGroup.get('weeklySelection')?.setValidators(Validators.required);
						this.scheduleFormGroup.get('weeklySelection')?.updateValueAndValidity();
						this.weeklySelectionValue = Number(split[4]);
						this.weeklySelectionValueObject = this.weeklySelectionOptions[this.weeklySelectionValue - 1];
					}
					//todo: update the selectedConnection and call loadNewDatabasesToSelect when multiple connections are available(probably do a res.find to match connection id) - kf
					//this.preLoadedSavedData = true;
					this.selectedSyncId = data.id;
				}
			});
		});
	}

	constructor(
		private fb: FormBuilder,
		public restService: RestService,
		private cdr: ChangeDetectorRef,
		public userService: UserService,
		private _projectDashboardService: ProjectDashboardService
	) {
		this.scheduleFormGroup = this.fb.group({
			connection: [null, Validators.required],
			database: [null, Validators.required],
			mode: [null, Validators.required],
			filterCode: [false],
			filterValue: [false],
			schedule: [null, Validators.required],
			frequency: [null, Validators.required],
			weeklySelection: [null],
			monthlySelection: [null],
			isActive: [false],
		});
	}

	editSyncSetting() {
		this.editClicked = true;
		this.fieldsDisabled = false;
	}

	runSyncSetting() {
		this.running = true;
		this.restService.getAccountDbSync(`api/function/schedule/runNow/${this.selectedSyncId}/`).subscribe((res: any) => {
			console.log('got', res);
			// this.running = false;
		});
	}

	removeSyncSetting() {
		this.restService.deleteAccountDbSync(`api/v1/schedules/${this.selectedSyncId}/`).subscribe((res: any) => {
			this.clearForm();
			const hasExistingSyncSettings = false;
			const projectId: string = this._projectDashboardService.$currentProjectData.value?._id;
			if (projectId !== undefined) {
				this.restService
					.patch(`project/${projectId}`, {
						hasExistingSyncSettings,
					})
					.subscribe((val) => {
						console.log('patch res', val.project.hasExistingSyncSettings);
					});
			}
		});
	}

	onSubmit() {
		this.restService
			.postAccountDbSync(
				`api/function/connection/${this.databaseInfo.selectedConnectionID}/test_connection_credentials/`,
				{},
				false,
				{ id: this.databaseInfo.selectedConnectionID }
			)
			.subscribe((res) => {
				console.log(res);
				const cron_string =
					this.syncFrequency === 'daily'
						? '0 0 * * *'
						: this.syncFrequency === 'weekly'
							? '0 0 * * ' + this.weeklySelectionValue
							: '0 0 ' + this.monthlySelectionValue + ' * *';
				const action = this.filtersShowing
					? {
							database: this.databaseInfo.database,
							analytics_project_id: this._projectDashboardService.$currentProjectPageId.value,
							selectedPCat: this.databaseInfo.selectedPCat,
							selectedPCatVal: this.databaseInfo.selectedPCatVal,
							mode: 'filter',
						}
					: this.onlyCanSelectSingleSchedule
						? {
								database: this.databaseInfo.database,
								analytics_project_id: this._projectDashboardService.$currentProjectPageId.value,
								projectNumber: this.databaseInfo.projectNumber,
								mode: 'single',
							}
						: {
								database: this.databaseInfo.database,
								analytics_project_id: this._projectDashboardService.$currentProjectPageId.value,
								wbsParentId: this.databaseInfo.wbsParentId,
								mode: 'folder',
							};
				if (this.hasExistingSync) {
					this.restService
						.patchAccountDbSync(`api/v1/schedules/${this.selectedSyncId}/`, {}, false, {
							connection_id: this.databaseInfo.selectedConnectionID,
							cron_frequency_string: cron_string,
							actions: [action],
							active: this.isSyncActive,
						})
						.subscribe((res: any) => {
							console.log(res);
							const dbSyncIds = [res.id];
							const projectId: string = this._projectDashboardService.$currentProjectData.value?._id;
							const hasExistingSyncSettings = true;
							const projectSelectedDatabase = this.databaseInfo.database;
							if (projectId !== undefined) {
								this.restService
									.patch(`project/${projectId}`, {
										dbSyncIds,
										hasExistingSyncSettings,
										projectSelectedDatabase,
									})
									.subscribe((val) => {
										console.log('patch res');
									});
							}
							this.fieldsDisabled = true;
							this.editClicked = false;
							this.hasExistingSync = true;
						});
				} else {
					this.restService
						.postAccountDbSync('api/v1/schedules/', {}, false, {
							connection_id: this.databaseInfo.selectedConnectionID,
							cron_frequency_string: cron_string,
							actions: [action],
							active: this.isSyncActive,
						})
						.subscribe((res: any) => {
							this.selectedSyncId = res.id;
							this.fieldsDisabled = true;
							const projectSelectedDatabase = this.databaseInfo.database;
							const hasExistingSyncSettings = true;
							const dbSyncIds = [res.id];
							const projectId: string = this._projectDashboardService.$currentProjectData.value?._id;
							if (projectId !== undefined) {
								this.restService
									.patch(`project/${projectId}`, {
										dbSyncIds,
										hasExistingSyncSettings,
										projectSelectedDatabase,
									})
									.subscribe((val) => {
										console.log('patch res', val.project.hasExistingSyncSettings);
									});
							}
							console.log(res);
						});
					/*if (this.fromNewProject) {
						this.saveFromNewProject.emit({
							connection_id: this.databaseInfo.selectedConnectionID,
							cron_frequency_string: cron_string,
							actions: [action],
							active: this.isSyncActive,
						});
					} else {

					}*/
				}
			});
	}

	loadNewDatabasesToSelect($event: any) {
		console.log('hi', $event);
		this.databaseFailedConnect = false;
		this.databaseInfo.selectedId = $event;
		if ($event.id !== '') {
			this.restService.postAccount('v2/apps/importengine/connection/list/', {}, false, { id: $event }).subscribe(
				(res: any) => {
					this.selectionDatabaseList = res.dbs;
					this.rawSelectionDatabaseList = res.dbs;
					console.log('selectionDatabaseList', this.selectionDatabaseList);
				},
				(err) => {
					this.databaseFailedConnect = true;
				}
			);
		} else {
			this.selectionDatabaseList = [];
			this.rawSelectionDatabaseList = [];
			this.selectionPCatList = [];
			this.rawSelectionPCatList = [];
			this.selectionPCatValList = [];
			this.rawSelectionPCatValList = [];
			this.selectionProjectList = [];
		}
	}

	autoComplete(value, rawFieldList, field: 'connection' | 'database' | 'filterCode' | 'filterCodeValue') {
		switch (field) {
			case 'connection':
				return rawFieldList.filter((s) => s.friendly_name.toLowerCase().includes(value.toLowerCase()));
			case 'database':
				return rawFieldList.filter((s) => s.toLowerCase().includes(value.toLowerCase()));
			case 'filterCode':
				return rawFieldList.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()));
			case 'filterCodeValue':
				return rawFieldList.filter((s) => s.value.toLowerCase().includes(value.toLowerCase()));
		}
	}

	adjustScheduleSelectFromMode(ev: any): void {
		this.scheduleFormGroup.get('filterCode')?.clearValidators();
		this.scheduleFormGroup.get('filterValue')?.clearValidators();
		this.scheduleFormGroup.get('schedule')?.clearValidators();
		this.filtersShowing = false;
		if (ev.value === 3) {
			this.scheduleFormGroup.get('filterCode')?.setValidators(Validators.required);
			this.scheduleFormGroup.get('filterValue')?.setValidators(Validators.required);
			this.filtersShowing = true;
			this.onlyCanSelectSingleSchedule = true;
		} else {
			this.scheduleFormGroup.get('schedule')?.setValidators(Validators.required);
			this.selectionProjectList = this.projectRaws;
			this.databaseInfo.selectedPCatVal = '';
			this.databaseInfo.selectedPCat = '';
			this.onlyCanSelectSingleSchedule = ev.value === 1;
		}
		this.scheduleFormGroup.get('filterCode')?.updateValueAndValidity();
		this.scheduleFormGroup.get('filterValue')?.updateValueAndValidity();
		this.scheduleFormGroup.get('schedule')?.updateValueAndValidity();
	}

	updateFrequency(ev: any): void {
		this.scheduleFormGroup.get('weeklySelection')?.clearValidators();
		this.scheduleFormGroup.get('monthlySelection')?.clearValidators();
		if (ev.value === 'weekly') {
			this.scheduleFormGroup.get('weeklySelection')?.setValidators(Validators.required);
		}
		if (ev.value === 'monthly') {
			this.scheduleFormGroup.get('monthlySelection')?.setValidators(Validators.required);
		}
		this.syncFrequency = ev.value;
		this.scheduleFormGroup.get('weeklySelection')?.updateValueAndValidity();
		this.scheduleFormGroup.get('monthlySelection')?.updateValueAndValidity();
	}

	updateWeeklySelection(ev: any): void {
		this.weeklySelectionValue = ev.value;
	}

	updateMonthlySelection(ev: any): void {
		this.monthlySelectionValue = ev;
	}

	updateSyncActive(ev: any): void {
		this.isSyncActive = ev;
	}

	loadNewFilterCodesAndProjects(
		$event: any,
		findProject: number = 0,
		projectNum?: any,
		pCat?: any,
		pCatVal?: any,
		wbsParent?: number
	) {
		console.log($event);
		this.databaseInfo.filterCode = '';
		this.databaseInfo.filterCodeValue = '';
		this.databaseInfo.database = $event;
		this.restService
			.postAccount('v2/apps/importengine/connection/validate/', {}, false, {
				id: this.databaseInfo.selectedId,
				database: $event,
			})
			.subscribe((res) => {
				this.restService
					.postAccount('v2/apps/importengine/connection/wbs/', {}, false, {
						id: this.databaseInfo.selectedId,
						database: $event,
					})
					.subscribe((res2) => {
						console.log(res2);
						const res = this.buildWbsProjectTree(res2.wbs);
						this.selectionProjectList = res;
						this.projectRaws = res;
						if (findProject === 2) {
							this.preLoadedProject = this.findProjectFromProjectNumber(projectNum);
						}
						if (findProject === 1) {
							this.checkPCatFilterRequest(pCat);
							this.filterValueChangeRequest(pCatVal);
						}
					});
				console.log(res);
				//this.selectionProjectList = res.projects;
				this.selectionPCatList = res.pcats;
				this.rawSelectionPCatList = res.pcats;
				//this.projectRaws = res.projects;
				this.pcatRaws = res.pcats;
			});
	}

	checkPCatFilterRequest($event: any) {
		this.databaseInfo.filterCode = $event;
		this.databaseInfo.selectedPCat = $event;
		this.databaseInfo.selectedPCatVal = '';
		this.databaseInfo.filterCodeValue = '';
		this.selectionProjectList = this.projectRaws;
		this.selectionPCatValList = [];
		this.rawSelectionPCatValList = [];
		for (let i = 0; i < this.pcatRaws.length; i++) {
			if (this.pcatRaws[i].id === $event) {
				this.selectionPCatValList = this.pcatRaws[i].values;
				this.rawSelectionPCatValList = this.pcatRaws[i].values;
				break;
			}
		}
	}

	filterValueChangeRequest($event: any) {
		if (!$event) {
			this.selectionProjectList = this.projectRaws;
			return;
		}
		this.databaseInfo.filterCodeValue = $event;
		this.databaseInfo.selectedPCatVal = $event;
		this.selectionProjectList = [];
	}

	buildWbsProjectTree(data: WBSItem[]): WBSTreeNode[] {
		const idToNodeMap: { [key: number]: WBSTreeNode } = {};

		// Create a map of nodes
		data.forEach((item) => {
			idToNodeMap[item.id] = {
				item,
				children: [],
			};
		});

		const rootNodes: any[] = [];

		// Link nodes to their parents
		data.forEach((item) => {
			const node = idToNodeMap[item.id];
			if (item.parent_wbs_id !== null && item.parent_wbs_id in idToNodeMap) {
				idToNodeMap[item.parent_wbs_id].children.push(node);
			} else {
				// If there's no valid parent, it must be a root node
				rootNodes.push(node);
			}
		});

		return rootNodes;
	}

	handleProjectNumber(item: any) {
		let found = false;
		this.databaseInfo.projectName = '';
		this.databaseInfo.projectNumber = null;

		// Recursive function to search for the item in the tree
		const findItem = (nodes: WBSTreeNode[]): boolean => {
			for (const node of nodes) {
				if (node.item.id === item.item.id) {
					this.databaseInfo.projectName = node.item.wbs_name;
					this.databaseInfo.projectNumber = node.item.proj_id;
					if (!this.onlyCanSelectSingleSchedule) {
						this.databaseInfo.wbsParentId = Number(node.item?.id);
					}
					return item.item.proj_flag === (this.onlyCanSelectSingleSchedule ? 'Y' : 'N');
				}
				if (node.children && node.children.length > 0) {
					if (findItem(node.children)) {
						return true;
					}
				}
			}
			return false;
		};

		found = findItem(this.selectionProjectList);
		this.disableParent = found;
	}

	findProjectFromProjectNumber(item: any) {
		this.databaseInfo.projectName = '';
		this.databaseInfo.projectNumber = null;

		// Recursive function to search for the item in the tree
		const findItem = (nodes: WBSTreeNode[]): WBSTreeNode => {
			for (const node of nodes) {
				if (this.onlyCanSelectSingleSchedule ? node.item.proj_id === +item : Number(node.item.id) === +item) {
					this.databaseInfo.projectName = node.item.wbs_name;
					this.databaseInfo.projectNumber = node.item.proj_id;
					return node;
				}
				if (node.children && node.children.length > 0) {
					const found = findItem(node.children);
					if (found) {
						return found;
					}
				}
			}
			return null;
		};
		return findItem(this.selectionProjectList);
	}

	disableCloseForParents(event): void {
		if (!this.disableParent && this.databaseInfo.projectNumber && this.selectionProjectList.length > 0) {
			event.preventDefault();
		}
	}

	clearForm() {
		this.scheduleFormGroup.reset();

		this.databaseInfo.selectedConnection = null;
		this.databaseInfo.database = null;
		this.databaseInfo.filterCode = null;
		this.databaseInfo.filterCodeValue = null;
		this.preLoadedProject = null;
		this.selectedMode = null;
		this.selectedFrequency = null;

		this.editClicked = false;
		this.fieldsDisabled = false;
		this.isSyncActive = false;
		this.hasExistingSync = false;
	}
}
