import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
	CellClickEvent,
	DependencyType,
	GanttComponent,
	GanttDependency,
	TaskClickEvent,
	TimelineViewType,
} from '@progress/kendo-angular-gantt';
import { Task } from '../../../../models/ChartSettings';
import { takeUntil } from 'rxjs/operators';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { UserService } from '../../../../services/common/user.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Activity, Xer, XerActivity } from '@rhinoworks/xer-parse';
import { differenceInCalendarDays, getMonth, isAfter, isBefore, startOfDay } from 'date-fns';
import { caretAltDownIcon, caretAltToBottomIcon, caretAltToTopIcon, rowsIcon } from '@progress/kendo-svg-icons';
import { ExpandEvent } from '@progress/kendo-angular-gantt/expanded-state/expand-event';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { ColumnType } from '../schedule-updates-list.component';
import { isCritical } from '@rhinoworks/analytics-calculations';

@Component({
	selector: 'app-critical-path-comparison',
	templateUrl: './critical-path-comparison.component.html',
	styleUrls: ['./critical-path-comparison.component.scss'],
})
export class CriticalPathComparisonComponent implements OnInit, OnDestroy, OnChanges {
	@Input() isOverview: boolean = false;
	public icons = {
		rows: rowsIcon,
		caretDown: caretAltToBottomIcon,
		caretUp: caretAltToTopIcon,
		caretAltDown: caretAltDownIcon,
	};
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	@Input() selectedDelayActivity: Activity = null;
	@Output() selectionByClick = new EventEmitter<boolean>();
	@ViewChild('criticalPathComparisonGanttChart')
	public gantt: GanttComponent;
	public data: Task[] = [];
	public dependencies: GanttDependency[] = [];
	loading = false;
	public selectionState: Set<number> = new Set();
	public expandedKeys = [];
	public activeView: TimelineViewType = 'year';
	public slotWidth: number = 150;
	public _slotWidth: number = 150;
	public collapsedSlotWidth: number = 150;
	allUpdates: UpdateInterface[] = [];
	usedIds = new Set<number>([]);
	allCriticalActivities = new Map<number, SlimmedTaskCommon>([]);
	criticalActivityIdsByTaskCode = new Map<string, number[]>([]);
	allUpdatesGanttData = new Map<number, Task>([]);
	allUpdatesDependencyData = new Map<number, GanttDependency[]>([]);
	selectedUpdates: number[] = [];
	linkTypeDictionary = {
		PR_FS: DependencyType.FS,
		PR_SF: DependencyType.SF,
		PR_FF: DependencyType.FF,
		PR_SS: DependencyType.SS,
	};
	currentUpdateEarliestDate: Date = null;
	currentUpdateLatestDate: Date = null;
	updateOptions = [];
	show = false;
	allNodesExpanded = false;
	treelistPaneCollapsed = false;
	finishMilestone: XerActivity;
	private updateSelectorPopupRef: PopupRef;
	@ViewChild('updateSelectorPopupTemplate') updateSelectorTemplateRef: TemplateRef<unknown>;
	_checkedUpdates: string[] = [];
	updateSelectorOpen: boolean = false;
	dataDate: Date = null;
	hasNotes: boolean = false;

	constructor(
		public project: ProjectDashboardService,
		public userService: UserService,
		public scheduleStorage: ScheduleStorageService,
		private popupService: PopupService
	) {
		this.taskCallback = this.taskCallback.bind(this);
	}

	ngOnInit() {
		const initAfterData = (updates: UpdateInterface[]) => {
			this.allUpdates = updates;
			this.allUpdatesGanttData.clear();
			const updateOptions = [];
			if (updates.length >= 2) {
				this.selectedUpdates = [updates.length - 2, updates.length - 1];
			} else {
				this.selectedUpdates = [updates.length - 1];
			}
			this.finishMilestone = updates[0]?.finishMilestone;
			updates.forEach((update: UpdateInterface, index: number) => {
				this.usedIds.clear();
				updateOptions.push({ index, name: index === 0 ? 'Baseline' : 'Update ' + index });
			});
			this.updateOptions = updateOptions;
			this.loadData().then(() => {
				this.checkedUpdates =
					updates?.length >= 2
						? [this.selectedUpdates[0].toString(), this.selectedUpdates[1].toString()]
						: [this.selectedUpdates[0].toString()];
			});
		};
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			initAfterData(updates);
			this.dataDate = startOfDay(new Date(updates[updates.length - 1]?.dataDate));
		});
		this.project.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 24)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedDelayActivity) {
			const update = Number(changes.selectedDelayActivity?.currentValue?.id?.toString()?.split('.')[0]);
			if (
				update &&
				update >= 1 &&
				!(
					this.selectionState.has(changes.selectedDelayActivity.currentValue.id) &&
					this.selectedUpdates.length === 2 &&
					this.selectedUpdates[0] === update - 1 &&
					this.selectedUpdates[1] === update
				)
			) {
				this.selectedUpdates = [update - 1, update];
				this.selectionState.clear();
				this.loadData().then(() => {
					if (this.allCriticalActivities.get(this.selectedDelayActivity.id) !== undefined) {
						this.toggleAllUpdateSelect(
							changes.selectedDelayActivity.currentValue.id,
							!this.isSelected(changes.selectedDelayActivity.currentValue)
						);
					} else {
						//activity not in the critical path in the selected update. just select the prev update's actv then.
						const actvWithThisCode = this.criticalActivityIdsByTaskCode.get(this.selectedDelayActivity?.info?.code);
						if (actvWithThisCode !== undefined) {
							const prevUpdateActvId = actvWithThisCode.filter(
								(actv) => Number((actv + '').split('.')[0]) === update - 1
							)[0];
							const prevUpdateActv = this.allCriticalActivities.get(prevUpdateActvId);
							if (prevUpdateActv !== undefined) {
								this.selectionState.add(prevUpdateActvId);
							}
						}
					}

					this.updateSelectionState(false);
					this.gantt.updateView();
				});
			}
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * generate gantt chart data for the passed in update
	 * @param updateId
	 * @param updateIndex
	 */
	async generateGanttData(updateId: string, updateIndex: number): Promise<Task[]> {
		const tasks: Task[] = [];
		const updateTasks = await this.scheduleStorage.grabUpdateTable<XerActivity>(updateId, 'TASK');
		let unsortedCritActv = updateTasks.filter((actv) => isCritical(actv));
		if (!unsortedCritActv.length) {
			const xerData = this.scheduleStorage.cachedXerData.get(updateId);
			const xer = new Xer(xerData);
			xer.markDriving();
			this.scheduleStorage.cachedXerTables
				.get(updateId)
				.set('TASK', xer.sortedActivities.map((a) => a.raw_entry) as never[]);
			unsortedCritActv = xer.sortedActivities.filter((actv) => isCritical(actv)).map((a) => a.raw_entry);
		}
		const criticalActivities: SlimmedTaskCommon[] = unsortedCritActv.sort((a, b) => {
			const dateA = startOfDay(new Date(a.act_start_date || a.early_start_date));
			const dateB = startOfDay(new Date(b.act_start_date || b.early_start_date));
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : updateTasks.indexOf(a) - updateTasks.indexOf(b);
		});
		for (const actv of criticalActivities) {
			this.allCriticalActivities.set(this.getId(actv.task_id, updateIndex), actv);
			let currentActvWithThisCode: number[] = this.criticalActivityIdsByTaskCode.get(actv?.task_code);
			if (currentActvWithThisCode === undefined) {
				currentActvWithThisCode = [this.getId(actv.task_id, updateIndex)];
			} else {
				currentActvWithThisCode.push(this.getId(actv.task_id, updateIndex));
			}
			this.criticalActivityIdsByTaskCode.set(actv?.task_code, currentActvWithThisCode);
			const task: Task = this.getGanttData(actv, updateIndex);
			tasks.push(task);
			if (this.currentUpdateEarliestDate === null || isBefore(task.start, this.currentUpdateEarliestDate)) {
				this.currentUpdateEarliestDate = task.start;
			}
			if (this.currentUpdateLatestDate === null || isAfter(task.end, this.currentUpdateLatestDate)) {
				this.currentUpdateLatestDate = task.end;
			}
			//path ends at project's finish milestone
			if (actv?.task_code === this.finishMilestone?.task_code) {
				break;
			}
		}
		return tasks;
	}

	/**
	 * comparison function to adjust sorted activity order for same start date activities based on pred/succ relationship (if one exists between the 2 actv)
	 * @param a
	 * @param b
	 */
	compareSameStartActivities(a: Activity, b: Activity): number {
		const prevToA = a.previousActivities;
		const prevToB = b.previousActivities;
		if ((prevToA?.length === 0 && prevToB?.length !== 0) || prevToB[0]?.id === a?.id) {
			return -1;
		}
		if ((prevToB?.length === 0 && prevToB?.length !== 0) || prevToA[0]?.id === b?.id) {
			return 1;
		}
		return 0;
	}

	/**
	 * fills in data to gantt task type
	 * @param actv
	 * @param updateIndex
	 */
	getGanttData(actv: SlimmedTaskCommon, updateIndex: number): Task {
		const dataDate: Date = new Date(this.allUpdates[updateIndex].dataDate);
		const start: Date = startOfDay(new Date(actv?.act_start_date || actv?.early_start_date));
		const end: Date = startOfDay(new Date(actv?.act_end_date || actv?.early_end_date));
		let completionRatio: number = 0;
		if (isAfter(dataDate, start)) {
			const durationUntilDataDate: number = differenceInCalendarDays(dataDate, start);
			const taskDuration: number = differenceInCalendarDays(end, start);
			completionRatio = durationUntilDataDate / (taskDuration || 1);
			completionRatio = completionRatio > 1 ? 1 : completionRatio;
		}
		return {
			completionRatio,
			end: startOfDay(new Date(actv.act_end_date || actv.early_end_date)),
			endIsAct: actv?.act_end_date !== undefined,
			id: this.getId(actv.task_id, updateIndex),
			isCritical: true,
			start: startOfDay(new Date(actv.act_start_date || actv.early_start_date)),
			startIsAct: actv?.act_start_date !== undefined,
			subtasks: [],
			tf: actv.total_float_hr_cnt ? actv.total_float_hr_cnt / 8 : 0,
			title: actv.task_code ? actv.task_code + ' - ' + actv.task_name : actv.task_name,
			isTopLevel: false,
		};
	}

	/**
	 * converts rawId to one that has the update index preceding it and a decimal (necessary to keep ids unique when
	 * using similar tasks/relationships across multiple updates. also gantt-chart works better with number ids)
	 * @param rawId
	 * @param updateId
	 */
	getId(rawId: number, updateId: number): number {
		return Number(updateId + '.' + rawId);
	}

	/**
	 * returns the task's true id
	 * @param id
	 */
	getTrueId(id: number): number {
		return Number(id.toString().split('.')[1]);
	}

	/**
	 * load updated data for current selections into the gantt chart
	 */
	async loadData(skipToggle = false): Promise<void> {
		const data: Task[] = [];
		for (const updateIndex of this.selectedUpdates) {
			const ganttData: Task[] = await this.generateGanttData(
				this.project.$currentProjectData.value.updateIds[updateIndex],
				updateIndex
			);
			const updateData: Task = {
				completionRatio: 0,
				end: this.currentUpdateLatestDate,
				id: updateIndex,
				isCritical: true,
				start: this.currentUpdateEarliestDate,
				subtasks: ganttData,
				tf: null,
				title: updateIndex === 0 ? 'Baseline' : 'Update ' + updateIndex,
				isTopLevel: true,
			};
			data.push(updateData);
		}
		this.data = data;
		/*let dependencies: GanttDependency[] = [];
		this.selectedUpdates.forEach((updateIndex: number) => {
			const updateDependencies: GanttDependency[] = this.allUpdatesDependencyData.get(updateIndex);
			if (updateDependencies?.length) {
				dependencies = [...dependencies, ...updateDependencies];
			}
		});
		this.dependencies = dependencies;*/
		this.loading = false;
		if (this.data.length && !skipToggle) {
			let i: number = 0;
			const hasDomLoaded = setInterval(() => {
				const critPathGantt: HTMLElement = document.getElementById('criticalPathComparisonGanttChart') as HTMLElement;
				if (critPathGantt !== undefined || i > 500) {
					this.updateSlotWidth();
					clearInterval(hasDomLoaded);
				}
				i++;
			}, 200);
		}
		if (skipToggle) {
			this.drawDataDateLine(this.dataDate);
		}
	}

	/**
	 * auto-fit gantt timeline pane width to container
	 */
	updateSlotWidth(skipLoadToggle = false): void {
		//timeout so the dom can finish updating to the expanded/collapsed width
		setTimeout(() => {
			const critPathGantt: HTMLElement = document.getElementById('criticalPathComparisonGanttChart') as HTMLElement;
			if (critPathGantt) {
				const containerEl: HTMLElement = critPathGantt.querySelector('.k-gantt-timeline') as HTMLElement;
				const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
				const numberOfSubdivisions: number = headerEl?.children.length ?? 0;
				const containerWidth: number = containerEl?.getBoundingClientRect().width;
				if (numberOfSubdivisions) {
					this.slotWidth = containerWidth / numberOfSubdivisions;
				}
				setTimeout(() => {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						setTimeout(() => {
							let minStart = this.data[0].start;
							this.data.forEach((bar: Task) => {
								if (isBefore(bar.start, minStart)) {
									minStart = bar.start;
								}
							});
							//show gantt vertical line borders on a yearly basis once zoom is small enough
							if (this.slotWidth <= 20 && minStart !== undefined) {
								const columns = document.getElementsByClassName('k-gantt-columns');
								const row = columns.item(0)?.children[1]?.children[0];
								const startNumber = 13 - getMonth(minStart);
								if (startNumber < row.children.length - 1) {
									for (let i = startNumber; i < row.children.length; i += 12) {
										row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
									}
								}
							}
							this.drawDataDateLine(this.dataDate);
						});
					}, 500);
				}, 200);
			}
		}, 500);
	}

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(dataItem): string {
		const updateIndex = Math.floor(dataItem.id);
		return this.selectedUpdates.indexOf(updateIndex) === 0 ? 'red' : 'green';
	}

	/**
	 * used by gantt to know if a row is selected
	 * @param dataItem
	 */
	public isSelected = (dataItem: Task): boolean => this.selectionState.has(dataItem.id);

	/**
	 * used by gantt to know if a row is expanded
	 * @param dataItem
	 */
	public isExpanded = (dataItem: Task): boolean => this.expandedKeys.includes(dataItem.id);

	/**
	 * handles arrow click
	 * @param dataItem
	 */
	arrowToggle({ dataItem }: ExpandEvent): void {
		this.toggleNode(dataItem.id);
	}

	/**
	 * update selected list based on user click
	 * @param dataItem
	 * @param sender
	 * @param originalEvent
	 */
	public toggleSelection({ dataItem, sender, originalEvent }: CellClickEvent | TaskClickEvent): void {
		// prevents context menu opening
		originalEvent.preventDefault();
		const originalSelectionExisted = this.selectionState.size > 0;
		if (dataItem.isTopLevel) {
			this.toggleNode(dataItem.id);
		} else {
			this.toggleAllUpdateSelect(dataItem.id, !this.isSelected(dataItem));
			this.selectionByClick.emit(originalSelectionExisted);
		}
		this.updateSelectionState(dataItem.isTopLevel);
		setTimeout(() => {
			this.drawDataDateLine(this.dataDate);
		}, 500);
	}

	/**
	 * update gantt and relevant data to reflect selection state change
	 * @param isTopLevel
	 */
	updateSelectionState(isTopLevel: boolean): void {
		if (this.selectionState.size > 0) {
			const copyData = structuredClone(this.data);
			copyData.forEach((updateTask: Task) => {
				updateTask.subtasks = updateTask.subtasks.filter((subtask) => this.isSelected(subtask));
			});
			this.data = copyData;
			this.toggleAllNodes(true);
		} else {
			this.loadData(true);
		}
	}

	/**
	 * select this activity on all updates or reset to original data
	 * @param id
	 * @param select
	 */
	toggleAllUpdateSelect(id: number, select: boolean): void {
		this.selectionState.clear();
		if (!select) {
			return;
		}
		const actv = this.allCriticalActivities.get(id);
		if (actv !== undefined) {
			const actvFromEachUpdateWithSameCode: number[] = this.criticalActivityIdsByTaskCode.get(actv?.task_code);
			for (const updateActvId of actvFromEachUpdateWithSameCode) {
				if (!Number.isNaN(updateActvId)) {
					this.selectionState.add(updateActvId);
				}
			}
		}
	}

	/**
	 * handles the treelist pane collapse/expand event
	 * @param ev
	 */
	treelistPaneToggled(ev): void {
		setTimeout(() => {
			// this.loading = true;
			// this.slotWidth = ev ? this.collapsedSlotWidth : this._slotWidth;
			this.treelistPaneCollapsed = ev;
			// this.gantt.updateView();
			//forces chart to rerender with updated slotWidth
			setTimeout(() => {
				// this.loading = false;
			});
		});
	}

	/**
	 * update selector selection change handler
	 * @param ev
	 * @param update
	 */
	updateChange(ev, update): void {
		this.loadData();
	}

	drawDataDateLine(dataDate: Date): void {
		const i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const critPathGantt: HTMLElement = document.getElementById('criticalPathComparisonGanttChart') as HTMLElement;
			const timeline = critPathGantt.querySelector('.k-gantt-timeline');
			if (timeline || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = timeline.querySelector('.k-grid-header');
				if (this.gantt) {
					const minStart: Date = this.gantt?.timelineSlots[0]?.start;
					const maxEnd: Date = this.gantt?.timelineSlots[this.gantt?.timelineSlots?.length - 1]?.end;
					const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
					const daysElapsed: number = differenceInCalendarDays(dataDate, minStart);
					const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
					const rightValue: number = header.clientWidth * rightPercent - 3;
					const containerForLine: HTMLElement = document
						.getElementsByClassName('crit-path-gantt-container')
						.item(0) as HTMLElement;
					const dataDateLine = containerForLine.querySelector('.data-date-gantt-line');
					if (dataDateLine) {
						dataDateLine.setAttribute('style', 'right:' + rightValue + 'px');
						if (this.isOverview && this.hasNotes) {
							const table = timeline.querySelector('.k-grid-content');
							const ganttHeight: number = table.getBoundingClientRect().height;
							dataDateLine.setAttribute('style', 'height:' + ganttHeight + 'px');
						}
					}
					//hides data date line if its past the gantt timeline
					const ganttTimeline = timeline.getBoundingClientRect();
					const dateLine = dataDateLine?.getBoundingClientRect();

					if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
						dataDateLine.setAttribute('style', 'display: none');
					}
				}
			}
		}, 200);
	}

	/**
	 * toggle opens/closes all nodes in gantt chart
	 * @param expand
	 */
	toggleAllNodes(expand: boolean): void {
		this.allNodesExpanded = expand;
		this.expandedKeys = [];
		if (expand) {
			this.data.forEach((update) => {
				this.expandedKeys.push(update.id);
			});
		}
	}

	/**
	 * toggle expansion of individual node
	 * @param id
	 */
	toggleNode(id): void {
		const indexOfKey = this.expandedKeys.findIndex((key) => key === id);
		if (indexOfKey !== -1) {
			this.expandedKeys.splice(indexOfKey, 1);
		} else {
			this.expandedKeys.push(id);
		}
	}

	toggleUpdateSelector(anchor: ElementRef | HTMLElement): void {
		const popupUpdateSelectorContainer: HTMLElement = document.getElementById('updateSelectorContainer');
		if (popupUpdateSelectorContainer !== null || anchor === null) {
			this.updateSelectorPopupRef.close();
			this.updateSelectorOpen = false;
		} else {
			if (anchor !== undefined) {
				this.updateSelectorPopupRef = this.popupService.open({
					anchor,
					content: this.updateSelectorTemplateRef,
					popupClass: ['columns-popup-class'],
					margin: { vertical: 10, horizontal: 0 },
				});
				this.updateSelectorOpen = true;
				const treeview: HTMLElement = document.getElementById('updatesTreeview');
				if (treeview) {
					const leaves: NodeListOf<Element> = treeview.querySelectorAll('.k-treeview-leaf');
					if (leaves) {
						let foundFirst: boolean = false;
						leaves.forEach((leaf: Element) => {
							if (!foundFirst && !leaf.classList.contains('k-disabled')) {
								foundFirst = true;
								leaf.scrollIntoView({ behavior: 'smooth', block: 'start' });
							}
						});
					}
				}
			}
		}
	}

	closeUpdates(): void {
		const popupUpdateSelectorContainer: HTMLElement = document.getElementById('updateSelectorContainer');
		if (popupUpdateSelectorContainer !== null) {
			this.updateSelectorPopupRef.close();
			this.updateSelectorOpen = false;
		}
	}

	resetUpdates(): void {
		const updateOptions = [];
		this.allUpdates.forEach((update: UpdateInterface, index: number) => {
			updateOptions.push({ index, name: index === 0 ? 'Baseline' : 'Update ' + index });
		});
		if (this.allUpdates.length >= 2) {
			this.selectedUpdates = [this.allUpdates.length - 2, this.allUpdates.length - 1];
			this.checkedUpdates = [(this.allUpdates.length - 2).toString(), (this.allUpdates.length - 1).toString()];
		} else {
			this.checkedUpdates = [];
		}
		this.updateOptions = updateOptions;
		this.closeUpdates();
	}

	public children = (dataItem): Observable<ColumnType[]> => of(dataItem?.children);
	public hasChildren = (dataItem): boolean => !!dataItem?.children;
	public isDisabled = (dataItem) =>
		this.checkedUpdates?.length === 2 && !this.checkedUpdates.includes(dataItem?.index.toString());

	set checkedUpdates(updates: string[]) {
		updates = updates.sort((a: string, b: string) => {
			const valA: number = Number(a);
			const valB: number = Number(b);
			return valA < valB ? -1 : valB < valA ? 1 : 0;
		});
		this._checkedUpdates = updates;
		const selectedUpdates = [];
		updates.forEach((update) => {
			selectedUpdates.push(Number(update));
		});
		this.selectedUpdates = selectedUpdates;
		this.loadData();
	}

	get checkedUpdates() {
		return this._checkedUpdates;
	}

	@HostListener('window:mousedown', ['$event'])
	mouseDown(event) {
		//closes update selector popup if clicked outside the popup
		if (this.updateOptions) {
			const updatesBtn: DOMRect = document.getElementById('updatesBtn')?.getBoundingClientRect();
			if (
				updatesBtn &&
				event.x >= updatesBtn.left &&
				event.x <= updatesBtn.right &&
				event.y >= updatesBtn.top &&
				event.y <= updatesBtn.bottom
			) {
				return;
			}
			const updateSelectorContainer: HTMLElement = document.getElementById('updateSelectorContainer');
			const updateSelectorPopupBounds: DOMRect =
				updateSelectorContainer?.parentElement?.parentElement?.getBoundingClientRect();
			if (
				updateSelectorPopupBounds &&
				!(
					event.x >= updateSelectorPopupBounds.left &&
					event.x <= updateSelectorPopupBounds.right &&
					event.y >= updateSelectorPopupBounds.top &&
					event.y <= updateSelectorPopupBounds.bottom
				)
			) {
				this.closeUpdates();
			}
		}
	}
}
