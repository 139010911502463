import { Component, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { UserService } from '../../../../services/common/user.service';
import { HierarchyResponse } from '../../../../models/company';
import { FilterItem } from '../../../portfolio/project-list/project-list/project-list.component';
import { FilterOperator } from '@progress/kendo-data-query';
import { BehaviorSubject, Subject } from 'rxjs';
import { ScheduleType } from '../../../../models/Project';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { XerActivity } from '@rhinoworks/xer-parse';
import { addDays, differenceInCalendarDays, isBefore, isValid } from 'date-fns';
import { TaskArrayInterface, UpdateInterface } from '../../../../models/Update/Task';
import { FloatConsumptionActv } from '../float-consumption/float-consumption-table-card/float-consumption-table-card.component';
import { CriticalActivityArrayInterface } from '../../../../models/ProjectReport/ProjectReport';
import { DelayTask } from '../../schedule-updates-list/schedule-delays/schedule-delays.component';
import { qcImpacts, scheduleType } from '../../../../util/projects';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';

@Component({
	selector: 'app-project-overview-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
	$clientName = new BehaviorSubject<string>('');
	$companyName = new BehaviorSubject<string>('');
	$pocName = new BehaviorSubject<string>('');
	$scheduleType = new BehaviorSubject<ScheduleType>('Baseline');
	$completionVariance = new BehaviorSubject<string>(null);
	$completionVarianceValue = new BehaviorSubject<number>(null);
	$completionVarianceLabel = new BehaviorSubject<string>('Contract ∆');
	$floatConsumption = new BehaviorSubject<string>(null);
	$floatDifference = new BehaviorSubject<number>(null);
	$floatIsLarger = new BehaviorSubject<boolean>(false);
	$drivingCriticalActivity = new BehaviorSubject<string>(null);
	$progressPerformance = new BehaviorSubject<string>(null);
	$progressPerformanceValue = new BehaviorSubject<number>(null);
	$qcIssue = new BehaviorSubject<string>(null);
	$criticalPathReliability = new BehaviorSubject<number>(null);
	$projectCode = new BehaviorSubject<string>(null);
	$projectLocation = new BehaviorSubject<string>(null);
	$finishMilestone = new BehaviorSubject<string>(null);
	$riskCalcMethod = new BehaviorSubject<string>(null);
	$totalActivities = new BehaviorSubject<number>(null);
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	allActivitiesActCodeKey = new Map<string, TaskArrayInterface>([]);

	constructor(
		public _projectDashboardService: ProjectDashboardService,
		public userService: UserService,
		public scheduleStorage: ScheduleStorageService
	) {}

	ngOnInit() {
		this.userService.clients.subscribe((clients) => {
			if (clients) {
				const id: string = this._projectDashboardService.$currentProjectData.value?.clientId;
				const clientName: string = id ? clients.get(id)?.name : '';
				this.$clientName.next(clientName);
			}
		});
		this.userService.companies.subscribe((companies: Map<string, HierarchyResponse>) => {
			if (companies) {
				this.updateCompanyName(companies);
			}
		});
		this.userService.members.subscribe((members) => {
			if (members) {
				const id: number = this._projectDashboardService.$currentProjectData.value?.pocId;
				const pocName: string = members.get(id)?.displayName || '';
				this.$pocName.next(pocName);
			}
		});
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			if (updates === undefined || updates?.length === 0) {
				return;
			}
			const i: number = 0;
			const allUpdatesInterval = setInterval(() => {
				if (
					this.scheduleStorage.$allUpdates.value?.length > 0 &&
					this._projectDashboardService.$currentProjectData.value !== undefined
				) {
					clearInterval(allUpdatesInterval);
					this.updateCompletionVariance(updates);
				}
				if (i > 500) {
					clearInterval(allUpdatesInterval);
				}
			}, 200);
		});
		this._projectDashboardService.activitiesByCode
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((tasksMap: Map<string, TaskArrayInterface>) => {
				this._projectDashboardService.previousActivitiesByCode
					.pipe(takeUntil(this._unsubscribeAll))
					.subscribe((prevTasksMap: Map<string, TaskArrayInterface>) => {
						const tasks: FloatConsumptionActv[] = Array.from(tasksMap.values());
						const prevTasks: FloatConsumptionActv[] = Array.from(prevTasksMap.values());
						this.updateFloatConsumption(tasks, prevTasks);
					});
			});
		this._projectDashboardService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (report === undefined) {
					return;
				}
				this.$criticalPathReliability.next(report?.riskPage?.criticalPathReliability?.overallScore);
				if (!report?.calculationFieldsHistorical) {
					return;
				}
				let j: number = 0;
				const allUpdatesInterval2 = setInterval(() => {
					if (this.scheduleStorage.$allUpdates.value?.length > 0) {
						clearInterval(allUpdatesInterval2);
						this.updateProgressPerformance(
							report as unknown as ProjectReportInterface,
							this.scheduleStorage.$allUpdates.value
						);
					}
					if (j > 50) {
						clearInterval(allUpdatesInterval2);
					}
					j++;
				}, 600);
				let k: number = 0;
				const expandedMetricsInterval = setInterval(() => {
					if (this.allActivitiesActCodeKey.size > 0) {
						clearInterval(expandedMetricsInterval);
						this.updateDrivingCriticalActivity(report as unknown as ProjectReportInterface);
					}
					if (k > 50) {
						clearInterval(expandedMetricsInterval);
					}
					k++;
				}, 600);
				this.updateQcImpacts(report as unknown as ProjectReportInterface);
			});
		this._projectDashboardService.activitiesByCode.pipe(takeUntil(this._unsubscribeAll)).subscribe((tasks) => {
			this.allActivitiesActCodeKey = tasks;
			this.$totalActivities.next(tasks.size);
		});
		this._projectDashboardService.$currentProjectData.subscribe((project) => {
			const schedType: ScheduleType = scheduleType(project);
			this.$scheduleType.next(schedType);
			this.$riskCalcMethod.next(
				project?.riskMetricsType === 'riskRegister'
					? 'Risk Register'
					: project?.riskMetricsType === 'coreRisk'
						? 'Core Risk'
						: project?.riskMetricsType === 'performanceFactor'
							? 'Performance Factor'
							: 'Default'
			);
			this.$projectLocation.next(project?.siteAddressReference);
			this.$projectCode.next(project?.projectCode);
		});
	}

	updateCompanyName(companies: Map<string, HierarchyResponse>): void {
		const flattenedCompanies = new Map<number, FilterItem>([]);
		const getNestedFilterItem = (company: HierarchyResponse): FilterItem => {
			const entry = company.entry;
			const filter = {
				text: entry.name,
				id: entry.id,
				value: {
					text: entry.name,
					field: 'company',
					operator: FilterOperator.EqualTo,
					value: entry.id,
				},
				children: company.children?.map((subCompany) => getNestedFilterItem(subCompany)),
			};
			flattenedCompanies.set(entry.id, filter);
			return filter;
		};
		//do not delete this line -RS
		const nestedCompanyList = Array.from(companies.values()).map((company) => getNestedFilterItem(company));
		const id: number = this._projectDashboardService.$currentProjectData.value?.company;
		const projectCompany = flattenedCompanies.get(id);
		this.$companyName.next(projectCompany?.text);
	}

	async updateCompletionVariance(updates: UpdateInterface[]): Promise<void> {
		let startMilestone: XerActivity;
		let finishMilestoneCode: string;
		const update = updates[updates.length - 1];
		const isBaseline: boolean = updates.length - 1 === 0 || update?.baseline;
		const finishMilestone = update?.finishMilestone;
		this.$finishMilestone.next(finishMilestone?.task_code + ' - ' + finishMilestone?.task_name);
		if (updates.length - 1 === 0 || !startMilestone) {
			startMilestone = update.startMilestone;
		}
		if (finishMilestone) {
			finishMilestoneCode = finishMilestone.task_code;
		}
		const contractCompletion: Date = new Date(
			finishMilestone?.act_end_date || finishMilestone?.cstr_date || finishMilestone?.early_end_date
		);
		const currentCompletion: Date = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
		const contractVariance: number =
			isValid(contractCompletion) && isValid(currentCompletion)
				? differenceInCalendarDays(contractCompletion, currentCompletion)
				: undefined;
		if (updates?.length > 1) {
			const prevTasks = await this.scheduleStorage.grabUpdateTable<XerActivity>(
				updates[updates.length - 2]._id,
				'TASK'
			);
			Promise.any(prevTasks).then(() => {
				const prevFinishMilestone =
					updates[updates.length - 2] === undefined
						? null
						: prevTasks?.find(
								(task) =>
									task.task_code === (updates[updates.length - 2].finishMilestone?.task_code || finishMilestoneCode)
							);
				const prevUpdateCurrentCompletion: Date =
					prevFinishMilestone === null
						? null
						: new Date(prevFinishMilestone?.act_end_date || prevFinishMilestone?.early_end_date);
				let previousVariance;
				if (!isBaseline && isValid(prevUpdateCurrentCompletion) && isValid(currentCompletion)) {
					previousVariance = differenceInCalendarDays(prevUpdateCurrentCompletion, currentCompletion);
				}
				const completionVariance: number = finishMilestone?.cstr_date == null ? previousVariance : contractVariance;
				this.$completionVariance.next(
					this.$scheduleType.value === 'Baseline'
						? 'N/A'
						: completionVariance < 0
							? completionVariance + ' CDs'
							: completionVariance === 0
								? '0 CDs'
								: '+' + completionVariance + ' CDs'
				);
				this.$completionVarianceValue.next(completionVariance);
				this.$completionVarianceLabel.next(finishMilestone?.cstr_date == null ? 'Update ∆' : 'Contract ∆');
			});
		}
	}

	updateFloatConsumption(tasks: XerActivity[], prevTasks: XerActivity[]): void {
		let totalFloatAllActivities: number = 0;
		let prevTotalFloat: number = 0;
		let prevTotalIncomplete: number = 0;
		let totalIncompleteActivities: number = 0;
		if (tasks.length > 0) {
			for (const task of tasks) {
				const totalFloat: number = task.total_float_hr_cnt;
				if (task.act_end_date || totalFloat === undefined) {
					continue;
				}
				totalFloatAllActivities += Math.round(task.total_float_hr_cnt / 8);
				totalIncompleteActivities += 1;
			}
			for (const task of prevTasks) {
				if (task.act_end_date || task.total_float_hr_cnt === undefined) {
					continue;
				}
				prevTotalFloat += Math.round(task.total_float_hr_cnt / 8);
				prevTotalIncomplete += 1;
			}
		}
		const currentFloat: number = Math.round(totalFloatAllActivities / (totalIncompleteActivities || 1));
		const prevFloat: number =
			this._projectDashboardService.$currentProjectData.value?.updateIds?.length > 1
				? Math.round(prevTotalFloat / (prevTotalIncomplete || 1))
				: null;
		const floatDifference: number =
			prevFloat === null ? null : Math.abs(Math.round((currentFloat / prevFloat - 1) * 100));
		this.$floatDifference.next(floatDifference);
		this.$floatIsLarger.next(currentFloat > prevFloat);
		this.$floatConsumption.next(
			floatDifference === null ||
				isNaN(floatDifference) ||
				floatDifference === Infinity ||
				floatDifference === -Infinity
				? null
				: currentFloat > prevFloat
					? '+' + floatDifference + '%'
					: (currentFloat < prevFloat ? '-' : '') + floatDifference + '%'
		);
	}

	updateProgressPerformance(report: ProjectReportInterface, updates: UpdateInterface[]): void {
		if (!report || !updates.length || report.updateIds.length !== updates.length || !report.progressDelayHistorical) {
			return;
		}
		const delays: DelayTask[] = [];
		let prevFinMileCode: string = null;
		let prevUpdateCurrentCompletion: Date = null;
		for (let i = 0; i < updates.length; i++) {
			const update: UpdateInterface = updates[i];
			const updateCurrentCompletion: Date = new Date(
				update.finishMilestone?.act_end_date || update.finishMilestone?.early_end_date
			);
			const isUpdate: boolean =
				prevUpdateCurrentCompletion &&
				(!update.finishMilestone || prevFinMileCode === update.finishMilestone?.task_code);
			const delay: DelayTask = {
				schedule: i === 0 ? 'Baseline' : `Update ${i}`,
				dataDate: new Date(update?.dataDate),
				currentCompletion: updateCurrentCompletion,
				updateDelta: isUpdate ? differenceInCalendarDays(prevUpdateCurrentCompletion, updateCurrentCompletion) : 0,
				progressImpact: 0,
				progressDate: updateCurrentCompletion,
				logicDate: updateCurrentCompletion,
				logicImpact: 0,
				updateIndex: i,
				isBaseline: !isUpdate,
			};
			if (isUpdate && report.progressDelayHistorical[i - 1]) {
				const progressDelay: number = report.progressDelayHistorical[i - 1];
				delay.progressImpact = differenceInCalendarDays(prevUpdateCurrentCompletion, new Date(progressDelay));
				delay.progressDate = new Date(progressDelay);
				delay.logicImpact = delay.updateDelta - delay.progressImpact;
				delay.logicDate = addDays(prevUpdateCurrentCompletion, -delay.logicImpact);
			}
			prevFinMileCode = update.finishMilestone.task_code || prevFinMileCode;
			prevUpdateCurrentCompletion = updateCurrentCompletion;
			delays.push(delay);
		}
		const progressPerformance: number = delays[delays.length - 1]?.progressImpact;
		this.$progressPerformance.next(
			progressPerformance > 0 ? '+' + progressPerformance + ' CDs' : progressPerformance + ' CDs'
		);
		this.$progressPerformanceValue.next(progressPerformance);
	}

	updateDrivingCriticalActivity(report: ProjectReportInterface): void {
		let criticalActivities: CriticalActivityArrayInterface[] = report?.criticalLookahead?.criticalActivityArray;
		let inProgressActivities: CriticalActivityArrayInterface[] = criticalActivities.filter((a) => {
			const fullActivity: TaskArrayInterface = this.allActivitiesActCodeKey.get(a.activityId);
			return fullActivity?.status_code === 'TK_Active';
		});
		if (inProgressActivities?.length > 1) {
			inProgressActivities = inProgressActivities.sort(
				(a: CriticalActivityArrayInterface, b: CriticalActivityArrayInterface) => {
					return isBefore(a.criticalFinish, b.criticalFinish)
						? -1
						: isBefore(b.criticalFinish, a.criticalFinish)
							? 1
							: 0;
				}
			);
		} else {
			criticalActivities = criticalActivities.sort(
				(a: CriticalActivityArrayInterface, b: CriticalActivityArrayInterface) => {
					return isBefore(a.criticalFinish, b.criticalFinish)
						? -1
						: isBefore(b.criticalFinish, a.criticalFinish)
							? 1
							: 0;
				}
			);
		}
		this.$drivingCriticalActivity.next(
			inProgressActivities?.length > 0
				? inProgressActivities[0]?.activityId + ' - ' + inProgressActivities[0]?.activityName
				: criticalActivities[0]?.activityId + ' - ' + criticalActivities[0]?.activityName
		);
	}

	updateQcImpacts(report: ProjectReportInterface): void {
		const impacts: {
			fsLagPenalty: number;
			missingPredSuccPenalty: number;
			oosPenalty: number;
			negLagPenalty: number;
			hardConstraintPenalty: number;
			relationshipDensityPenalty: number;
			durationPenalty: number;
			floatPenalty: number;
			ssffProblematicPenalty: number;
			openStartFinishPenalty: number;
			sfPenalty: number;
		} = qcImpacts(report);
		const impactMapping = {
			fsLagPenalty: 'FS with Lags',
			missingPredSuccPenalty: 'Missing Pred/Suc',
			oosPenalty: 'Out of Sequence',
			negLagPenalty: 'Negative Lags',
			hardConstraintPenalty: 'Hard Constraints',
			relationshipDensityPenalty: 'Logic Density',
			durationPenalty: 'High Duration Activities',
			floatPenalty: 'High Float Activities',
			ssffProblematicPenalty: 'SS/FF With Problematic Lag',
			openStartFinishPenalty: 'Open Starts/Finishes',
			sfPenalty: 'SF Relationships',
		};
		let largestImpact: number = 0;
		const largestImpactSet: Set<string> = new Set<string>('');
		for (const [key, val] of Object.entries(impacts)) {
			if (val > largestImpact) {
				largestImpact = val;
				largestImpactSet.clear();
				largestImpactSet.add(key);
			} else if (val === largestImpact) {
				largestImpactSet.add(key);
			}
		}
		const sortedImpactList: string[] = Array.from(largestImpactSet).sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));
		let qcIssueList: string = '';
		sortedImpactList.forEach((impact: string) => {
			if (qcIssueList !== '') {
				qcIssueList += ', ';
			}
			qcIssueList += impactMapping[impact];
		});
		this.$qcIssue.next(largestImpact === 0 ? null : qcIssueList);
	}
}
