import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { hasObjChanged } from '../../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { CriticalPathReliability } from '@rhinoworks/analytics-calculations/dist/models/report/ProjectReport';

@Component({
	selector: 'app-critical-path-reliability',
	templateUrl: './critical-path-reliability.component.html',
	styleUrls: ['./critical-path-reliability.component.scss'],
})
export class CriticalPathReliabilityComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	@Input() criticalPathReliability: CriticalPathReliability;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$overallScore = new Subject<number>();
	$consistencyScore = new Subject<number>();
	$baseScore = new Subject<number>();
	$updateScore = new Subject<number>();
	categories: string[] = [];
	chartData: SeriesDataSettings[] = [];
	public valuePlotBands: PlotBand[] = [
		{
			from: 0,
			to: 70,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 70,
			to: 85,
			color: '#4fc931',
			opacity: 0.2,
		},
		{
			from: 85,
			to: 100,
			color: '#0059FF',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: '% of Activities',
				visible: false,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
			min: 0,
			max: 100,
			majorGridLines: {
				visible: false,
			},
		},
	];
	hasNotes: boolean = false;

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				console.log('crit stuff', this.criticalPathReliability);
				this.updateValues(report, this.criticalPathReliability);
			});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 22)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * updates 4 square and chart values with the latest ones from the given project report
	 * @param report
	 * @param criticalPathReliability
	 */
	updateValues(report: CurrentProjectReport, criticalPathReliability?: CriticalPathReliability): void {
		let chartData = [];
		const categories = [];
		criticalPathReliability ||= report.riskPage.criticalPathReliability;
		const activityConsistency = criticalPathReliability.activityConsistencyScore;
		this.$consistencyScore.next(activityConsistency);

		const baseLogic = criticalPathReliability.baseLogicScore;
		this.$baseScore.next(baseLogic);

		const updateLogic = criticalPathReliability.updateLogicScore;
		this.$updateScore.next(updateLogic);

		const overallScore = criticalPathReliability.overallScore;
		this.$overallScore.next(overallScore);
		const x = criticalPathReliability?.overallScoreTrend;

		const overallScores: SeriesData[] = [];
		const consistencyScores: SeriesData[] = [];
		const baseLogicScores: SeriesData[] = [];
		const updateLogicScores: SeriesData[] = [];
		if (x) {
			for (let i = 0; i < x.length; i++) {
				const category =
					i === 0 ? 'Baseline' : 'Update ' + i + (report?.baselineUpdateId === report?.updateIds[i] ? ' ®' : '');
				overallScores.push({
					category,
					value: x[i].overallScore,
				});
				consistencyScores.push({
					category,
					value: x[i].activityConsistencyScore,
				});
				baseLogicScores.push({
					category,
					value: x[i].baseLogicScore,
				});
				updateLogicScores.push({
					category,
					value: x[i].updateLogicScore,
				});
				categories.push(category);
				chartData = [
					{
						data: baseLogicScores,
						type: 'line',
						name: 'Base Logic Score',
						visible: true,
						color: '#00000040',
						marker: 'square',
					},
					{
						data: updateLogicScores,
						type: 'line',
						name: 'Update Logic Score',
						visible: true,
						color: '#00000040',
						marker: 'triangle',
					},
					{
						data: consistencyScores,
						type: 'line',
						name: 'Activity Consistency Score',
						visible: true,
						color: '#00000040',
						marker: 'roundedRect',
					},
					{
						data: overallScores,
						type: 'line',
						name: 'Overall Score',
						visible: true,
						color: 'black',
						marker: 'circle',
					},
				];
			}
			if (hasObjChanged(categories, this.categories)) {
				this.categories = categories;
			}
			if (hasObjChanged(chartData, this.chartData)) {
				this.chartData = chartData;
			}
		}
	}
}
