import { Pipe, PipeTransform } from '@angular/core';
import { ProjectInterface } from '../../models/Project';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';

@Pipe({
	name: 'projectInfo',
})
export class ProjectInfoPipe implements PipeTransform {
	transform(project: ProjectInterface & { rawReport?: ProjectReportInterface }, property: string): unknown {
		if (!project || !project.rawReport) {
			return '';
		}
		switch (property) {
			case 'name': {
				return project.name.toString().trim();
			}
			case 'contractCompletion': {
				return project.rawReport?.projectTable?.contractCompletion
					? new Date(project.rawReport?.projectTable?.contractCompletion)
					: '';
			}
		}
		return '';
	}
}
