<div class="tab-page-container" id="focusTabComponent">
	<kendo-gridlayout
		[rows]="['47.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' style="background-color: #050c32;">
			<kendo-gridlayout
				[rows]="['47.4px']"
				[cols]="['226px', 'calc(100% - 231px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header" style="font-size: 23px;">
						Focus Areas
					</div>
<!--					<tooltip-open type='focus-areas'></tooltip-open>-->
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
					<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
						<div style='height: min-content; margin: auto 10px auto 0; display: flex; justify-content: flex-end; width: 100%; gap: 10px;'>
							<kendo-multiselecttree
								kendoMultiSelectTreeSummaryTag
								kendoMultiSelectTreeExpandable
								[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
								[(value)]="selectedActivityCodes"
								textField="name"
								valueField="id"
								childrenField='subCodes'
								[valuePrimitive]='false'
								[filterable]='true'
								(valueChange)='filterChanged($event);'
								[clearButton]='false'
								[expandOnFilter]="{expandMatches: true}"
								[itemDisabled]="itemDisabled"
								[placeholder]='"Activity Code Filter"'
								style='width: 300px; margin: auto 0; cursor: pointer;'
								[disabled]='!(projectService.$currentProjectData | async)?.updateIds.length'
								class="focusActvFilter"
							>
								<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
									<span>
										<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
										{{ codesTag }}
									</span>
								</ng-template>
							</kendo-multiselecttree>
							<button kendoButton title="Save Changes" themeColor="none" class='header-btn save-btn' style='width: 50px; margin: auto 0;' (click)="saveChanges()" [disabled]="loading || !hasChanges">
								Save
							</button>
						</div>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class="expansionpanel-container-focus-tab">
			@for (code of selectedActivityCodesForPanel; track code; let i = $index) {
				<kendo-expansionpanel
					[expanded]="code.expanded || isScreenshotMode"
					(action)="onAction(code, i)"
					[disabled]="code.progressScore === undefined || code.progressScore === null"
					#expansionPanels
					class="focus-expansion"
				>
					<ng-template kendoExpansionPanelTitleDirective>
						<span class="expansionpanel-title-container">
							<span class="panel-name" [style.font-size]="isAllCaps(code.name) ? '18px' : '20px'">
								<span>
									{{code.name}}
								</span>
								@if (code.disabled) {
									<span style="font-size: 12px; color: #8e8e8e; margin: auto 0;">
										(No remaining Activities)
									</span>
								}
							</span>
							<span class="scores-container">
								<span style="display: flex; flex-direction: column; justify-content: center;" kendoTooltip>
									<img src="/assets/icons/newIcons/checkered-flag.svg" [title]="'Finish Milestone'" [height]="20" width="20" style="margin-top: -2px;"/>
								</span>
								<div (click)="test($event)" style="height: 54.11px; width: 250px; background: white; border-radius: 4px;">
									<kendo-dropdowntree
										placeholder="Driving Path Switch Milestone"
										style="background-color: transparent !important; height: 100%; border: none;"
										[kendoDropDownTreeHierarchyBinding]="groupedActivities[code.codeName + '%--//--%' + code.shortName]"
										childrenField="children"
										[textField]="'entry.name'"
										[valueField]="'entry.id'"
										[filterable]='true'
										[value]='selectedFinishMilestones[code.codeName + "%--//--%" + code.shortName]'
										[loading]='scheduleService.isLoading || loading'
										kendoDropDownTreeExpandable
										[expandedKeys]="expandedNodes"
										[expandBy]="'entry.id'"
										[expandOnFilter]="{expandMatches: true}"
										[isNodeExpanded]="isNodeExpanded"
										(nodeExpand)="handleExpand($event)"
										(nodeCollapse)="handleCollapse($event)"
										(valueChange)='treeValChange($event, dropDownTree, code.codeName + "%--//--%" + code.shortName)'
										[popupSettings]="{popupClass: 'drivingPathDropdownTree'}"
										[clearButton]="false"
										[itemDisabled]="itemDisabledFinMileDropdown"
										#dropDownTree
										class="drivingMileTree"
									>
										<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
											<span class="milestone-item">

												<span [style.margin-left.px]="dataItem?.children?.length ? 0 : 22" style="padding: 0 2px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 330px;">
													{{ dataItem?.entry?.name }}
												</span>
											</span>
										</ng-template>
									</kendo-dropdowntree>
								</div>
								<span class="panel-score">
									<span class="score-label">
										Progress:
									</span>
									@if (code.disabled || code?.progressScore === undefined) {
										<span class="score-val" *ngIf="code.disabled">-</span>
										<kendo-loader type="pulsing" *ngIf="!code.disabled" themeColor="info" style="align-self: center; margin-left: auto; margin-right: auto"></kendo-loader>
									} @else {
										<span class="score-val" [style.color]="(code.disabled || code?.progressScore === null) ? 'rgba(162, 162, 162, .9)' : code?.progressScore >= 95 ? 'rgba(0, 89, 255, .9)' : code?.progressScore >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
											{{code?.progressScore | number : '1.0-0'}}%
										</span>
									}
								</span>
								<span class="panel-score">
									<span class="score-label">
										QC:
									</span>
									@if (code.disabled || code?.qcScore === undefined) {
										<span class="score-val" *ngIf="code.disabled">-</span>
										<kendo-loader type="pulsing" *ngIf="!code.disabled" themeColor="info" style="align-self: center; margin-left: auto; margin-right: auto"></kendo-loader>
									} @else {
										<span class="score-val" [style.color]="(code.disabled || code?.qcScore === null) ? 'rgba(162, 162, 162, .9)' : code?.qcScore >= 95 ? 'rgba(0, 89, 255, .9)' : code?.qcScore >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
											{{code?.qcScore | number : '1.0-0'}}%
										</span>
									}
								</span>
								<span class="panel-score">
									<span class="score-label">
										Risk:
									</span>
									@if (code.disabled || code?.riskScore === undefined) {
										<span class="score-val" *ngIf="code.disabled">-</span>
										<kendo-loader type="pulsing" *ngIf="!code.disabled" themeColor="info" style="align-self: center; margin-left: auto; margin-right: auto"></kendo-loader>
									} @else {
										<span class="score-val" [style.color]="(code.disabled || code?.riskScore === null) ? 'rgba(162, 162, 162, .9)' : code?.riskScore >= 85 ? 'rgba(0, 89, 255, .9)' : code?.riskScore >= 70 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
											{{code?.riskScore | number : '1.0-0'}}%
										</span>
									}
								</span>
								<!--<span class="panel-score" style="width: 177.67px;" [style.background-color]="(code.disabled || code?.actvCompletion === null) ? 'rgba(162, 162, 162, .9)' : code?.actvCompletion >= 95 ? 'rgba(0, 89, 255, .9)' : code?.actvCompletion >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									<span>
										Activity Completion:
									</span>
									@if (code.disabled || code?.actvCompletion === null) {
										<span class="score-val">-</span>
									} @else {
										<span class="score-val">
											{{code?.actvCompletion | number : '1.0-0'}}%
										</span>
									}
								</span>
								<span class="panel-score" style="width: 183.33px;" [style.background-color]="(code.disabled || code?.floatConsumption === '') ? 'rgba(162, 162, 162, .9)' : code?.floatConsumptionIsLarger ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									<span>
										Float Consumption:
									</span>
									@if (code.disabled || code?.floatConsumption === '') {
										<span class="score-val">-</span>
									} @else {
										<span class="score-val">
											{{code?.floatConsumption}}
										</span>
									}
								</span>-->
							</span>
						</span>
					</ng-template>
					<div class="expansionpanel-content-container">
						@if (expansionPanels.expanded && !(code.disabled || code?.progressScore === undefined)) {
							<app-activity-completion [hideTrending]="true" [hideNotes]="true" [defaultSelectedActivityCodes]="openPanel" [isFocus]="true" [finishMilestone]='selectedFinishMilestones[code.codeName + "%--//--%" + code.shortName]'></app-activity-completion>
							<app-float-consumption [hideNotes]="true" [focusTabSelectedActvCode]="[openPanel]" [focusTabDisabledCode]="code.disabled" [isFocus]="true"></app-float-consumption>
							<app-driving-path [actvCodeKey]="code.codeName + '%--//--%' + code.shortName" [hideNotes]="true" [isFocus]="true" [defaultFinishMilestoneCode]="lastActivityCode" [showOverlay]="code.disabled" [userSelectedFinMile]="selectedFinishMilestones[code.codeName + '%--//--%' + code.shortName]"></app-driving-path>
							<app-schedule-analysis *ngIf="!projectService.$currentProjectData.value?.qualityPreferences?.useDCMA" [customReport]="reportByCode.get(code.codeName + '%--//--%' + code.shortName)" [focusCodeKey]="code.codeName + '%--//--%' + code.shortName" [isFocus]="true"/>
							<app-dcma-assessment *ngIf="projectService.$currentProjectData.value?.qualityPreferences?.useDCMA" [focusReport]="reportByCode.get(code.codeName + '%--//--%' + code.shortName)[0]" [qualityPreferences]="projectService.$currentProjectData.value.qualityPreferences" [isFocus]="true" [focusCode]="openPanel"></app-dcma-assessment>
							<app-index-trending [beiHistorical]="projectService.$currentProjectReport.value.dcma.historicalByCode.bei[code.codeName + '%--//--%' + code.shortName]" [cpliHistorical]="projectService.$currentProjectReport.value.dcma.historicalByCode.cpli[code.codeName + '%--//--%' + code.shortName]"></app-index-trending>
							<app-critical-path-reliability [criticalPathReliability]="projectService.$currentProjectReport.value.riskPage.criticalPathReliabilityByCode[selectedFinishMilestonesEncoded[code.codeName + '%--//--%' + code.shortName]]"></app-critical-path-reliability>
						}
					</div>
				</kendo-expansionpanel>
			}
			@if (selectedActivityCodesForPanel?.length === 0) {
				<div class="warning-div" style="background-color: white; height: 150px;">
					Select codes from the dropdown above.
				</div>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
