import { Component, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
@Component({
	selector: 'app-debug',
	templateUrl: './debug.component.html',
	styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit {
	public loading = false;
	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		console.log(this.projectService.$currentProjectData.value);
		console.log(this.projectService.$currentProjectReport.value);
		console.log(this.scheduleService.$allUpdates.value);
	}

	async downloadDebug() {
		this.loading = true;
		const zip = new JSZip();
		const updates = this.scheduleService.$allUpdates.value;
		zip.file('project.json', JSON.stringify(this.projectService.$currentProjectData.value, null, 2));
		zip.file(
			'report.json',
			JSON.stringify(
				{
					...this.projectService.$currentProjectReport.value,
					project: this.projectService.$currentProjectPageId.value,
				},
				null,
				2
			)
		);
		zip.file('expanded-metrics.json', JSON.stringify(this.projectService.$expandedMetrics.value, null, 2));
		for (let i = 0; i < updates.length; i++) {
			const update = updates[i];
			const folderName = `Update ${i}`;
			const folder = zip.folder(folderName);

			folder.file(`Update ${i}.json`, JSON.stringify(update, null, 2));

			const xerData =
				this.scheduleService.cachedXerData.get(update._id) ||
				(await this.scheduleService.grabUpdateXerData(update._id, true));
			folder.file(`Update ${i}.xer`, this.convert2DArrayToCSV(xerData));
		}

		// Generate the zip file and save it
		zip.generateAsync({ type: 'blob' }).then((blob) => {
			saveAs(blob, `${this.projectService.$currentProjectData.value.name}.zip`);
		});
		this.loading = false;
	}

	private convert2DArrayToCSV(data: Array<Array<string | number>>): string {
		return data.map((row) => row.join('\t')).join('\n');
	}
}
