import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { RestService } from '../../../services/common/rest.service';
import { BehaviorSubject } from 'rxjs';
import { FileUploadService } from '../../../services/common/fileUpload.service';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { Location } from '@angular/common';
import { ProjectInterface } from '../../../models/Project';
import { UserService } from '../../../services/common/user.service';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { RiskRegister } from '../../../models/risk';
import { RiskRegisterInfo } from './risk-register/risk-register.component';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';

@Component({
	selector: 'app-risk-page',
	templateUrl: './risk-page.component.html',
	styleUrls: ['./risk-page.component.scss'],
})
export class RiskPageComponent implements OnInit {
	@Input() $projectData = new BehaviorSubject<any>({});
	@Input() user: any;
	@Input() $currentTab = new BehaviorSubject<string>('overview');
	@Input() currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	@Output() emitTab = new EventEmitter<any>();
	@Output() dialogStateChange = new EventEmitter<boolean>();

	@Input() visualizer: boolean = false;

	armContentOpen = false; //arm = additional risk metrics
	hasSubmittedRequest = false;
	history = history;
	riskMetricsType = 'default';
	riskMetricsOptions = [
		{
			value: 'performanceFactor',
			viewValue: 'Performance Factor',
			defaultChecked: false,
		},
		{
			value: 'riskRegister',
			viewValue: 'Risk Register',
			defaultChecked: false,
		},
	];
	activeRiskRegisters: Array<RiskRegister & RiskRegisterInfo> = [];

	constructor(
		private restService: RestService,
		public fileUploadService: FileUploadService,
		public _projectDashboardService: ProjectDashboardService,
		private location: Location,
		public userService: UserService,
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService
	) {}

	ngOnInit() {
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		this._projectDashboardService.$currentProjectData.subscribe((projectData) => {
			const projectInfo = projectData as ProjectInterface;
			if (!projectInfo) {
				return;
			}
			this.activeRiskRegisters = JSON.parse(JSON.stringify(projectInfo.riskMitigation || [])).filter(
				(risk) => risk.isDraft === false
			);
		});
	}

	backP() {
		this.location.back();
		// this.$currentTab.next('project');
		// this.emitTab.emit(this.$currentTab);
	}

	email() {
		this.hasSubmittedRequest = true;
		const body = {
			project: this._projectDashboardService.$currentProjectReport?.value?.project,
			client: this.user,
		};
		this.restService.post('project/enableRisk', body).subscribe(
			(success) => {},
			(error) => {
				console.error('error', error);
			}
		);
	}

	/**
	 * update riskMetricsType from child component
	 * @param newRiskMetricsType
	 */
	updateRiskMetricsType(newRiskMetricsType: string) {
		this.riskMetricsType = newRiskMetricsType;
	}

	/**
	 * enables risk on this project and recalculates it
	 */
	enableRisk() {
		const riskEnabled = this.riskMetricsType === 'performanceFactor' || this.riskMetricsType === 'riskRegister';
		this.fileUploadService.updateRisk(
			riskEnabled,
			this.riskMetricsType,
			this._projectDashboardService.$currentProjectReport.value?.project?._id ||
				this._projectDashboardService.$currentProjectReport.value?.project
		);
		this._projectDashboardService.calculateReport(
			this._projectDashboardService.$currentProjectReport.value?.project?._id
		);
	}

	/**
	 * toggles additional risk metrics card content
	 */
	toggleARMContent() {
		this.armContentOpen = !this.armContentOpen;
	}

	/**
	 * passes new dialogStateChange value along to project component
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogStateChange.emit(newVal);
	}

	/**
	 * open new project form as a baseline upload for saas risk projects
	 */
	openSaasRiskBaselineForm() {
		this.navBarStorage.showingBaselineProject = true;
		this.navBarStorage.newProjectFormMaxHeight = Math.min(window.innerHeight - 132, 750);
		this.navBarStorage.showingNewProject = true;
		this.analyticsService.baselineOpen.next(true);
	}
}
