import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { SeriesLabels } from '@progress/kendo-angular-charts';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-risk-summary-trend',
	templateUrl: './risk-summary-trend.component.html',
	styleUrls: ['./risk-summary-trend.component.scss'],
})
export class RiskSummaryTrendComponent implements OnInit, OnChanges {
	@Input() categories;
	@Input() data;
	@Input() title;
	@Input() chartType;
	@Input() isFromPortfolio: boolean = false;
	// @ViewChild('container', { read: ViewContainerRef, static: true })
	// public popupContainer: ViewContainerRef;
	progressMessageShowing = false;
	categoryStep = new BehaviorSubject<number>(0);
	chartTitle = '';
	public seriesLabels: SeriesLabels = {
		visible: this.isFromPortfolio,
		padding: 3,
		font: '8px Muli, Helvetica Neue, Arial, sans-serif',
		format: '{0}%',
		background: 'none',
	};
	public seriesColors: string[] = ['#df5353', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be'];
	range: number = 0;
	majorUnit: number = 20;

	constructor() {}

	ngOnInit(): void {
		let min = null;
		let max = null;
		this.data.forEach((point) => {
			if (min === null || point < min) {
				min = point;
			}
			if (max === null || point > max) {
				max = point;
			}
		});
		this.range = max - min;
		this.majorUnit = this.isFromPortfolio ? (this.range / 3 < 1 ? 1 : Math.ceil(this.range / 3)) : 20;
	}

	ngOnChanges(changes: SimpleChanges) {
		const dataLength = changes?.data?.currentValue.length;
		this.progressMessageShowing = changes?.title?.currentValue === 'Progress Score' && !dataLength;
		if (dataLength > 6) {
			const mod = Math.ceil(dataLength / 4) - 1;
			this.categoryStep.next(mod);
		}
		this.chartTitle = this.title === undefined ? 'Risk Score' : this.title;
	}

	protected readonly Math = Math;
}
