<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<div class='card-header-container' style='padding: 0.7em 11px' *ngIf='visualizer' [class.overviewHeader]="isOverview">
	<div class="card-header">
		DCMA 14-Point Assessment
	</div>
</div>
<div class='card' [style]='isFocus ? "" : "page-break-inside: avoid;"' id="dcmaComponent">
	<kendo-gridlayout
		[rows]="visualizer ? ['672.56px'] : [isFocus ? '32px' : '42.4px', assessments?.length ? '562.56px' : '150px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' *ngIf='!visualizer' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="[isFocus ? '32px' : '42.4px']"
				[cols]="['400px', 'calc(100% - 415px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header" [class.focusTabFontSizing]="isFocus">
						DCMA 14-Point Assessment <span style="font-size: 9px; position: relative; top: -1px;">(INCOMPLETE ACTIVITIES)</span>
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "dcma"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='project.goTo(11)' style="background-color: #ffb500;"></button>
						}
						@if (!isFocus) {
							<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='project.openNotesWindow({id: 11, name: "DCMA 14-Point Assessment"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
						}
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="!assessments.length && !visualizer">
			<div class='warning-div'>
				<p style="margin-top: 14px;">
					<ion-icon name="alert-circle-outline" size="large"></ion-icon>
					<br><strong>Please Update your Project.</strong>&nbsp;<span>In order to use this feature in full, this project must have a new update added to it or the current update recalculated.</span>
				</p>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='visualizer ? 1 : 2' [col]='1' *ngIf="assessments.length">
			<kendo-gridlayout
				[rows]="visualizer ? ['672.56px'] : ['562.56px']"
				[cols]="visualizer ? ['calc(100% - 5px)'] : ['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='btn-grid' style='padding: 5px 0 5px 5px;'>
					<div *ngFor='let assessment of assessments;let i = index'
							 [class.selected-button]='assessment.key === (selectedTest | async)'
							 (click)='!qualitySettings.get(assessment.key).enabled ? null : !isLoading ? selectedTest.next(assessment.key) : ngOnInit(assessment.key)'
							 [class.disabled-btn-dcma]="!qualitySettings.get(assessment.key).enabled"
					>
						<div>{{assessment.title}}</div>
						@if (qualitySettings.get(assessment.key).enabled) {
							<div class="score-text">
								{{ (assessment.value !== true && assessment.value > 1 ? ('Score: ' + (assessment.value | number: '1.0-0') + '%') : '') }}
								<span *ngIf="(assessment.value !== true && assessment.value > 1 ? ('Score: ' + (assessment.value | number: '1.0-0') + '%') : '') === ''">
							{{ (assessment.description.split(' ')[0] === 'Schedule' ? (assessment.value ? 'Pass' : 'Fail') : ((assessment.value | DCMAPct: [qualitySettings.get(assessment.key).min, qualitySettings.get(assessment.key).max]) + '%')) }}
									<span *ngIf='qualitySettings.get(assessment.key).min !== null'>of min {{(qualitySettings.get(assessment.key).min) + '%'}}</span>
							<span *ngIf='qualitySettings.get(assessment.key).max !== null'>of max {{(qualitySettings.get(assessment.key).max) + '%'}}</span>
							</span>
							</div>
						}
						<div class='pass-icon'>
							<mat-icon class='icon'
												[class.grey-icon]='!qualitySettings.get(assessment.key)?.enabled'
												[class.green-icon]='qualitySettings.get(assessment.key)?.enabled && assessment.passes'
												[class.red-icon]='qualitySettings.get(assessment.key)?.enabled && !assessment.passes'
							>
								{{!qualitySettings.get(assessment.key)?.enabled ? '-' : assessment.passes ? 'check' : 'priority_high'}}
							</mat-icon>
						</div>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='task-table' *ngIf='!visualizer'>
					<div style="display: table; width: calc(100% - 5px); height: 95.5px; margin: 5px 0;">
						<div *ngIf='(selectedTest | async) && assessments[selectedIndex]' style='height: 106.51px; width: 100%; text-align: center; background: #EDEDED; border-radius: 4px; display: flex; flex-direction: column; justify-content: center; vertical-align: middle;'>
							<div style="font-size: 24px; font-weight: bold; padding-bottom: 12px;">
								{{assessments[selectedIndex].title}}
							</div>
							<div style='width: 100%; text-align: center; font-size: 16px; font-weight: bold;' [class.red-icon]='!assessments[selectedIndex]?.passes'>

								@if (assessments[selectedIndex].key !== 'overall') {
									{{ (assessments[selectedIndex].value !== true && assessments[selectedIndex].value > 1 ? ('Current: ' + (assessments[selectedIndex].value | number: '1.0-0') + '%') : '') }}
								}
								<span *ngIf="(assessments[selectedIndex].value !== true && assessments[selectedIndex].value > 1 ? ('Current: ' + (assessments[selectedIndex].value | number: '1.0-0') + '%') : '') === ''">
									<span *ngIf="assessments[selectedIndex].denominator">{{assessments[selectedIndex].numerator}} / {{assessments[selectedIndex].denominator}}</span>
									({{ (assessments[selectedIndex].description.split(' ')[0] === 'Schedule' ? (assessments[selectedIndex].title === 'Critical Path Test' ? 'Schedule Has Critical Path' : 'Pass') : ((assessments[selectedIndex].value | DCMAPct: [qualitySettings.get(assessments[selectedIndex].key).min, qualitySettings.get(assessments[selectedIndex].key).max]) + '%')) }}
									<span *ngIf='qualitySettings.get(assessments[selectedIndex].key).min !== null'>of min {{(qualitySettings.get(assessments[selectedIndex].key).min) + '%'}}</span>
									<span *ngIf='qualitySettings.get(assessments[selectedIndex].key).max !== null'>of max {{(qualitySettings.get(assessments[selectedIndex].key).max) + '%'}}</span>)
								</span>
								@if (assessments[selectedIndex].key !== 'overall') {
									<br>
								}

								{{ assessments[selectedIndex]?.description }}
								<span *ngIf='qualitySettings.get(assessments[selectedIndex].key).min !== null'>(minimum {{(qualitySettings.get(assessments[selectedIndex].key).min) + '%'}})</span>
								<span *ngIf='qualitySettings.get(assessments[selectedIndex].key).max !== null'>(maximum {{(qualitySettings.get(assessments[selectedIndex].key).max) + '%'}})</span>

							</div>
						</div>
					</div>

					<div *ngIf='(selectedTest | async) === "overall"' style="height: 441px;">
						<app-chart
							[title]='"Overall Score Trend"'
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='false'
							[valueAxis]='valueAxisItemSettings'
							[isDCMAChart]="true"
						></app-chart>
					</div>

					<div *ngIf='(selectedTest | async) && assessments[selectedIndex] && assessments[selectedIndex]?.elements?.length' style='margin-right: 5px'>
						<kendo-grid
							#grid
							id='grid'
							[data]="gridView"
							[kendoGridBinding]="gridData"
							[height]="441"
							[rowHeight]='40'
							[loading]='isLoading'
							[size]='"small"'
							[sortable]='true'
							[sort]="sort"
							(sortChange)="sortChange($event)"
							(pageChange)="pageChange($event)"
							[skip]="skip"
							[pageSize]="pageSize"
							scrollable="virtual"
							[navigable]="false"
							class='sched-grid'
							(dataStateChange)="onDataStateChange()"
						>
							<kendo-grid-column
								*ngFor='let column of selectedColumns'
								[field]="column.field"
								[title]="column.title"
								[format]="column?.format"
								[width]='column.width'
								headerClass="header-class"
							>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "string"'>
									{{ dataItem[column.field] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "img"'>
									<img [src]="column.id === 13 ? '/assets/ui/gantt/' + dataItem.pred_type + '.png' : '/assets/ui/activity_type/' + dataItem.task_type + '.png'" style='vertical-align: -1.5px'/>
									{{ column.id === 0 ? dataItem.task_code : column.id === 17 ? taskTypeDictionary[dataItem.task_type] : linkTypeDictionary[dataItem.pred_type] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "date"'>
									{{ (dataItem[column.field] | date: 'MM/dd/yyyy') || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "actIfPossible"'>
									{{ dataItem[column.field] ? ((dataItem[column.field] | date: 'MM/dd/yyyy') + ' A') : dataItem[column.otherField] ? (dataItem[column.otherField] | date: 'MM/dd/yyyy') : 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "hours"'>
									{{ dataItem[column.field] | floor }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "calendar"'>
									{{ dataItem?.calendar || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "status"'>
									{{ tableDataType === 'Relationship' ? taskStatusDictionary[allTasksById.get(+dataItem.task_id)?.status_code || allTasksById.get(+dataItem.pred_task_id)?.status_code] : taskStatusDictionary[dataItem.status_code] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "missing"'>
									Missing {{(expandedMetrics | async)?.missingSuccessors?.includes(dataItem) ? 'Successor' : 'Predecessor'}}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "constraint"'>
									{{ constraintDict[dataItem[column.field]] || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "lag"'>
									{{ dataItem.lagHrs | floor }}
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotes) {
			<app-overview-notes [componentId]="11"></app-overview-notes>
		}
	</kendo-gridlayout>
</div>
