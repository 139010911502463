<div id='top-row-container'>
  <div class="header-top-container">
    <ion-grid *ngIf="navBarStorage.summaryOffScreen && (navBarStorage.selectedTab !== 'schedules' || navBarStorage.selectedTabSchedules === 'analysis')" [@slideInOutAnimation]="navBarStorage.summaryOffScreen" style="z-index: -1;">
      <ion-row style="z-index: 1">
        <ion-col size="12" style="background: white; border: 3px solid #011689; border-top: none; border-radius: 0 0 4px 4px; z-index: -1; padding: 0;">
            <ion-grid>
              <ion-row class="subtext-popover-titles">
								<ion-col class="fixed-width">Data Date</ion-col>
								<ion-col class="fixed-width">Contract Completion</ion-col>
								<ion-col class="fixed-width">Current Completion</ion-col>
								<ion-col class="fixed-width">Previous Completion</ion-col>
								<ion-col class="fixed-width">Project Score</ion-col>
								<ion-col class="fixed-width">Progress Score</ion-col>
								<ion-col class="fixed-width">QC Score</ion-col>
								<ion-col class="fixed-width">Risk Score</ion-col>
								<ion-col class="fixed-width">Activity Completion</ion-col>
              </ion-row>
              <ion-row class="subtext-popover-items">
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded">{{ dataDate | async }}</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded">{{ contractCompletion | async }}</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded">{{ currentCompletion | async }}</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded">{{ previousCompletion | async }}</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span                   [ngClass]="{
                              'good-score': ($projectScore | async) >= 85,
                              'moderate-score': ($projectScore | async) >= 70 && ($projectScore | async) < 85,
                              'bad-score': ($projectScore | async) < 70
                              }" *ngIf="_projectDashboardService.fullyLoaded">{{$projectScore | async | number: '1.0-0'}}%</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded && (_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length > 1" [ngClass]="{
                              'good-score': ($progressScore | async) >= 95,
                              'moderate-score': ($progressScore | async) >= 80 && ($progressScore | async) < 95,
                              'bad-score': ($progressScore | async) < 80
                              }">{{$progressScore | async | number: '1.0-0'}}%</span>
									<span *ngIf="_projectDashboardService.fullyLoaded && (_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length < 2" class='progress-req' style='overflow: visible; white-space: nowrap; margin: 0 auto; height: 100%;'>
										Requires at least 1 update
									</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded"                   [ngClass]="{
                              'good-score': ($qualityControlScore | async) >= 95,
                              'moderate-score': ($qualityControlScore | async) >= 80 && ($qualityControlScore | async) < 95,
                              'bad-score': ($qualityControlScore | async) < 80
                              }">{{$qualityControlScore | async | number: '1.0-0'}}%</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
								<ion-col class="fixed-width"
									*ngIf="$isRiskEnabled"
								>
                  <span *ngIf="_projectDashboardService.fullyLoaded"                   [ngClass]="{
                              'good-score': ($riskScore | async) >= 85,
                              'moderate-score': ($riskScore | async) >= 70 && ($riskScore | async) < 85,
                              'bad-score': ($riskScore | async) < 70
                              }">{{$riskScore | async | number: '1.0-0'}}%</span>
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
								</ion-col>
                <ion-col class="fixed-width">
                  <span *ngIf="_projectDashboardService.fullyLoaded"                   [ngClass]="{
                              'good-score': ($completionRatioScore | async) >= 85,
                              'moderate-score': ($completionRatioScore | async) >= 70 && ($completionRatioScore | async) < 85,
                              'bad-score': ($completionRatioScore | async) < 70
                              }">{{$completionRatioScore | async | number: '1.0-0'}}%</span>
                  <ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;" *ngIf="!_projectDashboardService.fullyLoaded"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
	<ion-grid #summary id="top-row-summary">
		<kendo-gridlayout
			[rows]="['200px']"
			[cols]="['200px', '345px', '120px', '180px', 'auto']"
			[gap]="{ rows: 0, cols: 5 }"
			class='grid'
			*ngIf="_projectDashboardService.$currentProjectReport.value?.project?.riskMetricsType !== 'riskRegister' || navBarStorage.$tabPointer.value !== 'risk'"
			style='padding-bottom: 5px;'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item img-container-center'>
				<div class="image-container"(mouseover)="mouseoverImage()" (mouseout)="mouseoutImage()">
					@if (imageShowing) {
						<div [style.background-image]="'url(' + _projectDashboardService.currentProjectImg + ')'" style="width: 200px; height: 200px; border-radius: 4px; background-position: center center; background-size: contain; background-repeat: no-repeat; object-fit: contain;"></div>
						<button
							kendoButton
							(click)="openRemoveImage()"
							title="Remove Project Image"
							[ngClass]='{"remove-image-icon": imageShowing}'
							[class.opacity0]='imageShowing && !isHoveringOverImage'
						>
							<kendo-svg-icon [icon]="icons.imageRemove" class="icon-set"></kendo-svg-icon>
						</button>
					}
					<button
						kendoButton
						(click)="openImageUpload()"
						title="Edit Project Image"
						[ngClass]='{"edit-image-icon": imageShowing, "edit-image-icon-unset": !imageShowing}'
						[class.opacity0]='imageShowing && !isHoveringOverImage'
					>
						<kendo-svg-icon [icon]="icons.imageAdd" [ngClass]='{"icon-set": imageShowing, "icon-unset": !imageShowing}'></kendo-svg-icon>
					</button>
					<div *ngIf="!imageShowing" style='width: 100%; height: 100%; position: absolute; top: 0;' (click)="openImageUpload()"></div>
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='1' [col]='2'>
				<kendo-gridlayout
					[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
					[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
					[gap]="{ rows: 5, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item four-square-container' style='position: relative;'>
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="10"
								 height="10"
								 viewBox="0 0 10 10"
								 kendoTooltip
								 position='left'
								 style='position: absolute; top: 0; left: 0;'
								 *ngIf='isMissingUpdate === true'
						>
							<path d="M 0 0 L 0 10 L 10 0 z" fill="#ff8c00" title='Missing Update'/>
						</svg>
<!--						<div style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;' [class.missing-update]='isMissingUpdate === true'></div>-->
						<div class='four-square-header'>
							Data Date
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ scheduleName | async }}
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ dataDate | async}}
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item four-square-container'>
						<div class='four-square-header'>
							Contract Completion
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ contractCompletion | async }}
						</div>
						<div
							class='four-square-text'
							*ngIf="_projectDashboardService.fullyLoaded"
							[ngClass]="{
								'good-score': (contractVariance | async) >= 0,
								'bad-score': (contractVariance | async) < 0
								}"
						>
							Variance: {{ contractVariance | async }}
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item four-square-container'>
						<div class='four-square-header'>
							Current Completion
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ currentCompletion | async }}
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ daysLeft | async }} days left
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item four-square-container'>
						<div class='four-square-header'>
							Previous Completion
						</div>
						<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
							{{ previousCompletion | async }}
						</div>
						<div
							class='four-square-text'
							*ngIf="_projectDashboardService.fullyLoaded"
							[ngClass]="{
								'good-score': (previousVariance | async) >= 0,
								'bad-score': (previousVariance | async) < 0
								}"
						>
							Variance: {{ previousVariance | async }}
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
						<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item
				class='gridlayout-item left-container clickable'
				[row]='1'
				[col]='3'
				[ngClass]="{
										'blue-score': ($projectScore | async) >= 85 && selectedSummaryChart !== 'projectScore',
										'blue-score-selected': ($projectScore | async) >= 85 && selectedSummaryChart === 'projectScore',
										'green-score': ($projectScore | async) >= 70 && ($projectScore | async) < 85 && selectedSummaryChart !== 'projectScore',
										'green-score-selected': ($projectScore | async) >= 70 && ($projectScore | async) < 85 && selectedSummaryChart === 'projectScore',
										'red-score': ($projectScore | async) < 70 && selectedSummaryChart !== 'projectScore',
										'red-score-selected': ($projectScore | async) < 70 && selectedSummaryChart === 'projectScore'
										}"
				(click)="updateChartSelection('projectScore')"
			>
				<div class='left-tile'>
					<div class='score-top-label' [style.color]="selectedSummaryChart === 'projectScore' ? 'white' : 'black'">
						Project
					</div>
					<div
						class='score'
						*ngIf="_projectDashboardService.fullyLoaded"
					>
						<div>
							{{minZero($projectScore) | number: '1.0-0'}}%
						</div>

						<div kendoTooltip class='score-note-icon' *ngIf='scoresAltered'>
							<kendo-icon name="info" title='Score settings altered'></kendo-icon>
						</div>

					</div>
					<div
						class="score"
						*ngIf="!_projectDashboardService.fullyLoaded"
					>
						<ion-skeleton-text [animated]="true" style="width: 100%; height: 33.59px;"></ion-skeleton-text>
					</div>
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='1' [col]='4'>
				<kendo-gridlayout
					[rows]="['calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
				>
					<kendo-gridlayout-item
						class='gridlayout-item right-container clickable'
						[row]='1'
						[col]='1'
						[ngClass]="{
												'blue-score': ($progressScore | async) >= 95 && selectedSummaryChart !== 'progressScore',
												'blue-score-selected': ($progressScore | async) >= 95 && selectedSummaryChart === 'progressScore',
												'green-score': ($progressScore | async) >= 80 && ($progressScore | async) < 95 && selectedSummaryChart !== 'progressScore',
												'green-score-selected': ($progressScore | async) >= 80 && ($progressScore | async) < 95 && selectedSummaryChart === 'progressScore',
												'red-score': ($progressScore | async) < 80 && selectedSummaryChart !== 'progressScore',
												'red-score-selected': ($progressScore | async) < 80 && selectedSummaryChart === 'progressScore'
												}"
						(click)="updateChartSelection('progressScore')"
					>
						<div class='score-label' [style.color]="selectedSummaryChart === 'progressScore' ? 'white' : 'black'">
							Progress
						</div>
						<div
							class='score'
							*ngIf="_projectDashboardService.fullyLoaded && (_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length > 1"
						>
							{{minZero($progressScore) | number: '1.0-0'}}%
						</div>
						<div
							class="score"
							*ngIf="!_projectDashboardService.fullyLoaded"
						>
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 33.59px;"></ion-skeleton-text>
						</div>
						<div
							*ngIf="_projectDashboardService.fullyLoaded && ((_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length || 0) < 2"
							class='progress-req'
							title='Requires at least 1 update'
						>
							Requires at least 1 update
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						class='gridlayout-item right-container clickable'
						[row]='2'
						[col]='1'
						[ngClass]="{
												'blue-score': ($qualityControlScore | async) >= 95 && selectedSummaryChart !== 'qcScore',
												'blue-score-selected': ($qualityControlScore | async) >= 95 && selectedSummaryChart === 'qcScore',
												'green-score': ($qualityControlScore | async) >= 80 && ($qualityControlScore | async) < 95 && selectedSummaryChart !== 'qcScore',
												'green-score-selected': ($qualityControlScore | async) >= 80 && ($qualityControlScore | async) < 95 && selectedSummaryChart === 'qcScore',
												'red-score': ($qualityControlScore | async) < 80 && selectedSummaryChart !== 'qcScore',
												'red-score-selected': ($qualityControlScore | async) < 80 && selectedSummaryChart === 'qcScore'
												}"
						(click)="updateChartSelection('qcScore')"
					>
						<div class='score-label' [style.color]="selectedSummaryChart === 'qcScore' ? 'white' : 'black'">
							QC
						</div>
						<div
							class='score'
							*ngIf="_projectDashboardService.fullyLoaded"
						>
							{{$qualityControlScore | async | number: '1.0-0'}}%
						</div>
						<div
							class="score"
							*ngIf="!_projectDashboardService.fullyLoaded"
						>
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 33.59px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						class='gridlayout-item right-container clickable'
						[row]='3'
						[col]='1'
						[ngClass]="{
												'blue-score': ($riskScore | async) >= 85 && selectedSummaryChart !== 'predictabilityScore',
												'blue-score-selected': ($riskScore | async) >= 85 && selectedSummaryChart === 'predictabilityScore',
												'green-score': ($riskScore | async) >= 70 && ($riskScore | async) < 85 && selectedSummaryChart !== 'predictabilityScore',
												'green-score-selected': ($riskScore | async) >= 70 && ($riskScore | async) < 85 && selectedSummaryChart === 'predictabilityScore',
												'red-score': ($riskScore | async) < 70 && selectedSummaryChart !== 'predictabilityScore',
												'red-score-selected': ($riskScore | async) < 70 && selectedSummaryChart === 'predictabilityScore'
												}"
						(click)="updateChartSelection('predictabilityScore')"
					>
						<div class='score-label' [style.color]="selectedSummaryChart === 'predictabilityScore' ? 'white' : 'black'">
							Risk
						</div>
						<div
							class='score'
							*ngIf="$isRiskEnabled && _projectDashboardService.fullyLoaded"
						>
							{{$riskScore | async | number: '1.0-0'}}%
						</div>
						<div
							class="score"
							*ngIf="!_projectDashboardService.fullyLoaded"
						>
							<ion-skeleton-text [animated]="true" style="width: 100%; height: 33.59px;"></ion-skeleton-text>
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='1' [col]='5' class='gridlayout-item' *ngIf='(navBarStorage.$tabPointer | async) !== "cost"'>
				<app-risk-summary-trend
					[categories]="summaryScoreCategories"
					[data]="summaryScoreTrendData"
					[title]='summaryScoreTrendTitle'
					[chartType]="'line'"
					[isFromPortfolio]='false'
					class='proj-top-row-score-trend'
					*ngIf="_projectDashboardService.fullyLoaded"
				></app-risk-summary-trend>
				<div
					*ngIf="!_projectDashboardService.fullyLoaded"
				>
					<ion-skeleton-text [animated]="true" style="width: 100%; height: 200px; margin: 0;"></ion-skeleton-text>
				</div>
			</kendo-gridlayout-item>
			@if ((navBarStorage.$tabPointer | async) === "cost") {
				<kendo-gridlayout-item [row]='1' [col]='5' style="width: 100%; height: 100%;">
					<kendo-gridlayout
						[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
						[cols]="['calc(25% - (15px / 4))', 'calc(25% - (15px / 4))', 'calc(25% - (15px / 4))', 'calc(25% - (15px / 4))']"
						[gap]="{ rows: 5, cols: 5 }"
						class='grid'
						style="position: relative;"
					>
						<div class="gridlayout-item totals-label">Totals</div>
						<div class="gridlayout-item period-label">Period</div>
						<kendo-gridlayout-item [row]='1' [col]='1' [rowSpan]="2" [colSpan]="2">
							<kendo-gridlayout
								[rows]="['calc(25% - (15px / 4))', 'calc(25% - (15px / 4))', 'calc(25% - (15px / 4))', 'calc(25% - (15px / 4))']"
								[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
								[gap]="{ rows: 5, cols: 5 }"
								class='grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($totalBudgetedCost | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
										<div class="labelLabel">Baseline Budget</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($currentBudgetedCost | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
										<div class="labelLabel">Current Budget</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='3' [col]='1' [rowSpan]="2" class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($totalActual | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
										<div class="labelLabel">Actual</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='3' [col]='2' [rowSpan]="2" class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($spi | async | number: '1.0-2') || '-' }}</div>
										<div class="labelLabel">Project SPI</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($baselineRemaining | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
										<div class="labelLabel">Baseline Remaining</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item' style="display: flex; background-color: #011589;">
									<div style='margin: auto 0; width: 100%;'>
										<div class="valueLabel">{{ ($currentRemaining | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
										<div class="labelLabel">Current Remaining</div>
									</div>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item' style="display: flex; background-color: #369ae8;">
							<div style='margin: auto 0; width: 100%;'>
								<div class="valueLabel">{{ ($prevPlannedBudgeted | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
								<div class="labelLabel">Last Period Planned</div>
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item' style="display: flex; background-color: #369ae8;">
							<div style='margin: auto 0; width: 100%; position: relative;'>
								<div class="valueLabel">{{ ($prevActual | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
								<div class="labelLabel">Last Period Actual</div>
								<div class="smallNumberLabel">{{($prevActualPercentLabel | async) || ''}}</div>
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='2' [col]='3' class='gridlayout-item' style="display: flex; background-color: #369ae8;">
							<div style='margin: auto 0; width: 100%; position: relative;'>
								<div class="valueLabel">{{ ($prevDelta | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
								<div class="labelLabel">Last Period Δ</div>
								<div class="smallNumberLabel">{{($prevDeltaPercentLabel | async) || ''}}</div>
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='2' [col]='4' class='gridlayout-item' style="display: flex; background-color: #369ae8;">
							<div style='margin: auto 0; width: 100%;'>
								<div class="valueLabel">{{ ($nextMonthPlanned | async | currency: 'USD' : '$' :'1.0-0') || '-' }}</div>
								<div class="labelLabel">Next Period Planned</div>
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
			}
		</kendo-gridlayout>

		<kendo-gridlayout
			[rows]="['200px']"
			[cols]="['200px', 'auto']"
			[gap]="{ rows: 0, cols: 5 }"
			class='grid'
			*ngIf="_projectDashboardService.$currentProjectReport.value?.project?.riskMetricsType === 'riskRegister' && navBarStorage.$tabPointer.value === 'risk'"
			style='padding-bottom: 5px;'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item img-container-center'>
				<div class="image-container" (click)="openImageUpload()" (mouseover)="mouseoverImage()" (mouseout)="mouseoutImage()">
					@if (imageShowing) {
						<div [style.background-image]="'url(' + _projectDashboardService.currentProjectImg + ')'" style="width: 200px; height: 200px; border-radius: 4px; background-position: center center; background-size: contain; background-repeat: no-repeat; object-fit: contain;"></div>
						<button
							kendoButton
							(click)="openRemoveImage()"
							title="Remove Project Image"
							[ngClass]='{"remove-image-icon": imageShowing}'
							[class.opacity0]='imageShowing && !isHoveringOverImage'
						>
							<kendo-svg-icon [icon]="icons.imageRemove" class="icon-set"></kendo-svg-icon>
						</button>
					}

					<button
						kendoButton
						(click)="openImageUpload()"
						title="Edit Project Image"
						[ngClass]='{"edit-image-icon": imageShowing, "edit-image-icon-unset": !imageShowing}'
						[class.opacity0]='imageShowing && !isHoveringOverImage'
					>
						<kendo-svg-icon [icon]="icons.imageAdd" [ngClass]='{"icon-set": imageShowing, "icon-unset": !imageShowing}'></kendo-svg-icon>
					</button>
					<div *ngIf="!imageShowing" style='width: 100%; height: 100%; position: absolute; top: 0;' (click)="openImageUpload()"></div>
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='1' [col]='2'>
				<kendo-gridlayout
					[rows]="['200px']"
					[cols]="['345px', '120px', 'auto']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<kendo-gridlayout
							[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
							[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
							[gap]="{ rows: 5, cols: 5 }"
							class='grid'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item four-square-container' style='position: relative;'>
								<svg xmlns="http://www.w3.org/2000/svg"
										 width="10"
										 height="10"
										 viewBox="0 0 10 10"
										 kendoTooltip
										 position='left'
										 style='position: absolute; top: 0; left: 0;'
										 *ngIf='isMissingUpdate === true'
								>
									<path d="M 0 0 L 0 10 L 10 0 z" fill="#ff8c00" title='Missing Update'/>
								</svg>
								<!--						<div style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;' [class.missing-update]='isMissingUpdate === true'></div>-->
								<div class='four-square-header'>
									Last Update
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ scheduleName | async }}
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ dataDate | async}}
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item four-square-container'>
								<div class='four-square-header'>
									Contract Completion
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ contractCompletion | async }}
								</div>
								<div
									class='four-square-text'
									*ngIf="_projectDashboardService.fullyLoaded"
									[ngClass]="{
								'good-score': (contractVariance | async) >= 0,
								'bad-score': (contractVariance | async) < 0
								}"
								>
									Variance: {{ contractVariance | async }}
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item four-square-container'>
								<div class='four-square-header'>
									Current Completion
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ currentCompletion | async }}
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ daysLeft | async }} days left
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item four-square-container'>
								<div class='four-square-header'>
									Previous Completion
								</div>
								<div class='four-square-text' *ngIf="_projectDashboardService.fullyLoaded">
									{{ previousCompletion | async }}
								</div>
								<div
									class='four-square-text'
									*ngIf="_projectDashboardService.fullyLoaded"
									[ngClass]="{
								'good-score': (previousVariance | async) >= 0,
								'bad-score': (previousVariance | async) < 0
								}"
								>
									Variance: {{ previousVariance | async }}
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
								<div class='four-square-text' *ngIf="!_projectDashboardService.fullyLoaded">
									<ion-skeleton-text [animated]="true" style="width: 100%; height: 16px;"></ion-skeleton-text>
								</div>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' [ngClass]="{
												'blue-score-selected': ($riskScore | async) >= 85 && selectedSummaryChart !== 'predictabilityScore',
												'green-score-selected': ($riskScore | async) >= 70 && ($riskScore | async) < 85 && selectedSummaryChart !== 'predictabilityScore',
												'red-score-selected': ($riskScore | async) < 70 && selectedSummaryChart !== 'predictabilityScore',
												}">
						<div class='left-tile' style="height: 100%; display: flex; flex-direction: column; justify-content: center;">
							<div class='score-top-label' [style.color]="'white'">
								Risk
							</div>
							<div
								class='score'
								*ngIf="_projectDashboardService.fullyLoaded"
							>
								<div>
									{{minZero($riskScore) | number: '1.0-0'}}%
								</div>
							</div>
							<div
								class="score"
								*ngIf="!_projectDashboardService.fullyLoaded"
							>
								<ion-skeleton-text [animated]="true" style="width: 100%; height: 33.59px;"></ion-skeleton-text>
							</div>
						</div>
					</kendo-gridlayout-item>
					<!--<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
						<app-risk-summary-trend
							*ngIf="(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length > 1"
							[categories]="riskSummaryTrendCategories"
							[data]="riskSummaryTrendData"
							[chartType]="'line'"
							[isFromPortfolio]='false'
							class='proj-top-row-predictability-trend'
						></app-risk-summary-trend>
						<div
							*ngIf="(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length <= 1"
							class="no-data-chart-warning"
						>
							Upload 1 more update to view the Predictability Score Chart
						</div>
					</kendo-gridlayout-item>-->
					<kendo-gridlayout-item [row]='1' [col]='3'>
						<kendo-gridlayout
							[rows]="['200px']"
							[cols]="['calc((100% - 195px) / 2)', '185px', 'calc((100% - 195px) / 2)']"
							[gap]="{ rows: 0, cols: 5 }"
							class='grid'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
								<span class="chart-title">Risk by Category</span>
								<app-risk-by-category
									[chartType]="'bar'"
									[title]="'Risk by Category'"
									[$risks]="allRisks"
									[groupBy]="'category'"
									[isFromProjectPage]='true'
								></app-risk-by-category>
								<span class="chart-title">Risk by Owner</span>
								<app-risk-by-category
									[chartType]="'bar'"
									[title]="'Risk by Owner'"
									[$risks]="allRisks"
									[groupBy]="'riskOwner.name'"
									[isFromProjectPage]='true'
								></app-risk-by-category>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' style='height: 100%;'>
								<span class="chart-title">Top Risks by Status</span>
								<div class='mitStatus-table-container'>
									<div *ngFor='let status of mitStatusTableVals' class='mitStatusItem' style="font-size: 12px;">
										<div class='mitStatusLabel' style="width: calc(100% - 42px); font-size: 14px;">
											{{status.label}}
										</div>
										<div class='mitStatusVal'>
											{{status.percentage | number: '1.0-0'}}%
										</div>
									</div>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
								<span class="chart-title">Likelihood vs. Impact (Pre-Mitigation)</span>
								<app-likelihood-heatmap
									[$risks]="allRisks"
								></app-likelihood-heatmap>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</ion-grid>
</div>
<kendo-window
	*ngIf="showArchiveWarning"
	(close)="closeDialog()"
	[resizable]='false'
	[minWidth]="250"
	[(top)]="appWindowService.windowPosition.archiveProjectWarning.top"
	[(left)]="appWindowService.windowPosition.archiveProjectWarning.left"
	[height]="appWindowService.windowPosition.archiveProjectWarning.height"
	[width]="appWindowService.windowPosition.archiveProjectWarning.width"
	(leftChange)="restrictMovement('archiveProjectWarning')"
	(topChange)="restrictMovement('archiveProjectWarning')"
	id='archiveProjectWarningWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Please Confirm</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style="margin: 30px; text-align: center;z-index: 99999">
		Are you sure you want to archive this project?
	</div>
	<kendo-dialog-actions style='margin: 0; padding: 0;'>
		<button kendoButton (click)="showArchiveWarning = false">No</button>
		<button kendoButton (click)="saveArchiveChange()" themeColor="primary">
			Yes
		</button>
	</kendo-dialog-actions>
</kendo-window>
<kendo-window
	*ngIf="imageUploadDialogOpen"
	[minWidth]="376"
	(close)='closeDialog()'
	[resizable]='false'
	[(top)]="appWindowService.windowPosition.image.top"
	[(left)]="appWindowService.windowPosition.image.left"
	[height]="appWindowService.windowPosition.image.height"
	[width]="appWindowService.windowPosition.image.width"
	(leftChange)="restrictMovement('image')"
	(topChange)="restrictMovement('image')"
	id='imageWindow'
	class='project-image-upload-dialog'
	>
	<kendo-window-titlebar>
		<div class="k-window-title">Upload Your Image</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-upload-picture (imageReadyToShow)='imageReadyToShowUpdated($event)' (closeDialog)='closeDialog()'></app-upload-picture>
</kendo-window>

<kendo-window
	*ngIf="removeImageDialogOpen"
	(close)="closeDialog()"
	[resizable]='false'
	[minWidth]="250"
	[(top)]="appWindowService.windowPosition.removeLogo.top"
	[(left)]="appWindowService.windowPosition.removeLogo.left"
	[height]="appWindowService.windowPosition.removeLogo.height"
	[width]="appWindowService.windowPosition.removeLogo.width"
	(leftChange)="restrictMovement('removeLogo')"
	(topChange)="restrictMovement('removeLogo')"
	class='unsave-warning-container'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Please Confirm</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style="margin: 25px; text-align: center;">
		Are you sure you wish to remove the current image?
	</div>
	<kendo-dialog-actions style='margin: 0; padding: 0;'>
		<button kendoButton (click)="removeImage()" fillMode="solid" themeColor='success'>
			Yes
		</button>
		<button kendoButton (click)="removeImageDialogOpen = false" fillMode="solid" themeColor="error">No</button>
	</kendo-dialog-actions>
</kendo-window>
